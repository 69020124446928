/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";

import TempImg from "../../../assets/icons/TempImg.svg";
import waterIcon2 from "../../../assets/image/waterIcon2.svg";

import "./ADWater.scss";
import * as R from "ramda";
function ADWater({ data }:any) {

  return (
    <>
      {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
        data?.meters.map(
          (el:any, index:any) =>
            el.meterTypeId.includes("F_SPEED") && (
              <div className="waterContainer">
                <p className="cardHeading">Flow Speed</p>
                <div className="cardValuesCont">
                  <img src={waterIcon2} alt="icon" className="cardIcon" />
                  <p
                    style={{
                      color: el.compliance === false ? "red" : "#000000",
                    }}
                    className="card1Values"
                  >
                    {Number(el?.meterValue).toFixed(2)} {el?.readingUomDesc}
                  </p>
                </div>
              </div>
            )
        )
      ) : (
        <div className="waterContainer">
          <p className="cardHeading">Flow Speed</p>
          <div className="cardValuesCont">
            <img src={waterIcon2} alt="icon" className="cardIcon" />
            <p className="cardValues">--</p>
          </div>
        
        </div>
      )}

{!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
        data?.meters.map(
          (el:any, index:any) =>
            el.meterTypeId.includes("F_VOLUME") && (
              <div className="waterContainer">
                <p className="cardHeading">Flow Rate</p>
                <div className="cardValuesCont">
                  <img src={waterIcon2} alt="icon" className="cardIcon" />
                  <p
                    style={{
                      color: el.compliance === false ? "red" : "#000000",
                    }}
                    className="card1Values"
                  >
                    {Number(el?.meterValue).toFixed(2)} {el?.readingUomDesc}
                  </p>
                </div>
              </div>
            )
        )
      ) : (
        <div className="waterContainer">
          <p className="cardHeading">Flow Rate</p>
          <div className="cardValuesCont">
            <img src={waterIcon2} alt="icon" className="cardIcon" />
            <p className="cardValues">--</p>
          </div>
         
        </div>
      )}

{!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
        data?.meters.map(
          (el:any, index:any) =>
            el.meterTypeId.includes("F_MASS") && (
              <div className="waterContainer">
                <p className="cardHeading">Mass Flow Rate</p>
                <div className="cardValuesCont">
                  <img src={waterIcon2} alt="icon" className="cardIcon" />
                  <p
                    style={{
                      color: el.compliance === false ? "red" : "#000000",
                    }}
                    className="card1Values"
                  >
                    {Number(el?.meterValue).toFixed(2)} {el?.readingUomDesc}
                  </p>
                </div>
              </div>
            )
        )
      ) : (
        <div className="waterContainer">
          <p className="cardHeading">Mass Flow Rate</p>
          <div className="cardValuesCont">
            <img src={waterIcon2} alt="icon" className="cardIcon" />
            <p className="cardValues">--</p>
          </div>
         
        </div>
      )}
    </>
  );
}

export default ADWater;
