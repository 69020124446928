import React, { useState, useEffect, useRef } from "react";
import "../../App_one.scss";
import "../../Activity_Centre.scss";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import TabGroup from "../tab";
import ChatBot from "../chatBot";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import Modal from "react-bootstrap/Modal";
import alertsImg from "../../assets/icons/icon-alert.svg";
import view_Dtlimg from "../../assets/icons/view_Details.svg";
import CloseButton from "../../assets/icons/CloseButton.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as R from "ramda";
import { useStateValue } from "../../redux/StateProvider";
import DisplayError from "../DisplayError/DisplayError";
import LoaderStyle from "../LoaderStyle/LoaderStyle";
import CustomSelect from "../tab/customSelector/CustomSelect";
import zIndex from "@material-ui/core/styles/zIndex";
import { getAssetList } from "../../features/assetList/service/AssetListService";
import { getAssetTypeDesc, getFacilityDetails } from "../../features/assetList/utils";
import LocalStorageService from "../../Utils/LocalStorageService";
import { actionTypes } from "../../redux/reducer";

function ToggleRight({ rightOpen, isDark, handleRightOpen }: any) {
  const elemStyle = isDark ? "sidebarRight-dark" : "sidebarRight-light";
  const [{ dateRange }, dispatch] = useStateValue();
  const [selectReason, setSelectReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [tabLabel, setTabLabel] = useState<any>([
    {
      name: "WatchList",
      id: 1,
      count: 0,
      value: {
        data: [],
      },
    },
    {
      name: "Alerts",
      id: 2,
      count: 0,
      value: {
        data: [],
      },
    },
    {
      name: "Tickets",
      id: 3,
      count: 0,
      value: {
        data: [],
      },
    },
  ]);


  const [{ lastSelectedActivityCenterTab }] = useStateValue();

  const [dataVal, setDataVal] = useState<any>([]);
  const [lodeState, setlodeState] = useState(false);
  const [stateVal, setStateVal] = useState(1);
  const [isShown, setIsShown] = useState(false);
  const activeAlert = useRef<any>({});
  const [reasonsList, setReasonsList] = useState<any>([]);
  const [permissionForUpdate, setPermissionForUpdate] = useState(false);

  const getDataSet = async (fromDateStr: any, id: any, endDateStr: any) => {
    setlodeState(true);
    const DBservice = new DashBoardServices();
    const fetchData = await DBservice.getActivityCenter(
      fromDateStr,
      1,
      endDateStr,
      ""
    );
    if (fetchData === "error") {
      setDataVal(fetchData);
    } else if (typeof fetchData === "number") {
      setDataVal(fetchData);
    } else if (R.isEmpty(fetchData)) {
      setDataVal([]);
    } else {
      setDataVal(fetchData);
      const newJsonSet = [
        {
          name: "WatchList",
          id: 1,
          count: fetchData?.watchList.count,
          value: {
            data: [...fetchData?.watchList.data],
          },
          pagination: fetchData?.watchList.pagination
        },
        {
          name: "Alerts",
          id: 2,
          count: fetchData?.alerts.count,
          value: {
            data: [...fetchData?.alerts.data],
          },
          pagination: fetchData?.alerts.pagination
        },
        {
          name: "Tickets",
          id: 3,
          count: fetchData?.tickets.count,
          value: {
            data: [...fetchData?.tickets.data],
          },
          pagination: fetchData?.tickets.pagination
        },
      ];
      setTabLabel(newJsonSet);
    }
    setlodeState(false);
  };

  useEffect(() => {
    if (selectReason === "OTHERS") {
    } else {
      setOtherReason("");
    }
  }, [selectReason]);

  useEffect(() => {
    setStateVal(1);
    const fromDateStr = moment(dateRange[0]).startOf("day").toISOString();
    const endDateStr = moment(dateRange[1]).endOf("day").toISOString();
    getDataSet(moment(fromDateStr).valueOf(), 1, moment(endDateStr).valueOf());
  }, [dateRange]);

  const closeAlertListUpdate = () => {
    const fromDateStr = moment(dateRange[0]).startOf("day").toISOString();
    const endDateStr = moment(dateRange[1]).endOf("day").toISOString();
    getDataSet(moment(fromDateStr).valueOf(), 1, moment(endDateStr).valueOf());
  };

  const navigateAssetDetailPage = async () => {
    const body = {
      viewIndex: 1,
      viewSize: 50,
      isLowDataMode: false,
      withLiveMeters: false,
      withCardMeters: true,
      isMonitoring: true,
      withAssetAttributes: true,
      searchBy: [
        {
          "isSearch": true,
          "searchKey": "fixedAssetId",
          "searchValue": [
            activeAlert.current.fixedAssetId
          ]
        }
      ],
    }

    const response = await getAssetList(body);
    if (response.hasOwnProperty("assets") && response?.assets.length !== 0) {
      const data = response?.assets[0];
      const facility = await getFacilityDetails(data.locatedAtFacilityId);
      const assetType = await getAssetTypeDesc(data.assetTypeId);

      let dataTypeCheck = {
        fixedAssetAssocWithTypeDesc: data.fixedAssetAssocWithTypeDesc,
        assetId: data.assetId,
        fixedAssetAssocWithTypeId: data.fixedAssetAssocWithTypeId,
        assetName: data.assetName,
        assetTypeDesc: assetType?.description,
        meters: data.attributes,
        data: data,
      };

      LocalStorageService.setAssetIdData(data.assetId);
      LocalStorageService.setSelectedAssetData(JSON.stringify(dataTypeCheck));
      LocalStorageService.setSingleAssetCardDetailsData(
        JSON.stringify(facility)
      );

      dispatch({
        type: actionTypes.SET_CURRENT_ASSET_ID,
        currAssetId: data.assetId,
      });

      window.location.pathname = `/asset-list/${data.assetName}`;
    }
  };

  const [filterReason, setFilterReason] = useState("");

  const updateReason = (ele: any) => {
    if (ele === "") {
      setFilterReason("");
      setSelectReason("");
      setEmptyCheckStatus(false);
    } else if(ele === 'undefined'){
      setFilterReason("");
      setSelectReason("");
      setEmptyCheckStatus(false);
    }else{
      let idValue = reasonsList.find((obj: any) => obj.enumId === ele);
      setFilterReason(idValue.description);
      setSelectReason(ele);
      setEmptyCheckStatus(false);
    }
  };

  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [closeAlertStatus, setCloseAlertStatus] = useState(false);

  useEffect(() => {
    let permission: any = localStorage.getItem("permissions");
    let permissionsList: any = JSON.parse(permission);
    if (permissionsList === null || permissionsList === undefined) {
    } else {
      const permissionIds = [];

      for (const item of permissionsList) {
        if (Array.isArray(item.permissionId)) {
          permissionIds.push(...item.permissionId);
        }
      }
      if (permissionIds === undefined || permissionIds === null) {
      } else {
        let list = permissionIds.includes("ALERT_UPDATE");

        setPermissionForUpdate(list);
      }
    }
    getAlertReasons();
  }, []);

  const activeAlertUpdateFun = (ele: any) => {
    activeAlert.current = ele;
    setIsShown(true);
  };

  const getAlertReasons = async () => {
    const fetchDB = new DashBoardServices();
    const reasonsListData = await fetchDB.getAlertReasons();
    if (reasonsListData === "error") {
      setReasonsList([]);
    } else if (typeof reasonsListData === "number") {
      setReasonsList([]);
    } else {
      let data = await reasonsListData.enumerationList;
      setReasonsList(data);
    }
  };

  const [toastState, setToastState] = useState(false);

  const getCloseAlert = async () => {
    setCloseAlertStatus(true);
    let message = "";
    const fetchDB = new DashBoardServices();
    let reason = selectReason === "OTHERS" ? otherReason : selectReason;
    const reasonsListData: any = await fetchDB.getCloseAlert(
      activeAlert.current.alertId,
      reason
    );
    console.log("reasonsListData", reasonsListData);
    if (reasonsListData === "error") {
      message = "Failed Try Again";
    } else {
      switch (reasonsListData.status) {
        case 200:
          let data = await reasonsListData.json();
          message = await data.responseMessage;
          closeAlertListUpdate();
          setIsShown(false);
          break;

        case 412:
          let data_412 = await reasonsListData.json();
          message = await data_412.errorMessage;
          closeAlertListUpdate();
          setIsShown(false);
          break;

        case 413:
          let data_413 = await reasonsListData.json();
          message = await data_413.errorMessage;
          break;

        case 419:
          let data_419 = await reasonsListData.json();
          message = await data_419.errorMessage;
          break;

        case 420:
          let data_420 = await reasonsListData.json();
          message = await data_420.errorMessage;
          break;

        case 204:
          let data_204 = await reasonsListData.json();
          message = await data_204.errorMessage;
          break;

        case 202:
          let data_202 = await reasonsListData.json();
          message = await data_202.errorMessage;
          break;

        case 500:
          let data_500 = await reasonsListData.json();
          message = await data_500.errorMessage;
          break;

        case 400:
          let data_400 = await reasonsListData.json();
          message = await data_400.errorMessage;
          break;

        default:
          message = "error";
      }
    }
    setCloseAlertStatus(false);
    setToastState(true);
    setTimeout(() => {
      setToastState(false);
    }, 3000);
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000, // Close the toast after 5 seconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const [emptyCheckStatus, setEmptyCheckStatus] = useState(false);

  const closeAlertFun = async () => {
    setDropdownStatus(true);
    if (dropdownStatus && !toastState) {

      if (selectReason === "OTHERS") {
        // alert(selectReason)
        if (otherReason.trim() === "") {
          setEmptyCheckStatus(true);
        } else {
          setEmptyCheckStatus(false);
          getCloseAlert();
        }
      } else if (selectReason.trim() === "") {
        setEmptyCheckStatus(true);
      } else {
        setEmptyCheckStatus(false);
        getCloseAlert();
      }
    }
  };

  useEffect(() => {
    setDropdownStatus(false);
    setEmptyCheckStatus(false);
    setSelectReason("");
    setOtherReason("");
  }, [isShown]);

  return (
    <>
      <Modal
        show={isShown}
        centered
        keyboard={false}
        backdrop={"static"}
        //size="sm"
        onHide={() => setIsShown(false)}
        className="modelPopAlerts"
      >
        <div className="alertset">
          <div className="alertset_div">
            <div className="alertse_divSpace">
              <img className="alertsImg" src={alertsImg} />
              <div>
                <h3 className="alertTypeDesc">
                  {activeAlert.current.alertTypeDesc}
                </h3>
                <p className="fixedAssetName">
                  {activeAlert.current.fixedAssetName}
                </p>
              </div>
            </div>
            <div>
              <p onClick={() => setIsShown(false)}>
                <img src={CloseButton} />
              </p>
            </div>
          </div>
          <h3 className="alertDesc">{activeAlert.current.alertDesc}</h3>
          {dropdownStatus ? (
            <div className="reasonClose">
              <p className="reason">Reason for closing</p>
              {closeAlertStatus ? (
                <input
                  type="text"
                  className="filterReason"
                  value={filterReason}
                  readOnly
                />
              ) : (
                <CustomSelect
                  callBackValue={updateReason}
                  options={reasonsList}
                />
              )}

              {selectReason === "OTHERS" ? (
                closeAlertStatus ? (
                  <input
                    type="text"
                    readOnly
                    className="otherReason"
                    style={{ borderColor: "#A7B8F4" }}
                    value={otherReason}
                  />
                ) : (
                  <input
                    type="text"
                    className="otherReason"
                    placeholder="Enter reason for closing"
                    style={{
                      borderColor: emptyCheckStatus ? "#ff8282" : "#A7B8F4",
                    }}
                    value={otherReason}
                    onClick={() => setEmptyCheckStatus(false)}
                    maxLength={100}
                    onChange={(e) => {
                      if (e.target.value.trim() === "") {
                        setEmptyCheckStatus(true);
                        setOtherReason(e.target.value.trim());
                      } else if (e.target.value.trim().length > 0) {
                        setEmptyCheckStatus(false);
                        setOtherReason(e.target.value);
                      } else {
                        setEmptyCheckStatus(false);
                        setOtherReason(e.target.value);
                      }
                    }}
                  />
                )
              ) : null}

              <p
                style={{
                  color: "#ff1212",
                  fontSize: "12px",
                  fontWeight: "500",
                  opacity: emptyCheckStatus ? 1 : 0,
                }}
              >
                please{" "}
                {selectReason === "OTHERS"
                  ? "enter your reason for closing alert"
                  : "select valid reason for closing alert"}
              </p>
            </div>
          ) : null}

          <div className="footerAlert">
            <div
              style={{ cursor: "pointer" }}
              onClick={navigateAssetDetailPage}
              className="ViewStyle"
            >
              <img src={view_Dtlimg} />
              <p>View Details</p>
            </div>
            <div>
              {/* <div>
                <p>Cancel</p>
              </div> */}
              {permissionForUpdate ? (
                <div
                  style={{ cursor: "pointer" }}
                  className="closeStyle"
                  onClick={() => (closeAlertStatus ? null : closeAlertFun())}
                >
                  {closeAlertStatus ? (
                    <div className="loader"></div>
                  ) : (
                    <p>Close Alert</p>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>

      <div className="pagination_value" style={{ zIndex: "999999" }}>
        <ToastContainer position="bottom-right" />
      </div>

      {rightOpen ? (
        <div className={`d-flex col-xl-4 col-12 sidebarRight ${elemStyle}`}>
          <div className="closeArrow" onClick={handleRightOpen}>
            <FontAwesomeIcon icon={faChevronRight} className="faChevronRight" />
          </div>

          <div className="sideHeader">
            <div className="sideHeaderFirstDiv">
              <h5>Activity Centre</h5>
            </div>
            <div className="sideHeaderSecondDiv sideNav-active-SecondDiv-header-icon">
              {/* <FontAwesomeIcon icon={faCalendar} className="externalIconColor" />
          <FontAwesomeIcon
            icon={faArrowUpRightFromSquare}
            className="externalIconColor"
          /> */}
              <FontAwesomeIcon
                icon={faXmark}
                className="closeIconColor"
                onClick={handleRightOpen}
              />
            </div>
          </div>

          <div className="sideContentArea">
            {lodeState ? (
              <div className="centerState">
                <LoaderStyle />{" "}
              </div>
            ) : dataVal === "error" ? (
              <div
                style={{
                  justifyContent: "center",
                  marginLeft: "10%",
                  alignItems: "center",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <DisplayError type={"err_1"} />
              </div>
            ) : typeof dataVal === "number" ? (
              <div
                style={{
                  justifyContent: "center",
                  marginLeft: "10%",
                  alignItems: "center",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <DisplayError type={"err-500/404"} />
              </div>
            ) : R.isEmpty(dataVal) || R.isEmpty(tabLabel) ? (
              <div
                style={{
                  justifyContent: "center",
                  marginLeft: "10%",
                  alignItems: "center",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <DisplayError type={"err-empty"} />
              </div>
            ) : (
              <TabGroup data={tabLabel} callBack={activeAlertUpdateFun} />
            )}
          </div>
          <div className="sideFooter">
            <ChatBot />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ToggleRight;
