import React, { useState, useEffect, useRef } from "react";
import TempImg from "../../../assets/icons/icon-suction-temperature-compressor.svg";
import condensorImg from "../../../assets/icons/icon-condensation-temperature-compressor.svg";
import compressorActiveIcon from "../../../assets/icons/compressor-active.svg";
import compressorinActiveIcon from "../../../assets/icons/compressor-inactive.svg";
import "./CompressorLiveCard.scss";
import { getCheckAbbrevationValue, getCheckNullValidation } from "../../../Utils/utils";
import { compressorLiveCardObj,consumptionAssetDataType } from "../../../DataTypes/DataTypes"


function CompressorLiveCard({ data, closeOpenBtn }: consumptionAssetDataType) {
  const suctionTemperature1 = useRef<compressorLiveCardObj>({value:"-", setPoint:'-', readingUomDesc:'-'});
  const suctionTemperature2 = useRef<compressorLiveCardObj>({value:"-", setPoint:'-', readingUomDesc:'-'});
  const condensationTemp1 = useRef<compressorLiveCardObj>({value:"-", setPoint:'-', readingUomDesc:'-'});
  const condensationTemp2 = useRef<compressorLiveCardObj>({value:"-", setPoint:'-', readingUomDesc:'-'});
  const [compressorActive, setCompressorActive] = useState<string | number >("-");
  const [compressorInActive, setCompressorInActive] = useState<string | number >('-');
  useEffect(() => {
    if(typeof data === 'object' && data.length > 0){
      for (let obj of data){
          if(obj.meterTypeId === "SUC_TEMP_1") {
            suctionTemperature1.current = ({
                  ...suctionTemperature1.current,
                  value :  getCheckNullValidation(obj.meterValue),
              });
          }
          else if(obj.meterTypeId === "SUC_TEMP_1_SET_PT"){
            suctionTemperature1.current = ({
                  ...suctionTemperature1.current,
                  setPoint : getCheckNullValidation(obj.meterValue),
                  readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
              });
          }
          else if(obj.meterTypeId === "SUC_TEMP_2"){
            suctionTemperature2.current = ({
                  ...suctionTemperature2.current,
                  value : getCheckNullValidation(obj.meterValue),
              });
          }
          else if(obj.meterTypeId === "SUC_TEMP_2_SET_PT"){
            suctionTemperature2.current = ({
                  ...suctionTemperature2.current,
                  setPoint : getCheckNullValidation(obj.meterValue),
                  readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
              });
          }
          else if(obj.meterTypeId === "COND_TEMP_1"){
            condensationTemp1.current = ({
                  ...condensationTemp1.current,
                  value : getCheckNullValidation(obj.meterValue),
              });
          }
          else if(obj.meterTypeId === "COND_TEMP_1_SET_PT"){
            condensationTemp1.current = ({
                  ...condensationTemp1.current,
                  setPoint : getCheckNullValidation(obj.meterValue),
                  readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
              });
          }
          else if(obj.meterTypeId === "COND_TEMP_2"){
            condensationTemp2.current = ({
                  ...condensationTemp2.current,
                  value : getCheckNullValidation(obj.meterValue),
              });
          }
          else if(obj.meterTypeId === "COND_TEMP_2_SET_PT"){
            condensationTemp2.current = ({
                  ...condensationTemp2.current,
                  setPoint : getCheckNullValidation(obj.meterValue),
                  readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
              });
          }
          else if(obj.meterTypeId === "ACTIVE_CMPR") setCompressorActive(getCheckNullValidation(obj.meterValue));
          else if(obj.meterTypeId === "IN_ACTIVE_CMPR") setCompressorInActive(getCheckNullValidation(obj.meterValue));
      }
  }
  }, [data]);

  return (
    <div className="tempEnergyContainer">
      <div className="cardsRowCont">
        <div className="rowOne">
            <div className="card1Container">
              <p className="card1Heading">Suction temp 1</p>
                <div className="card1ValuesCont">
                  <img src={TempImg} alt="icon" loading="lazy" className="card1Icon" />
                  <p className="card1Values">  {suctionTemperature1.current.value}{suctionTemperature1.current.readingUomDesc} </p>
                </div>
                <p className="card1Celcius">
                <b>Set Point</b>{" "}
                  {suctionTemperature1.current.setPoint} {suctionTemperature1.current.readingUomDesc}
              </p>
            </div>

            <div className="card1Container">
              <p className="card1Heading">Suction temp 2</p>
                <div className="card1ValuesCont">
                  <img src={TempImg} loading="lazy" alt="icon" className="card1Icon" />
                  <p className="card1Values">  {suctionTemperature2.current.value}{suctionTemperature2.current.readingUomDesc} </p>
                </div>
                <p className="card1Celcius">
                <b>Set Point</b>{" "}
                  {suctionTemperature2.current.setPoint} {suctionTemperature2.current.readingUomDesc}
              </p>
            </div>

            <div className="card1Container">
              <p className="card1Heading">Condensation temp 1</p>
                <div className="card1ValuesCont">
                  <img src={condensorImg} loading="lazy" alt="icon" className="card1Icon" />
                  <p className="card1Values">  {condensationTemp1.current.value}{condensationTemp1.current.readingUomDesc} </p>
                </div>
                <p className="card1Celcius">
                <b>Set Point</b>{" "}
                  {condensationTemp1.current.setPoint} {condensationTemp1.current.readingUomDesc}
              </p>
            </div>

            <div className="card1Container">
              <p className="card1Heading">Condensation temp 2</p>
                <div className="card1ValuesCont">
                  <img src={condensorImg} loading="lazy" alt="icon" className="card1Icon" />
                  <p className="card1Values">  {condensationTemp2.current.value}{condensationTemp2.current.readingUomDesc} </p>
                </div>
                <p className="card1Celcius">
                <b>Set Point</b>{" "}
                  {condensationTemp2.current.setPoint} {condensationTemp2.current.readingUomDesc}
              </p>
            </div>
        </div>
        {closeOpenBtn && (
          <div className="rowOne">
           <div className="card1Container">
              <p className="card1Heading">Compressor Active</p>
                <div className="card1ValuesCont">
                  <img src={compressorActiveIcon} loading="lazy" alt="icon" className="card1Icon" />
                  <p className="card1Values">{compressorActive}</p>
                </div>
            </div>

            <div className="card1Container">
              <p className="card1Heading">Compressor inactive</p>
                <div className="card1ValuesCont">
                  <img src={compressorinActiveIcon} loading="lazy" alt="icon" className="card1Icon" />
                  <p className="card1Values"> {compressorInActive} </p>
                </div>
            </div>

          </div>
        )}
      </div>
    </div>
  );
}
export default CompressorLiveCard;