/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
//@ts-nocheck

import { useEffect, useState } from "react";
import AssetSettings from "../../../SettingsComponents/AssetSettings/AssetSettings";
import FacilitySettings from "../../../SettingsComponents/FacilitySettings/FacilitySettings";
import Users from "../../../SettingsComponents/Users/Users";
import Organisations from "../../../SettingsComponents/Organisations/Organisations";
import FacilityGroups from "../../../SettingsComponents/FacilityGroups/FacilityGroups";
import IconSearch from "../../../assets/icons/ic_settings_search.svg";
import CloseButton from "../../../assets/icons/CloseButton.svg";
import "./TracktraceDashboard.scss";
import { SettingsApiServices } from "../../../services/settingsPageServices/SettingsApiServices";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getKeyForSelectedFilter } from "../../../Constants/constants";
import { useStateValue } from "../../../redux/StateProvider";

function TrackTraceDashboard({
  isDark,
  theme,
  rightOpen,
  toggleBtn,
  themeColors,
}) {
  const [{ settingsPaginationCount }] = useStateValue();
  const permissionsMapping = {
    FIXEDASSET_VIEW: "Asset Settings",
    FACILITY_VIEW: "Facility Settings",
    PARTYMGR_VIEW: "Users",
    PARTYGRP_VIEW: "Organisations",
    FACGROUP_VIEW: "Facility Groups",
  };

  //Todo move it to a helper fn
  const securityPermissions = localStorage.getItem("securityPermissions") || "";
  const securityPermissionsArray = JSON.parse(securityPermissions);
  // const securityPermissionsArray = securityPermissions.split(",").map(permission => permission.replace(/"/g, ''));

  const filteredHeaderList = securityPermissionsArray
    .filter((permission) =>
      Object.keys(permissionsMapping).includes(permission)
    )
    .map((permission) => permissionsMapping[permission]);

  async function GeneratedToken() {
    const fetchData = new SettingsApiServices();
    const data = await fetchData.TokenGenerate();
    localStorage.setItem("@tokenID", data?.token);
    console.log("dataToken", data?.token);
  }

  const [headerButton, setHeaderButton] = useState(filteredHeaderList[0]);

  function generateID(length: any) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [callAddFac, callAddFacilityPopup] = useState({
    number: generateID(5),
    show: false,
  });
  const [searchString, setSearchString] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [initSearch, setInitSearch] = useState(0);
  const [searchState, setSearchState] = useState(false);
  const callCreateFacilityFunction = () => {
    setHeaderButton("Facility Settings");
    callAddFacilityPopup({ number: generateID(5), show: true });
  };

  const incrementInitSearch = () => {
    setInitSearch(initSearch + 1);
  };

  let components = [
    {
      name: "Asset Settings",
      comoponent: (
        <AssetSettings
          callCreateFacilityFunction={callCreateFacilityFunction}
          searchString={searchString}
          initSearch={initSearch}
          searchKey={searchKey}
          incrementInitSearch={incrementInitSearch}
        />
      ),
    },
    {
      name: "Facility Settings",
      comoponent: (
        <FacilitySettings
          callAddFac={callAddFac}
          setHeaderButton={setHeaderButton}
          callAddFacilityPopup={callAddFacilityPopup}
        />
      ),
    },
    { name: "Users", comoponent: <Users /> },
    { name: "Organisations", comoponent: <Organisations /> },
    { name: "Facility Groups", comoponent: <FacilityGroups /> },
  ];
  const getComponents = () => {
    if (headerButton == "Facility Groups") {
      localStorage.setItem("activefacilityGroupId", JSON.stringify([]));
    }
    let activeComponent = components.find((el) => el.name == headerButton);
    return activeComponent !== undefined ? (
      activeComponent.comoponent
    ) : (
      <AssetSettings callCreateFacilityFunction={callCreateFacilityFunction} />
    );
  };

  //Search
  const searchItemsAssets = ["Asset Id", "Asset Name", "Serial Number"];
  const searchItemsFacility = ["Facility Name", "External ID"];
  const searchItemsOrg = ["Group Name"];
  const searchItemsUsers = ["First Name", "Last Number", "Username"];

  const [searchValue, setSearchValue] = useState(null);

  let dropdownItems;
  switch (headerButton) {
    case "Asset Settings":
      dropdownItems = searchItemsAssets;
      break;
    case "Facility Settings":
      dropdownItems = searchItemsFacility;
      break;
    case "Users":
      dropdownItems = searchItemsUsers;
      break;
    case "Organisations":
      dropdownItems = searchItemsOrg;
      break;
    default:
      dropdownItems = [];
      break;
  }

  useEffect(() => {
    setSelectedItem(dropdownItems[0]);
  }, [headerButton]);

  let searchHint;
  switch (headerButton) {
    case "Asset Settings":
      searchHint = `Search assets`;
      break;
    case "Facility Settings":
      searchHint = `Search facility`;
      break;
    case "Users":
      searchHint = `Search users`;
      break;
    case "Organisations":
      searchHint = `Search groups`;
      break;
    default:
      searchHint = `Search`;
      break;
  }

  const [selectedItem, setSelectedItem] = useState(dropdownItems[0]);
  const [previousSelectedItem, setPreviousSelectedItem] = useState(
    dropdownItems[0]
  );
  const handleItemClick = (item) => {
    // if ((selectedItem === previousSelectedItem) || (previousSelectedItem!= item && searchValue)) {
    //   debugger
    // setPreviousSelectedItem(selectedItem)
    // setSelectedItem(item);
    // setSearchString(undefined)
    setSearchState(false);
    // }
    // else{
    //   debugger
    setPreviousSelectedItem(selectedItem);
    setSelectedItem(item);
    // }
    console.log(`previousSelectedItem ; ${previousSelectedItem}`);
    console.log(`selectedItem ; ${item}`);
  };

  const notify = (s: string) => toast(s);

  function checkValidation() {
    if (selectedItem == "Asset Id") {
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;
      if (searchValue.length <= 20 && alphanumericRegex.test(searchValue)) {
        return true;
      } else {
        notify("Enter a valid Asset Id");
        return false;
      }
    }

    if (selectedItem == "Asset Name") {
      const alphanumericRegex = /^[A-Za-z0-9-#.,\/' ]+$/;
      if (searchValue.length <= 20 && alphanumericRegex.test(searchValue)) {
        return true;
      } else {
        notify("Enter a valid Asset Name");
        return false;
      }
    }

    if (selectedItem == "Serial Number") {
      const alphanumericRegex = /^[A-Za-z0-9-#]+$/;
      if (searchValue.length <= 20 && alphanumericRegex.test(searchValue)) {
        return true;
      } else {
        notify("Enter a valid Serial Number");
        return false;
      }
    }
    return false;
  }

  const handleSearch = (item) => {
    if (checkValidation()) {
      if (searchValue && !searchState) {
        setSearchString(searchValue);
        setSearchKey(getKeyForSelectedFilter[selectedItem]);
        setSearchState(true);
      } else if (searchState) {
        setSearchState(false);
        setSearchString(undefined);
        setSearchValue("");
      }
      incrementInitSearch();
    }
  };

  const handleInputChange = (value: string) => {
    if (selectedItem == "Asset Id") {
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;
      if (value.length <= 20 && alphanumericRegex.test(value)) {
        setSearchValue(value);
      }
    }

    if (selectedItem == "Asset Name") {
      if (value === "") {
        setSearchValue(value);
      } else {
        const alphanumericRegex = /^[A-Za-z0-9-#.,\/' ]+$/;
        if (value.length <= 20 && alphanumericRegex.test(value)) {
          setSearchValue(value);
        }
      }
    }

    if (selectedItem == "Serial Number") {
      if (value === "") {
        setSearchValue(value);
      } else {
        const alphanumericRegex = /^[A-Za-z0-9-#]+$/;
        if (value.length <= 20 && alphanumericRegex.test(value)) {
          setSearchValue(value);
        }
      }
    }
  }

  useEffect(() => {
    if (searchValue) {
      // setSearchState(false);
    }
    if (searchValue == "" && searchState) {
      setSearchState(false);
      setSearchString(undefined);
      setSearchValue("");
      incrementInitSearch();
    }
  }, [searchValue]);

  return (
    <div className="trackntraceContainer">
      <div className="headerSearchCont">
        <h4>Settings</h4>
        {/*SEARCH PERMISSION*/}
        {headerButton != "Facility Groups" && (
          <div className="settingsSearchCont">
            <div className="left-element">
              <DropdownButton
                id="dropdown-variants-secondary"
                className="width-100"
                variant="variant"
                title={selectedItem}
                // disabled={searchState}
                size="sm"
              >
                {dropdownItems.map((item) => (
                  <Dropdown.Item
                    key={item}
                    onClick={() => handleItemClick(item)}
                  >
                    {selectedItem === item ? (
                      <span>&#10003; &nbsp;</span>
                    ) : (
                      <span>&nbsp; &nbsp; &nbsp;</span>
                    )}
                    {item}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <input
              type="text"
              className="middle-element"
              placeholder={searchHint}
              value={searchValue}
              onChange={(e) => handleInputChange(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (!searchState) {
                    handleSearch();
                  }
                }
              }}
            />
            <div className={"right-element-close"}>
              <span
                className={`icon-search ${searchState ? "close" : ""}`}
                onClick={handleSearch}
              />
            </div>
          </div>
        )}
      </div>
      <div className="settingsNavCont">
        <div className="settingsNav">
          {filteredHeaderList.map((permission) => (
            <h1
              key={permission}
              className={
                headerButton === permission
                  ? "activeSettingsHeaders"
                  : "settingsHeaders"
              }
              onClick={() => setHeaderButton(permission)}
            >
              {permission}
            </h1>
          ))}
        </div>
        <div className="settingsPaginationCount">{settingsPaginationCount}</div>
      </div>
      {getComponents()}
    </div>
  );
}

export default TrackTraceDashboard;
