import {
  ASSET_CATEGORY_HIERARCHY,
  FACILITY_HIERARCHY,
} from "../../../Constants/constants";
import { DropdownType } from "../../../Utils/enumData";
import LocalStorageService from "../../../Utils/LocalStorageService";
import { FilterDataObj, FilterDataType } from "../types";

export default class AssetFilterData {
  getStatusFilterData = () => {
    let responseString = localStorage.getItem("facility_statistics_cache");
    const response = JSON.parse(responseString);
    const assetStatus = response?.data?.statusTypes?.FXD_ASSET_STATUS;
    const result: FilterDataObj[] =
      assetStatus?.map((element: any) => ({
        id: element.statusCode,
        name: element.description,
        group: "Status",
        isSelected: false,
        isVisible: true,
        parentId: "",
      })) || [];
    return result;
  };

  getAssetCategoryFilterData = () => {
    const assetCategoryList: {
      parentDescription: string;
      fixedAssetTypeId: string;
      description: string;
      parentFixedAssetTypeId: string;
    }[] = LocalStorageService.getUserSpecificAssetTypeData();

    const result: FilterDataObj[] = Array.from(
      new Set(assetCategoryList?.map((type) => type.parentFixedAssetTypeId))
    ).map((parentFixedAssetTypeId) => {
      const firstMatch = assetCategoryList.find(
        (type) => type.parentFixedAssetTypeId === parentFixedAssetTypeId
      );
      return {
        id: parentFixedAssetTypeId,
        name: firstMatch?.parentDescription,
        group: "Asset Category Live",
        isSelected: false,
        isVisible: true,
        parentId: "",
      };
    });
    return result;
  };

  getAssetTypeFilterData = () => {
    const assets: {
      parentDescription: string;
      fixedAssetTypeId: string;
      description: string;
      parentFixedAssetTypeId: string;
    }[] = LocalStorageService.getUserSpecificAssetTypeData();

    const result: FilterDataObj[] =
      assets?.map((element) => ({
        id: element.fixedAssetTypeId,
        name: element.description || element.fixedAssetTypeId,
        group: "Asset Type",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFixedAssetTypeId,
      })) || [];
    return result;
  };

  getRegionFilterData = () => {
    let response: {
      facilityGroupTypeId: string;
      parentFacilityGroupType: string;
      facilityGroupId: string;
      facilityGroupName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getRegionData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityGroupId,
        name: element.facilityGroupName,
        group: "Region",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId,
      })) || [];
    return result;
  };

  getCityFilterData = () => {
    let response: {
      facilityGroupTypeId: string;
      parentFacilityGroupType: string;
      facilityGroupId: string;
      facilityGroupName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getCityData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityGroupId,
        name: element.facilityGroupName,
        group: "City",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId,
      })) || [];
    return result;
  };

  getClusterFilterData = () => {
    let response: {
      facilityGroupTypeId: string;
      parentFacilityGroupType: string;
      facilityGroupId: string;
      facilityGroupName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getClusterData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityGroupId,
        name: element.facilityGroupName,
        group: "Cluster",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId,
      })) || [];
    return result;
  };

  getFacilityFilterData = () => {
    let response: {
      facilityId: string;
      parentFacilityGroupType: string;
      facilityName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getFacilityData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityId,
        name: element.facilityName,
        group: "Facility",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId,
      })) || [];
    return result;
  };

  getAssetFilterData = () => {
    const newFilterList: FilterDataType[] = [];

    // Asset Status
    const statusList = this.getStatusFilterData();
    newFilterList.push({
      key: "Status",
      data: statusList,
      placeholder: "Asset Status",
      dropdown: DropdownType.SINGLE,
      parent: "",
      searchValue: "",
      selectedCount: 0,
      hierarchyList: [],
      selectedChildren: [],
    });

    // Asset Category -> Asset Type

    // For Asset Category
    const assetCategory = this.getAssetCategoryFilterData();

    // For Asset Type
    const assetTypeList = this.getAssetTypeFilterData();
    const assetTypeData: FilterDataObj[] = [...assetCategory, ...assetTypeList];
    const distinctAssetType = [
      ...new Set(assetTypeData.map((item) => item.group)),
    ];

    newFilterList.push({
      key: "Asset Category/Type",
      data: assetTypeData,
      placeholder: "Asset Category/Type",
      dropdown:
        distinctAssetType.length === 1
          ? DropdownType.SINGLE
          : DropdownType.MULTIPLE,
      parent: "",
      searchValue: "",
      selectedCount: 0,
      hierarchyList: ASSET_CATEGORY_HIERARCHY,
      selectedChildren: [],
    });

    // Region -> City -> Cluster -> Facility

    // For Region
    const regionList = this.getRegionFilterData();

    // For City
    const cityList = this.getCityFilterData();

    // For Cluster
    const clusterList = this.getClusterFilterData();

    // For Facility
    const facilityList = this.getFacilityFilterData();

    const facilityGroupData = [
      ...regionList,
      ...cityList,
      ...clusterList,
      ...facilityList,
    ];
    const distinctFacilityGroup = [
      ...new Set(facilityGroupData.map((item) => item.group)),
    ];

    newFilterList.push({
      key: "Facility Groups/Facilities",
      data: facilityGroupData,
      placeholder: "Facility Groups/Facilities",
      dropdown:
        distinctFacilityGroup.length === 1
          ? DropdownType.SINGLE
          : DropdownType.MULTIPLE,
      parent: "",
      searchValue: "",
      selectedCount: 0,
      hierarchyList: FACILITY_HIERARCHY,
      selectedChildren: [],
    });

    return newFilterList;
  };
}
