import "./SingleTypeFilter.scss";
import { FilterDataObj, FilterDataType } from "../../assetList/types";
import DownArrowIcon from "../assets/dropdownIcon.svg";
import FilterLabel from "./FilterLabel";

type PropTypes = {
  data: FilterDataType;
  index: number;
  activeInput: string;
  searchSingleTypeFilterData: (value: string, index: number) => void;
  setActiveInput: (value: string) => void;
  handleSelectedFilter: (obj: FilterDataObj, index: number) => void;
  disabled: boolean;
};

function SingleTypeFilter({
  data,
  index,
  activeInput,
  searchSingleTypeFilterData,
  setActiveInput,
  handleSelectedFilter,
  disabled,
}: PropTypes) {
  if (activeInput === data.key) {
    return (
      <div className="singleTypeFilterMainDiv">
        <div className="filterSelectorMainContainer">
          <div className="chipFilterSelector">
            <input
              type="text"
              placeholder="Search..."
              value={data.searchValue}
              className="searchFilterInput"
              onChange={(e) =>
                searchSingleTypeFilterData(e.target.value, index)
              }
            />
            <img src={DownArrowIcon} onClick={() => setActiveInput("")} />
          </div>
          <div className="singleTypeFilterList">
            {data.data.map((obj: FilterDataObj) => (
              <>
                {obj.isVisible && (
                  <FilterLabel
                    isSelected={obj.isSelected}
                    title={obj.name}
                    onClick={() => handleSelectedFilter(obj, index)}
                  />
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
      <button
        className="selectorMainContainer"
        onClick={() => setActiveInput(data.key)}
        disabled={disabled}
      >
        <div className="filterSelector">
          <p className="chipFilterPlaceholder">
            {data.placeholder}{" "}
            {data.selectedCount > 0 && (
              <span className="selectedFilterCount">{data.selectedCount}</span>
            )}
          </p>
          <img src={DownArrowIcon} className="dropdownIcon" />
        </div>
      </button>
  );
}

export default SingleTypeFilter;
