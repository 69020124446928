import { API_BASE_URL } from "../../../Constants/constants";

const abortControllers: { [key: string]: AbortController } = {};

const getAbortController = (key: string): AbortController => {
  if (!abortControllers[key]) {
    abortControllers[key] = new AbortController();
  }
  return abortControllers[key];
};

export const updateAcControllerSettings = async (body: object) => {
  const url = `${API_BASE_URL}assets/assetControlCmds`;
  const tokenID = localStorage.getItem("@tokenID");
  const key = "updateAcControllerSettings";

  try {
    const abortController = getAbortController(key);

    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenID}`,
      },
      signal: abortController.signal,
    });

    if (!resp.ok) {
      return resp.status;
    }

    return resp.status;
  } catch (error) {
    return error;
  }
};

export const getAcControllerSetting = async (
  assetId: string,
  macId: string
) => {
  const url = `${API_BASE_URL}assets/getLatestCommand?fixedAssetId=${assetId}&deviceId=${macId}`;
  const tokenID = localStorage.getItem("@tokenID");
  const key = "getAcControllerSetting";

  try {
    const abortController = getAbortController(key);

    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenID}`,
      },
      signal: abortController.signal,
    };
    const resp = await fetch(url, option);

    if (!resp.ok) {
      return resp.status;
    }

    return await resp.json();
  } catch (error) {
    return error;
  }
};

export const getAssetAckCommand = async (
  deviceId: string,
  assetId: string,
  fromDate: number
) => {
  const url = `${API_BASE_URL}assets/getAssetAckCommand?deviceId=${deviceId}&fixedAssetId=${assetId}&fromDate=${fromDate}`;
  const tokenID = localStorage.getItem("@tokenID");
  const key = "getAssetAckCommand";

  try {
    const abortController = getAbortController(key);

    const option = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenID}`,
      },
      signal: abortController.signal,
    };
    const resp = await fetch(url, option);

    if (!resp.ok) {
      return resp.status;
    }

    const response = await resp.json()

    const returnData =  {
      "triggerAck": "{\"COMP\":false,\"STATE\":false}",
      "cmd": "1000",
      "deviceId": "E8:9F:6D:E8:38:7C"
  }

    return response?.acknowledgement;
    // return returnData
  } catch (error) {
    return error;
  }
};
