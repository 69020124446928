import React, { useState, useEffect, useRef } from "react";
import TempImg from "../../../assets/icons/icon-suction-temperature-compressor.svg";
import condensorImg from "../../../assets/icons/icon-condensation-temperature-compressor.svg";
import voltageIcon from "../../../assets/icons/VoltageImg.svg";
import currentImg from "../../../assets/icons/CurrentImg.svg";
import './AirControllerCard.scss';
import { consumptionAssetDataType, compressorLiveCardObj } from "../../../DataTypes/DataTypes";
import { getCheckNullValidation, getCheckAbbrevationValue } from "../../../Utils/utils";

const AirControllerLiveCard = ({ data, closeOpenBtn, maxValue }: consumptionAssetDataType) => {
  const temperature = useRef<compressorLiveCardObj>({ value: "-", setPoint: '-', readingUomDesc: '-' });
  const currentValue = useRef<compressorLiveCardObj>({ value: "-", setPoint: '-', readingUomDesc: '-' });
  const voltageValue = useRef<compressorLiveCardObj>({ value: "-", setPoint: '-', readingUomDesc: '-' });
  const actualSetPoint = useRef<compressorLiveCardObj>({ value: "-", setPoint: '-', readingUomDesc: '-' });
  const desiredSetPoint = useRef<compressorLiveCardObj>({ value: "-", setPoint: '-', readingUomDesc: '-' });
  useEffect(() => {
    if (typeof data === 'object' && data.length > 0) {
      for (let obj of data) {
        if (obj.meterTypeId === "TEMP") {
          temperature.current = ({
            ...temperature.current,
            value: getCheckNullValidation(obj.meterValue),
            readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
          });
        }
        else if (obj.meterTypeId === "CURRENT") {
          currentValue.current = ({
            ...currentValue.current,
            value: getCheckNullValidation(obj.meterValue),
            readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
          });
        }
        // else if (obj.meterTypeId === "VOLTAGE") {
        //   voltageValue.current = ({
        //     ...voltageValue.current,
        //     value: getCheckNullValidation(obj.meterValue),
        //     readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
        //   });
        // }
        else if (obj.meterTypeId === "COMP_STATUS") {
          actualSetPoint.current = ({
            ...actualSetPoint.current,
            value: obj.meterValue,
            readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
          });
        }
        // else if (obj.meterTypeId === "COND_TEMP_2_SET_PT") {
        //   desiredSetPoint.current = ({
        //     ...actualSetPoint.current,
        //     value: getCheckNullValidation(obj.meterValue),
        //     readingUomDesc: getCheckAbbrevationValue(obj.readingUomDesc)
        //   });
        // }
      }
    }
  }, [data]);
  return (
    <div className="tempEnergyContainer">
      <div className="cardsRowCont">
        <div className="rowOne">
          <div className="card1Container">
            <p className="card1Heading">Temperature</p>
            <div className="card1ValuesCont">
              <img src={TempImg} alt="icon" loading="lazy" className="card1Icon" />
              <p className="card1Values">  {temperature.current.value}{temperature.current.readingUomDesc} </p>
            </div>
            <div className="cardSetPoint">
              <h2>Set Point</h2>{" "}
              <h3>{maxValue}{" "}<span>
                {temperature.current.readingUomDesc}
              </span></h3>

            </div>
          </div>

          <div className="card2Container">
            <p className="card2Heading">Current</p>
            <div className="card2ValuesCont">
              <img src={currentImg} loading="lazy" alt="icon" className="card2Icon" />
              <p className="card2Values">  {currentValue.current.value} </p>
            </div>
          </div>

          <div className="card2Container">
            <p className="card2Heading">Load Status</p>
            <div className="card2ValuesCont">
              {/* <img src={condensorImg} loading="lazy" alt="icon" className="card1Icon" /> */}
              <p className="card2Values">  {actualSetPoint.current.value === 'N' ? "OFF" : actualSetPoint.current.value === 'Y' ? "ON" : '-'}</p>
            </div>
          </div>
        </div>
        {/* {closeOpenBtn && (
          <div className="rowOne">
            <div className="card1Container">
              <p className="card1Heading">Desired Set Point</p>
              <div className="card1ValuesCont">
                <img src={condensorImg} loading="lazy" alt="icon" className="card1Icon" />
                <p className="card1Values">  {desiredSetPoint.current.value}{desiredSetPoint.current.readingUomDesc} </p>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default AirControllerLiveCard