import React, { useEffect, useState } from "react";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import { useQuery } from "react-query";
import { getAcControllerSetting } from "../service/AcControllerApi";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import TimerMode from "./TimerMode";
import { AcControllerUtils } from "../utils/AcControllerUtils";
import { AcControllerSetting } from "../types";
import TemperatureMode from "./TemperatureMode";
import UiMode from "./UiMode";

type PropsType = {
    assetId: string;
    macId: string;
};

type AcControllerSettingItem = {
    timerMode: AcControllerSetting;
    tempMode: AcControllerSetting;
    opMode: AcControllerSetting;
};

const AcControllerSummaryCard = ({ assetId, macId }: PropsType) => {
    const { data, error, isLoading, isFetching, refetch } = useQuery(
        "getAcControllerSetting",
        () => getAcControllerSetting(assetId, macId),
        { enabled: false }
    );

    const [acControllerSettingItem, setAcControllerSettingItem] =
        useState<AcControllerSettingItem>({
            timerMode: {
                key: "",
                data: {
                    state: undefined,
                    opMode: undefined,
                    onTimeHour: undefined,
                    onTimeMin: undefined,
                    offTimeHour: undefined,
                    dayOfWeek: undefined,
                    timerFlag: undefined,
                    offTimeMin: undefined,
                    setTemp: undefined,
                    macId: undefined,
                    tempFlag: undefined,
                },
                latestUpdate: 0,
            },
            tempMode: {
                key: "",
                data: {
                    state: undefined,
                    opMode: undefined,
                    onTimeHour: undefined,
                    onTimeMin: undefined,
                    offTimeHour: undefined,
                    dayOfWeek: undefined,
                    timerFlag: undefined,
                    offTimeMin: undefined,
                    setTemp: undefined,
                    macId: undefined,
                    tempFlag: undefined,
                },
                latestUpdate: 0,
            },
            opMode: {
                key: "",
                data: {
                    state: undefined,
                    opMode: undefined,
                    onTimeHour: undefined,
                    onTimeMin: undefined,
                    offTimeHour: undefined,
                    dayOfWeek: undefined,
                    timerFlag: undefined,
                    offTimeMin: undefined,
                    setTemp: undefined,
                    macId: undefined,
                    tempFlag: undefined,
                },
                latestUpdate: 0,
            },
        });

    const fetchDataAndUpdateState = async () => {
        try {
            await refetch();
        } catch (error) {
            console.error("Error re-fetching data:", error);
        }
    };

    const filterSettingControl = () => {
        if (data?.assetCommand.hasOwnProperty("fixedAssetId")) {
            const utils = new AcControllerUtils();
            const result = utils.extractAcControllerSetting(data?.assetCommand);
            const settingItem: any = {};
            result.forEach((item) => {
                if (item.key === "1000") {
                    settingItem.opMode = item;
                } else if (item.key === "202") {
                    settingItem.timerMode = item;
                } else if (item.key === "203") {
                    settingItem.tempMode = item;
                }
            });
            setAcControllerSettingItem(settingItem);
        }
    };

    useEffect(() => {
        fetchDataAndUpdateState();
    }, [assetId, macId, refetch]);

    useEffect(() => {
        if (data !== undefined) {
            filterSettingControl();
        }
    }, [data]);

    if (isLoading || isFetching) {
        return <LoaderStyle />;
    }

    if (error) {
        return <DisplayError type={"err-empty"} />;
    }

    return (
        <div className="acControllerContainerDiv">
            <TimerMode
                acControllerSetting={acControllerSettingItem.timerMode}
                assetId={assetId}
                macId={macId}
            />
            <TemperatureMode
                acControllerSetting={acControllerSettingItem.tempMode}
                assetId={assetId}
                macId={macId}
            />
            <UiMode
                assetId={assetId}
                macId={macId}
                acControllerSetting={acControllerSettingItem.opMode}
            />
        </div>
    );
};

export default AcControllerSummaryCard;
