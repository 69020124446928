import React from 'react'
import InActiveMeterIcon from "../../../assets/icons/metersInActiveIcon.svg"
import EnergyVoltsIcon from "../../../assets/icons/energyVoltsIcon.svg"
import ActiveMeterIcon from "../../../assets/icons/activeEnergyIcon.svg"
import EnergyNavIcon from "../../../assets/icons/energyNavIcon.svg"
import MainMeterActive from '../../../assets/icons/MainMeterECActive.svg';
import MainMeterNotActive from '../../../assets/icons/MainMeterECNonActive.svg';
import * as R from "ramda";
import "./MeterCard.scss"

function MeterCard(props: any) {
    const { data, decimalVal, changeMeterId, active, tabsArr, activeTab, consumption , abbreviation } = props;
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimalVal,
    });
    return (
        <div className={active ? 'meterCard activeMeter' : 'meterCard'} onClick={() => changeMeterId(data.fixedAssetId)}>
            <div className='metricIconsContainer'>
                {
                    tabsArr[+activeTab].name === "Energy" ? <> {data.classEnum === 'Sub meter' ? <img src={!active ? InActiveMeterIcon : ActiveMeterIcon} alt="icon" className="inactiveIcon" /> : <img src={!active ? MainMeterNotActive : MainMeterActive} alt="icon" className="inactiveIcon" />}
                    </> : <>{data.classEnum === 'Sub meter' ? <img src={tabsArr[+activeTab].subMeterIcon} alt="icon" className="inactiveIcon" /> : <img src={tabsArr[+activeTab].meterIcon} alt="icon" className="inactiveIcon" />}</>}
                {/* {active && <img src={EnergyNavIcon} alt="icon" />} */}
            </div>

            <p className={data.fixedAssetName?.length >25 ? 'meterCardHeader': 'meterCardHeader1' }><span>{data.fixedAssetName}</span></p>
            <div className="meterValuesCont">
                <img src={tabsArr[+activeTab].consumedIcon} alt="icon" className="voltsIcon" />
                <p className="meterValues">
                {/* meterValues */}
                    {
                      consumption == null ? '- ' :  formatter.format(consumption)
                    } {consumption !== null && abbreviation}</p>
            </div>
            {data.classEnum !== 'Sub meter' && data.classEnum !== null && <p className='mainMeterDesc'> {data.classEnum} </p>}
        </div>
    )
}

export default MeterCard