import React, { useState,useEffect, useRef } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, CanvasRenderer]);

function AnoviComplianceStackedBar({ data }: any) {
  const chartContainerRef = useRef<HTMLDivElement>(null);

   const truncateName = (name: string) => (name.length > 10 ? name.slice(0, 10) + "..." : name);

  // Calculate the chart height based on the number of data points
  const chartHeight=Math.max(100,data.length*60);

  const [options,setOptions]= useState({
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    color: ["#97aefe", "#4caf50", "#f6754c"],
    legend: {
      show: true,
      bottom: 0,
      left: 0,
      textStyle: { color: "#0B0B0C", fontSize: 12 },
      pointerEvents: "none",
    },

    grid: {
      bottom: 35,
      left: 0,
      right: 15,
      top: 0,
      containLabel: true,
    },
    xAxis: {
      type: "value",
      max: "dataMax",
      axisLabel: {
        margin: -5,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      offset: -4.5,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      data: [], 
      axisLabel: {
        formatter: (value: any) => truncateName(value),
        tooltip: {
          show: true,
        },
        margin: 15,
      },
    },
    series: [
      {
        name: "Below Threshold",
        type: "bar",
        barWidth: 25,
        stack: "stacked",
        color: "#97aefe",
        emphasis: {
          focus: "none",
        },
        label: {
          show: true,
          color: "#FFFFFF",
          formatter: (params: any) => {
            const labelValue = params.value < 8 ? "" : `${params.value}`;
            return labelValue;
          },
        },
        data: [],
        itemStyle: {
          opacity: 1,
          borderRadius: [0, 2, 2, 0],
          borderWidth: 20,
        },
      },
      {
        name: "Compliant",
        type: "bar",
        barWidth: 25,
        stack: "stacked",
        color: "#4caf50",
        pointerEvents: "none",
        label: {
          show: true,
          color: "#FFFFFF",
          formatter: (params: any) => {
            const labelValue = params.value < 8 ? "" : `${params.value}`;
            return labelValue;
          },
        },
        emphasis: {
          focus: "none",
        },
        data: [], 
        itemStyle: {
          borderRadius: [2, 2, 2, 2],
          opacity: 1,
          borderWidth: 20,
        },
      },
      {
        name: "Above Threshold",
        type: "bar",
        barWidth: 25,
        stack: "stacked",
        color: "#f6754c",
        label: {
          show: true,
          color: "#FFFFFF",
          formatter: (params: any) => {
            const labelValue = params.value < 8 ? "" : `${params.value}`;
            return labelValue;
          },
        },
        emphasis: {
          focus: "none",
        },
        data: [], 
        itemStyle: {
          borderRadius: [0, 2, 2, 0],
          pointerEvents: "none",
          opacity: 1,
          borderWidth: 20,
        },
      },
    ],
    barGap: "10%",
    barCategoryGap: "50%",
    barMinHeight: 10,
  });


  useEffect(() => {

    // options.yAxis.data = data.map((el: any) => truncateName(el.name));
    // options.series[0].data = data.map((el: any) => parseFloat(el.ncBelowThreshold));
    // options.series[1].data = data.map((el: any) => parseFloat(el.compliancePercentage));
    // options.series[2].data = data.map((el: any) => parseFloat(el.ncAboveThreshold));

    //to resize the charts according to window size
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      yAxis: {...prevOptions.yAxis,data: data.map((el: any) => truncateName(el.name))},
      series: [
        {...prevOptions.series[0],data: data.map((el: any) => parseFloat(el.ncBelowThreshold)) },
        {...prevOptions.series[1],data: data.map((el: any) => parseFloat(el.compliancePercentage))},
        {...prevOptions.series[2],data: data.map((el: any) => parseFloat(el.ncAboveThreshold))},
      ]
    }));

    
    const chartInstance = echarts.init(chartContainerRef.current!);
    chartInstance.setOption(options);

    const handleResize = () => {
      chartInstance.resize();
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
       window.removeEventListener("resize", handleResize);
       chartInstance.dispose();
     };
  }, [options]);


  return (

      <div ref={chartContainerRef} style={{ width: "100%", height: `${chartHeight}px`}}>
        <ReactEChartsCore
          echarts={echarts}
          option={options}
          notMerge={true}
          lazyUpdate={true}
          theme={"theme_name"}
        />
      </div>
    
  );
}

export default AnoviComplianceStackedBar;
