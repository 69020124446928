/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React from "react";

// import TempImg from "../../../assets/icons/TempImg.svg";
// import CurrentImg from "../../../assets/icons/CurrentImg.svg";
// import VoltageImg from "../../../assets/icons/VoltageImg.svg";
// import PowerImg from "../../../assets/icons/PowerImg.svg";
// import FrequencyImg from "../../../assets/icons/FrequencyImg.svg"
import BatteryImg from "../../../assets/icons/BatteryImg.svg";
import TempImg from "../../../assets/icons/TempImg.svg";
import LubeoilImg from "../../../assets/icons/LubeoilImg.svg";
import RPMImg from "../../../assets/icons/RPMImg.svg";

import "./ASDG.scss";
import * as R from "ramda";

function ASDG({ data }:any) {
  // console.log("the assetDetail data", data);
  return (
    <>
      <div className="dgContainer">
        {/* <div className="cardOneCont">
          <p className="cardOneHeading">Battery Voltage</p>
          <div className="cardOneValuesCont">
            <img src={BatteryImg} alt="icon" className="cardOneIcon" />
            <p className="cardOneValues">10 A</p>
          </div>
        </div> */}
        {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
          data?.meters.map(
            (el, index) =>
              el.meterTypeId.includes("BATTERY_VOLTAGE") && (
                <>
                  <div className="cardOneCont">
                    <p className="cardOneHeading">Battery Voltage</p>
                    <div className="cardOneValuesCont">
                      <img
                        src={BatteryImg}
                        alt="icon"
                        className="cardOneIcon"
                      />
                      <p className="cardOneValues">
                        {Number(el?.meterValue).toFixed(2)}°{el.readingUomDesc}
                      </p>
                    </div>
                  </div>
                </>
              )
          )
        ) : (
          <>
            <div className="cardOneCont">
              <p className="cardOneHeading">Battery Voltage</p>
              <div className="cardOneValuesCont">
                <img src={BatteryImg} alt="icon" className="cardOneIcon" />
                <p className="cardOneValues">-</p>
              </div>
            </div>
          </>
        )}
        {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
          data?.meters.map(
            (el, index) =>
              el.meterTypeId.includes("COOLTEMP") && (
                <>
                  <div className="cardOneCont">
                    <p className="cardOneHeading">Coolant Temperature</p>
                    <div className="cardOneValuesCont">
                      <img src={TempImg} alt="icon" className="cardOneIcon" />
                      <p className="cardOneValues">
                        {" "}
                        {Number(el?.meterValue).toFixed(2)} °C
                      </p>
                    </div>
                  </div>
                </>
              )
          )
        ) : (
          <>
            <div className="cardOneCont">
              <p className="cardOneHeading">Coolant Temperature</p>
              <div className="cardOneValuesCont">
                <img src={TempImg} alt="icon" className="cardOneIcon" />
                <p className="cardOneValues">-</p>
              </div>
            </div>
          </>
        )}
        {/* <div className="cardOneCont">
          <p className="cardOneHeading">Coolant Temperature</p>
          <div className="cardOneValuesCont">
            <img src={TempImg} alt="icon" className="cardOneIcon" />
            <p className="cardOneValues">40.00 °C</p>
          </div>
        </div> */}

        {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
          data?.meters.map(
            (el, index) =>
              el.meterTypeId.includes("OILPRESS") && (
                <>
                  <div className="cardOneCont">
                    <p className="cardOneHeading">Lube Oil Pressure</p>
                    <div className="cardOneValuesCont">
                      <img
                        src={LubeoilImg}
                        alt="icon"
                        className="cardOneIcon"
                      />
                      <p className="cardOneValues">
                        {Number(el?.meterValue).toFixed(2)} Bar
                      </p>
                    </div>
                  </div>
                </>
              )
          )
        ) : (
          <>
            <div className="cardOneCont">
              <p className="cardOneHeading">Lube Oil Pressure</p>
              <div className="cardOneValuesCont">
                <img src={LubeoilImg} alt="icon" className="cardOneIcon" />
                <p className="cardOneValues">-</p>
              </div>
            </div>
          </>
        )}
        {/* <div className="cardOneCont">
          <p className="cardOneHeading">Lube Oil Pressure</p>
          <div className="cardOneValuesCont">
            <img src={LubeoilImg} alt="icon" className="cardOneIcon" />
            <p className="cardOneValues">0.00 Bar</p>
          </div>
        </div> */}

        {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
          data?.meters.map(
            (el, index) =>
              el.meterTypeId.includes("ENGSPD") && (
                <>
                  <div className="cardOneCont">
                    <p className="cardOneHeading">Engine RPM</p>
                    <div className="cardOneValuesCont">
                      <img src={RPMImg} alt="icon" className="cardOneIcon" />
                      <p className="cardOneValues">
                        {Number(el?.meterValue).toFixed(2)}
                      </p>
                    </div>
                  </div>
                </>
              )
          )
        ) : (
          <>
            <div className="cardOneCont">
              <p className="cardOneHeading">Engine RPM</p>
              <div className="cardOneValuesCont">
                <img src={RPMImg} alt="icon" className="cardOneIcon" />
                <p className="cardOneValues">-</p>
              </div>
            </div>
          </>
        )}
        {/* <div className="cardOneCont">
          <p className="cardOneHeading">Engine RPM</p>
          <div className="cardOneValuesCont">
            <img src={RPMImg} alt="icon" className="cardOneIcon" />
            <p className="cardOneValues">300</p>
          </div>
        </div> */}

        {!R.isEmpty(data?.load) && !R.isNil(data?.load) ? (
          <>
            {/* <div className="cardOneCont">
                    <p className="cardOneHeading">Engine RPM</p>
                    <div className="cardOneValuesCont">
                      <img src={RPMImg} alt="icon" className="cardOneIcon" />
                      <p className="cardOneValues">
                        {Number(el?.meterValue).toFixed(2)}
                      </p>
                    </div>
                  </div> */}
            <div className="cardTwoCont">
              <p className="cardTwoHeading">Load</p>
              <div className="cardTwoValuesCont">
                <img src={RPMImg} alt="icon" className="cardTwoIcon" />
                <p className="cardTwoValues">{data?.load.value} kVA | -</p>
              </div>
              {/* <p className="cardTwoTxt">Threshold: 550 kVA</p> */}
              <p className="cardTwoTxt">Threshold: -</p>
            </div>
          </>
        ) : (
          <>
            <div className="cardTwoCont">
              <p className="cardTwoHeading">Load</p>
              <div className="cardTwoValuesCont">
                <img src={RPMImg} alt="icon" className="cardTwoIcon" />
                <p className="cardTwoValues">-</p>
              </div>
              <p className="cardTwoTxt">Threshold: -</p>
            </div>
          </>
        )}
        {/* <div className="cardTwoCont">
          <p className="cardTwoHeading">Load</p>
          <div className="cardTwoValuesCont">
            <img src={RPMImg} alt="icon" className="cardTwoIcon" />
            <p className="cardTwoValues">300 kVA | 50%</p>
          </div>
          <p className="cardTwoTxt">Threshold: 550 kVA</p>
        </div> */}
      </div>
    </>
  );
}

export default ASDG;
