import { useEffect, useRef } from "react";
import "./EventListDashboard.scss";
import BackArrow from "../../assets/backArrow.svg";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getEventList } from "../../service/EventListService";
import { useStateValue } from "../../../../redux/StateProvider";
import EventListState from "../../state/EventListState";
import DisplayError from "../../../../a1Components/DisplayError/DisplayError";
import {
  EventLog,
  FilterDataObj,
  FilterDataType,
} from "../../types/EventListTypes";
import EventListCard from "../EventListCard";
import { DropdownType } from "../../../../Utils/enumData";
import SingleTypeFilter from "../../../filterComponent/components/SingleTypeFilter";
import MultileTypeFilter from "../../../filterComponent/components/MultileTypeFilter";
import LocalStorageService from "../../../../Utils/LocalStorageService";
import { SettingsApiServices } from "../../../../services/settingsPageServices/SettingsApiServices";
import * as R from "ramda";
import { cancelExistingQuery } from "../../../../Utils/utils";
import { FilterUtils } from "../../../assetList/utils/FilterUtils";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import EventFilterData from "../../utils/EventFilterData";
import { checkAssetDataStatus } from "../../../assetList/utils";
import LoaderStyle from "../../../../a1Components/LoaderStyle/LoaderStyle";
import LoadMoreBtn from "../../../assetList/components/LoadMoreBtn";

function EventListDashboard() {
  const queryClient = useQueryClient();

  const [{ dateRange }] = useStateValue();
  const pageViewSize = 20;
  const pageIndex = useRef<number>(1);
  const nextPageIndex = useRef<number>(1);
  const totalPages = useRef<number | null>(null);

  const {
    hoveredIndex,
    setHoveredIndex,
    filterDataList,
    setFilterDataList,
    filterDataListRef,
    searchByList,
    setSearchByList,
    searchByListRef,
    activeInput,
    setActiveInput,
    disableApplyFilter,
    setDisableApplyFilter,
    showClearAll,
    setShowClearAll,
    eventList,
    setEventList,
    showLoading,
    setShowLoading,
    showLoadMore,
    setShowLoadMore,
    showError,
    setShowError,
    loadMoreStatus,
    setLoadMorestatus,
    eventDataStatus,
    setEventDataStatus,
    eventCountStatus,
    setEventCountStatus,
  } = EventListState();

  const { data, isLoading, isFetching, error, refetch, dataUpdatedAt } =
    useQuery(
      "getEventListDashboard",
      () =>
        getEventList(
          pageIndex.current,
          pageViewSize,
          moment(dateRange[0]).valueOf(),
          moment(dateRange[1]).valueOf(),
          searchByListRef.current
        ),
      {
        enabled: false,
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (fetchedData) => {
          if (
            fetchedData.hasOwnProperty("eventLog") &&
            fetchedData.hasOwnProperty("paginationMap")
          ) {
            setShowLoading(false);
            setShowError(false);
            if (pageIndex.current == 1) {
              setEventList(() => fetchedData.eventLog);
            } else if (
              pageIndex.current === nextPageIndex.current &&
              eventList.length !== fetchedData.paginationMap.totalCount
            ) {
              setEventList((prevList) => [
                ...prevList,
                ...fetchedData?.eventLog,
              ]);
            } else {
              setEventList((prevList) => [
                ...prevList?.slice(0, 0 - fetchedData?.eventLog?.length),
                ...fetchedData?.eventLog,
              ]);
            }
            //Api dependency
            if (!R.isEmpty(fetchedData.eventLog)) {
              nextPageIndex.current = fetchedData.paginationMap.nextPage;
              totalPages.current = fetchedData.paginationMap.totalPage;
              setShowLoadMore(
                fetchedData.paginationMap.currentPage ===
                  fetchedData.paginationMap.nextPage
              );
            } else {
              setShowLoadMore(true);
            }
            setLoadMorestatus(false);
          }
        },
        onError: () => {
          setShowLoading(false);
          setShowError(true);
          setLoadMorestatus(false);
        },
      }
    );

  const fetchDataAndUpdateState = async () => {
    try {
      cancelExistingQuery("getEventListDashboard", queryClient);
      await refetch();
    } catch (error) {
      console.error("Error in refetching api:", error);
    }
  };

  const handleMouseEnter = (index: number) => {
    setActiveInput("");
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const getAssetFilterData = async () => {
    let tokenID = LocalStorageService.getTokenData();
    const services = new SettingsApiServices();
    const response = await services.FacilityStatistics(tokenID);

    if (
      !R.isEmpty(response) &&
      !(
        response.statusCode == "BAD_REQUEST" ||
        response.responseMessage == "error"
      )
    ) {
      const utils = new EventFilterData();
      const newFilterList = utils.getEventFilterData();
      setFilterDataList(newFilterList);
    }
  };

  const searchSingleTypeFilterData = (value: string, index: number) => {
    const utils = new FilterUtils();
    const result = utils.searchSingleTypeFilterData(
      value,
      index,
      filterDataListRef.current
    );
    setFilterDataList([...result]);
  };

  const handleSelectedFilter = (obj: FilterDataObj, index: number) => {
    setFilterDataList((prevFilterDataList) => {
      const tempFilterData = [...prevFilterDataList];
      const data = [...tempFilterData[index].data];
      const key = obj.group === 'Area' ? 'indexId' : 'id';
      const value = obj.group === 'Area' ? obj.indexId : obj.id;
      const dataIndex = data.findIndex(item => item[key] === value);

      data[dataIndex].isSelected = !data[dataIndex].isSelected;
      tempFilterData[index].data = data;
      const selectedCount = data.reduce(
        (count, item) => count + (item.isSelected ? 1 : 0),
        0
      );
      tempFilterData[index].selectedCount = selectedCount;
      return tempFilterData;
    });
  };

  const filterHierarchyData = (
    index: number,
    group: string,
    hierarchyList: string[]
  ) => {
    const utils = new FilterUtils();
    const response = utils.filterHierarchyData(
      index,
      group,
      hierarchyList,
      filterDataListRef.current
    );
    setFilterDataList(response);
  };

  const searchMultipleTypeFilterData = (value: string, index: number) => {
    const utils = new FilterUtils();
    const result = utils.searchMultipleTypeFilterData(
      value,
      index,
      filterDataListRef.current
    );
    setFilterDataList([...result]);
  };

  const handleFilterHeading = (index: number, group: string) => {
    const temp: FilterDataType[] = filterDataListRef.current;
    const selectedChildren: string[] = temp[index].selectedChildren;
    let newSelectedChildren: string[] = [];

    if (selectedChildren.includes(group)) {
      newSelectedChildren = selectedChildren.filter(
        (item: string) => item !== group
      );
    } else {
      newSelectedChildren = [...selectedChildren, group];
    }

    temp[index].selectedChildren = newSelectedChildren;
    setFilterDataList([...temp]);
  };

  const updateSearchList = () => {
    const utils = new FilterUtils();
    const newSearchList = utils.updateSearchList(filterDataListRef.current, "");
    setSearchByList([...newSearchList]);

    cancelExistingQuery("getEventListDashboard", queryClient);
    pageIndex.current = 1;
    nextPageIndex.current = 1;
    fetchDataAndUpdateState();
    setEventList([]);
    setShowLoading(true);
    setShowClearAll(true);
  };

  const handleClearFilter = async () => {
    const utils = new FilterUtils();
    const unselectedData = utils.clearSelectedFilter(filterDataListRef.current);
    setFilterDataList([...unselectedData]);

    setSearchByList([]);

    cancelExistingQuery("getEventListDashboard", queryClient);
    pageIndex.current = 1;
    nextPageIndex.current = 1;
    fetchDataAndUpdateState();
    setEventList([]);
    setShowLoading(true);
    setShowClearAll(false);
  };

  const handleLoadMore = async () => {
    pageIndex.current = nextPageIndex.current;
    setLoadMorestatus(true);
    cancelExistingQuery("getEventListDashboard", queryClient);
    fetchDataAndUpdateState();
  };

  /**
   * Mount and Unmount the component.
   */
  useEffect(() => {
    cancelExistingQuery("getEventListDashboard", queryClient);
    setShowLoading(true);
    fetchDataAndUpdateState();
    getAssetFilterData();
    return () => cancelExistingQuery("getEventListDashboard", queryClient);
  }, []);

  /**
   * Check for the Apply Button Disable status.
   */
  useEffect(() => {
    const checkButtonStatus = () => {
      const utils = new FilterUtils();
      const status = utils.checkFilterButtonDisable(filterDataListRef.current);
      setDisableApplyFilter(!status);
    };
    checkButtonStatus();
  }, [filterDataListRef.current]);

  useEffect(() => {
    fetchDataAndUpdateState();
  }, [dateRange, pageViewSize, pageIndex]);

  useEffect(() => {
    const updateAssetDataStatus = () => {
      const result = checkAssetDataStatus(
        isLoading,
        isFetching,
        showError,
        eventList?.length,
        showLoading,
        showLoadMore
      );
      setEventDataStatus(result);
    };
    updateAssetDataStatus();
  }, [isLoading, isFetching, showError, eventList, showLoading, showLoadMore]);

  /**
   * Update the Asset Count based on the current polling state.
   */
  useEffect(() => {
    const updateAssetCount = () => {
      const eventCountData = {
        assetsLengthCount: eventList.length,
        showAssetCount: !showLoading,
        totalAssetCount: data?.paginationMap?.totalCount,
      };
      setEventCountStatus(eventCountData);
      setDisableApplyFilter(true);
    };
    updateAssetCount();
  }, [isFetching, eventList, showLoading, data]);

  return (
    <div className="eventListDashboardMainDiv">
      <div className="eventListHeaderDiv">
        <Link to="/" style={{width:'fit-content'}}>
          <div className="goBackDiv">
            <img src={BackArrow} />
            <p className="goBackDivTitle">Dashboard</p>
          </div>
        </Link>
        <p className="componentHeading">Events</p>
      </div>

      {filterDataListRef.current.length !== 0 && (
        <div className="eventListFilterMainDiv">
          {eventCountStatus.showAssetCount && (
            <p className="eventCountStatusDiv">
              You are viewing {eventCountStatus.assetsLengthCount} /{" "}
              {eventCountStatus.totalAssetCount} incident(s){" "}
            </p>
          )}
          <div className="eventFilterRow">
            <Col xs={1} md={1} lg={1}>
              <p className="eventFilterHeading">Filter by</p>
            </Col>
            <Row style={{ width: "90%", alignItems: "center" }}>
              {filterDataListRef.current.map(
                (item: FilterDataType, index: number) => (
                  <Col
                    xs={0}
                    sm={6}
                    md={6}
                    lg={3}
                    style={{
                      padding: ".25rem",
                      height: "80%",
                      fontSize: "small",
                    }}
                  >
                    {item.dropdown === DropdownType.SINGLE ? (
                      <SingleTypeFilter
                        data={item}
                        index={index}
                        activeInput={activeInput}
                        searchSingleTypeFilterData={searchSingleTypeFilterData}
                        setActiveInput={setActiveInput}
                        handleSelectedFilter={handleSelectedFilter}
                        disabled={isLoading}
                      />
                    ) : (
                      <MultileTypeFilter
                        data={item}
                        index={index}
                        activeInput={activeInput}
                        setActiveInput={setActiveInput}
                        handleSelectedFilter={handleSelectedFilter}
                        filterHierarchyData={filterHierarchyData}
                        searchMultipleTypeFilterData={
                          searchMultipleTypeFilterData
                        }
                        handleFilterHeading={handleFilterHeading}
                        disabled={isLoading}
                      />
                    )}
                  </Col>
                )
              )}
              <button
                onClick={updateSearchList}
                onMouseOver={() => setActiveInput("")}
                disabled={disableApplyFilter}
                className={`filterApplyButton ${
                  disableApplyFilter
                    ? "disableFilterApplyButton"
                    : "enableFilterApplyButton"
                }`}
              >
                Apply
              </button>
              {showClearAll && (
                <p
                  onClick={handleClearFilter}
                  className="clearAllButton"
                  onMouseOver={() => setActiveInput("")}
                >
                  CLEAR ALL
                </p>
              )}
            </Row>
          </div>
        </div>
      )}

      {eventDataStatus.showLoading && !eventDataStatus.showAsset && (
        <div className="loadingBox2">
          <LoaderStyle />
        </div>
      )}

      {eventDataStatus.showEmptyData && (
        <div className="loadingBox2">
          <DisplayError type={"err-empty"} />
        </div>
      )}

      {eventDataStatus.showError && (
        <div className="loadingBox2">
          <DisplayError type={"err-500/404"} />
        </div>
      )}

      {eventDataStatus.showAsset && (
        <div className="eventListDashboardMainContainer">
          <div className="eventListDashboardMainCont">
            {eventList.map((item: EventLog, index: number) => (
              <>
                <EventListCard
                  key={`${item.eventId}_${item.eventDescription}`}
                  data={item}
                  index={index}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  hoveredIndex={hoveredIndex}
                />
                {eventDataStatus.showLoadMoreButton &&
                  index === eventList.length - 1 && (
                    <div className="loadMoreAssets">
                      <LoadMoreBtn
                        state={loadMoreStatus}
                        title="Load more"
                        loadingText={"Loading"}
                        onClickFunction={handleLoadMore}
                      />
                    </div>
                  )}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default EventListDashboard;
