import "./ToggleButton.scss";

type DataType = {
    label:string, 
    toggled:boolean, 
    onClick:(arr:Boolean) => void
}

export const ToggleButton = ({ label, toggled, onClick }:DataType) => {
    const callback = () => onClick(toggled);

    return (
        <label className="toggleButtonContainer">
          <input type="checkbox" defaultChecked={toggled} onClick={callback} />
          <span />
          <strong>{label}</strong>
        </label>
    )
}