/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import OptionsIcon from "../../../../assets/icons/OptionsIcon.svg";
import deleteIcon from "../../../../assets/icons/addFacDeleteIcon.png";
import DisabledIcon from "../../../../assets/icons/DisabledIcon.svg";
import AddIcon from "../../../../assets/icons/AddIcon.svg";
import IconEdit from "../../../../assets/icons/IconEdit.svg";
import IconDelete from "../../../../assets/icons/IconDelete.svg";
import UploadImgIcon from "../../../../assets/icons/UploadImgIcon.svg";
import CountryIcon from "../../../../assets/icons/CountryIcon.svg";
// import IconAdd from "../../../../assets/icons/IconAdd.svg";
import RemoveImgIcon from "../../../../assets/icons/RemoveImgIcon.svg";
import Edit from "../../../../assets/icons/Edit.svg";
import "./UserCard.scss";
import FacilityAddressPopUpScreen from "../../../FacilitySettings/facilitypopUpScreen/FacilityAddressPopUpScreen";
import CustomSwitch from "../customSwitch/CustomSwitch";
import userIcon from "../../../../assets/icons/temp_profile_icon.png";
import IconClose from "../../../../assets/icons/IconClose.svg";
import EditAddressIcon from "../../../../assets/icons/EditAddressIcon.svg";

function UserCard(props: any) {
  const { addToSelectedList, data, selected } = props;
  // const {userName, name} = data;
  console.log("userData", data);
  // creates random ID
  function generateID(length: any) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [addToFacilityGroup, setAddToFacilityGroup] = useState(false);
  const [mapUsertoFaclityGroupsArr, setMapUsertoFaclityroupsArr] = useState([
    {
      id: generateID(4),
      facilityList: ["Group-1", "Group-2", "Group-3", "Group-4"],
      rolesList: ["role-1", "role-2", "role-3", "role-4"],
    },
  ]);

  const addFacilityGroupRole = () => {
    const copyArray = [...mapUsertoFaclityGroupsArr];
    // Update the copy array
    copyArray.push({
      id: generateID(4),
      facilityList: [
        "Facility Group",
        "Group-1",
        "Group-2",
        "Group-3",
        "Group-4",
      ],
      rolesList: ["select role", "role-1", "role-2", "role-3", "role-4"],
    });
    setMapUsertoFaclityroupsArr(copyArray);
  };
  const deleteAddFaciityGroupRole = (id: any) => {
    let copyArray = [...mapUsertoFaclityGroupsArr];
    copyArray = copyArray.filter((el) => el.id !== id);
    setMapUsertoFaclityroupsArr(copyArray);
  };

  // Add to facility role
  const [addToFacilityShow, setAddToFacilityShow] = useState(false);
  const [mapUsertoFaclityArr, setMapUsertoFaclityArr] = useState([
    {
      id: generateID(4),
      facilityList: ["fac-1", "fac-2", "fa-3", "fac-4"],
      rolesList: ["role-1", "role-2", "role-3", "role-4"],
    },
  ]);

  const addFacilityRole = () => {
    const copyArray = [...mapUsertoFaclityArr];

    // Update the copy array
    copyArray.push({
      id: generateID(4),
      facilityList: ["Select Facility", "fac-1", "fac-2", "fa-3", "fac-4"],
      rolesList: ["Select Role", "role-1", "role-2", "role-3", "role-4"],
    });
    setMapUsertoFaclityArr(copyArray);
  };
  const deleteAddFaciityRole = (id: any) => {
    let copyArray = [...mapUsertoFaclityArr];
    copyArray = copyArray.filter((el) => el.id !== id);
    setMapUsertoFaclityArr(copyArray);
  };

  // Delete User Popup..
  const [showDelete, setDeleteUser] = useState(false);

  // View User Popup
  const [viewUser, setViewUserPopup] = useState(false);

  // Edit User Popup
  const [showEditUser, setEditUserPopup] = useState(false);
  const [activeTab, onclickActivePopup] = useState("basic");
  // const facilitiesData = [
  //   "",
  //   "Facility-1",
  //   "Facility-2",
  //   "Facility-3",
  //   "Facility-4",
  // ];
  // const facilityRoleData = [
  //   "",
  //   "Employee",
  //   "Manager",
  //   "City Manager",
  //   "Warehouse Manager",
  // ];

  // const facilityGroupsData = ["", "Chennai", "Banglore", "Delhi", "Kolkata"];
  // const facilityGroupsRoleData = [
  //   "",
  //   "Facility Manager",
  //   "Cluster Manager",
  //   "City Manager",
  //   "Warehouse Manager",
  // ];

  const [addressPopUpscreen, setAddressPopUpscreen] = useState(false);

  const onAddressPopUpClose = () => {
    setAddressPopUpscreen(false);
  };

  const [isresponse, setIsResponse] = useState(false);
  const changeResponse = () => {
    setIsResponse(!isresponse);
  };

  // const [switched, setSwitched] = useState(false);
  // const switchHandle = () => {
  //   setSwitched(!switched);
  // };
  const [callBack, setCallBack] = useState(true);

  const [cardViewUser, setCardViewUser] = useState(true);
  const setViewUserTrue = () => {
    if (cardViewUser) {
      setViewUserPopup(true);
    }
  };

  const [showEditAddress, setShowEditAddress] = useState(false);
  const addressPurpose = [
    "Shipping Destination Address",
    "Shipping Origin Address",
    "Billing (AP) Address",
    "Payment (AR) Address",
    "General Correspondence Address",
    "Purchase Return Address",
    "Primary Address",
    "Previous Address",
  ];

  const [showEmailPurpose, setShowEmailPurpose] = useState(false);
  const emailPurposeData = [
    "Shipping Origin Phone Number",
    "Main Fax Number",
    "Secondary Fax Number",
    "Direct Inward Dialing Phone Number",
    "Main Home Phone Number",
    "Main Mobile Phone Number",
    "Main Work Phone Number",
    "Secondary Work Phone Number",
    "Shipping Destination Phone Number",
  ];
  const [showPhonePurpose, setShowPhonePurpose] = useState(false);
  const phonePurposeData = [
    "Shipping Origin Phone Number",
    "Main Fax Number",
    "Secondary Fax Number",
    "Direct Inward Dialing Phone Number",
    "Main Home Phone Number",
    "Main Mobile Phone Number",
    "Main Work Phone Number",
    "Secondary Work Phone Number",
    "Shipping Destination Phone Number",
  ];

  const facilitiesData = [
    "",
    "Facility-1",
    "Facility-2",
    "Facility-3",
    "Facility-4",
  ];
  const facilityRoleData = [
    "",
    "Employee",
    "Manager",
    "City Manager",
    "Warehouse Manager",
  ];

  const facilityGroupsData = ["", "Chennai", "Banglore", "Delhi", "Kolkata"];
  const facilityGroupsRoleData = [
    "",
    "Facility Manager",
    "Cluster Manager",
    "City Manager",
    "Warehouse Manager",
  ];

  return (
    <>
      <div
        className={selected ? "userActiveCardCont" : "userCardCont"}
        onClick={setViewUserTrue}
      >
        <div className="userCardInputContainer">
          <input
            type="checkbox"
            className="userCardInput"
            checked={true}
            onClick={() => addToSelectedList(data.userName)}
            onMouseOver={() => setCardViewUser(false)}
            onMouseLeave={() => setCardViewUser(true)}
          />
          <div className="userStatusCont">
            {data.status === true ? (
              <p className="userEnableTxt">enabled</p>
            ) : (
              <div className="userDisabledCont">
                <img src={DisabledIcon} alt="icon" />
                <p className="userDisabledTxt">disabled</p>
                {/* <p className="loackHoverTxt">10 Failed login attempts</p> */}
              </div>
            )}

            {/* Condition Base */}

            <div className="profileHoverImgCont">
              <img src={OptionsIcon} alt="icon" className="profileHoverImg" />
              <ul
                className="userHoverCont"
                onMouseOver={() => setCardViewUser(false)}
                onMouseLeave={() => setCardViewUser(true)}
              >
                <li onClick={() => setAddToFacilityShow(true)}>
                  Add To Facility
                </li>
                <li onClick={() => setAddToFacilityGroup(true)}>
                  Add To Groups
                </li>
                <li onClick={() => setViewUserPopup(true)}>View User</li>
                <li onClick={() => setEditUserPopup(true)}>Edit User</li>
                <li onClick={() => setDeleteUser(true)}>Delete User</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="userCardProfileContainer">
          <div className="userProvileImgCont">
            <img className="userLogoImage" src={userIcon} alt="logo" />
          </div>
          <ul className="userProfileDetails">
            <li className="userName">{data.userName}</li>
            <li className="userEmail">{data.userEmail}</li>
            <li className="userNumber">+91-{data.userNo}</li>
          </ul>
        </div>
        <div className="userCardDetailsCont">
          <ul className="userRoleDetails">
            <li className="userRoleHeader">ROLE</li>
            <li className="userRole">{data.userRole}</li>
          </ul>
          <ul className="userReportDetailsCont">
            <li className="userReportHeader">Reports to</li>
            <li className="reporterName">
              {data.reportsTo} ({data.reporterEmail})
            </li>
            <li className="reporterRole">{data.reporterRole}</li>
          </ul>
        </div>
      </div>

      {/* Add to facility Popup */}

      <Modal
        show={addToFacilityShow}
        onHide={() => setAddToFacilityShow(false)}
        centered
        backdrop="static"
        className="userAddFacilityPopupModal"
      >
        <Modal.Header className="userAddFacilityPopHeaderCont">
          <h4 className="userAddFacilityPopHeader">Map user to facilities</h4>
          <div
            className="closeIconDiv"
            onClick={() => setAddToFacilityShow(false)}
          >
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="userAddFacilityPopBody">
          <div className="userAddFacilityPopBodyCont">
            <h4 className="userAddFacilityPopBodyHeaderss">
              Adding <b>Rajesh (rajesh@email.com)</b> to following facilities.
            </h4>
            <div className="userAddFacilityDetailsCont">
              <div className="userAddFacilityDetails">
                <p className="popFacilityTxt">Facility</p>
                <p className="popRoleTxt">Role</p>
              </div>
              <div className="userAddFacilitySelectorsHeightCont">
                {mapUsertoFaclityArr.map((obj) => (
                  <div className="userAddFacilitySelectorsCont">
                    <select className="popFacilitySelect">
                      <option value="" disabled selected>
                        Select Facility
                      </option>
                      {obj.facilityList.map((list) => (
                        <option key={list}>{list}</option>
                      ))}
                    </select>
                    <select className="popRoleSelect">
                      <option value="" disabled selected>
                        Select Role
                      </option>
                      {obj.rolesList.map((list) => (
                        <option key={list}>{list}</option>
                      ))}
                    </select>
                    <img
                      src={deleteIcon}
                      alt="delete-icon"
                      onClick={() => deleteAddFaciityRole(obj.id)}
                    />
                  </div>
                ))}
              </div>

              <div className="userAddFacilityDetailsBtnCont">
                <button
                  className="userAddFacilityDetailsBtn"
                  onClick={addFacilityRole}
                >
                  <img src={AddIcon} alt="icon" />
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="userAddFacilityPopFooterCont">
          <button
            onClick={() => setAddToFacilityShow(false)}
            className="userAddFacilityCancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => setAddToFacilityShow(false)}
            className="userAddFacilityMappingBtn"
          >
            {" "}
            Confirm Mapping
          </button>
        </Modal.Footer>
      </Modal>

      {/* Add to Groups Popup*/}

      <Modal
        show={addToFacilityGroup}
        onHide={() => setAddToFacilityGroup(false)}
        centered
        backdrop="static"
        className="userAddGroupsPopupModal"
      >
        <Modal.Header className="userAddGroupsPopHeaderCont">
          <h4 className="userAddGroupsPopHeader">
            Map user to facility groups
          </h4>
          <div
            className="closeIconDiv"
            onClick={() => setAddToFacilityGroup(false)}
          >
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="userAddGroupsPopBody">
          <div className="userAddGroupsPopBodyCont">
            <h4 className="userAddGroupsPopBodyHeader">
              Adding <b>Rajesh (rajesh@email.com)</b> to following facility
              groups.
            </h4>
            <div className="userAddGroupsDetailsCont">
              <div className="userAddGroupsDetails">
                <p className="popFacilityGroupsTxt">Facility Group</p>
                <p className="popGroupsRoleTxt">Role</p>
              </div>
              <div className="userAddFroupsSelectorsHeightCont">
                {mapUsertoFaclityGroupsArr.map((obj) => (
                  <div className="userAddGroupsSelectorsCont">
                    <select className="popGroupsSelect">
                      <option value="" disabled selected>
                        Select Group
                      </option>
                      {obj.facilityList.map((list) => (
                        <option key={list}>{list}</option>
                      ))}
                    </select>
                    <select className="popGroupsRoleSelect">
                      <option value="" disabled selected>
                        Select Role
                      </option>
                      {obj.rolesList.map((list) => (
                        <option key={list}>{list}</option>
                      ))}
                    </select>
                    <img
                      src={deleteIcon}
                      alt="delete-icon"
                      onClick={() => deleteAddFaciityGroupRole(obj.id)}
                    />
                  </div>
                ))}
              </div>

              <div className="userAddGroupsDetailsBtnCont">
                <button
                  className="userAddGroupsDetailsBtn"
                  onClick={addFacilityGroupRole}
                >
                  <img src={AddIcon} alt="icon" />
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="userAddGroupsPopFooterCont">
          <button
            onClick={() => setAddToFacilityGroup(false)}
            className="userAddGroupsCancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => setAddToFacilityGroup(false)}
            className="userAddGroupsMappingBtn"
          >
            {" "}
            Confirm Mapping
          </button>
        </Modal.Footer>
      </Modal>

      {/* Edit User Popup*/}

      <Modal
        show={showEditUser}
        onHide={() => setEditUserPopup(false)}
        centered
        backdrop="static"
        className="editUserPopupModal"
      >
        <Modal.Header className="editUserPopHeader">
          <h4 className="editUserHeader">Create new user</h4>
          <div className="closeIconDiv" onClick={() => setEditUserPopup(false)}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="editUserPopBody">
          <div className="editUserBody">
            <div className="editUserHeaderNav">
              <p
                className={
                  activeTab == "basic"
                    ? "activeeditUserNavTxt"
                    : "editUserNavTxt"
                }
                onClick={() => onclickActivePopup("basic")}
              >
                Basic Information
              </p>
              <p
                className={
                  activeTab == "facilities"
                    ? "activeeditUserNavTxt"
                    : "editUserNavTxt"
                }
                onClick={() => onclickActivePopup("facilities")}
              >
                Facilities
              </p>
              <p
                className={
                  activeTab == "groups"
                    ? "activeeditUserNavTxt"
                    : "editUserNavTxt"
                }
                onClick={() => onclickActivePopup("groups")}
              >
                Facility Groups
              </p>
            </div>
            {activeTab == "basic" ? (
              <div className="userBasicInfoCont">
                <div className="basicInfoCont">
                  <div className="basicInfoInputsCont">
                    <div className="textInputsCont">
                      <label>First Name *</label>
                      <input type="text" />
                    </div>

                    <div className="textInputsCont">
                      <label>Last Name *</label>
                      <input type="text" />
                    </div>

                    <div className="selectorInputCont">
                      <label>Role *</label>
                      <select placeholder="Select Role">
                        <option>Select Role</option>
                      </select>
                    </div>

                    <div className="textInputsCont">
                      <label>Address *</label>
                      <input type="text" placeholder="Add address" readOnly />
                      <img
                        className="editUserIcon"
                        src={EditAddressIcon}
                        alt="icon"
                        onClick={() => setShowEditAddress(true)}
                      />
                    </div>

                    <div className="phoneNumberInputCont">
                      <label>Phone Number *</label>
                      <div className="selectAndInputCont">
                        <div className="imgAndSelectCont">
                          <img src={CountryIcon} alt="icon" />
                          <select>
                            <option>+91</option>
                          </select>
                        </div>

                        <input type="text" />
                      </div>

                      <p
                        className="setPurpose"
                        onClick={() => setShowPhonePurpose(true)}
                      >
                        Set purpose
                      </p>
                      {showPhonePurpose && (
                        <div className="phoneNumberSetPurpose">
                          <div className="setPurposeHeader">
                            <div className="purposeCloseCont">
                              <img
                                src={IconClose}
                                alt="icon"
                                onClick={() => setShowPhonePurpose(false)}
                              />
                            </div>
                          </div>
                          <div className="setPurposeDataCont">
                            {phonePurposeData.map((el) => (
                              <div className="setPurposeData">
                                <input type="checkbox" />
                                <label>{el}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="textInputsCont">
                      <label>Email *</label>
                      <input type="validateEmail" />
                      <p
                        className="setPurpose"
                        onClick={() => setShowEmailPurpose(true)}
                      >
                        Set purpose
                      </p>
                      {showEmailPurpose && (
                        <div className="emailSetPurpose">
                          <div className="setPurposeHeader">
                            <div className="purposeCloseCont">
                              <img
                                src={IconClose}
                                alt="icon"
                                onClick={() => setShowEmailPurpose(false)}
                              />
                            </div>
                          </div>
                          <div className="setPurposeDataCont">
                            {emailPurposeData.map((el) => (
                              <div className="setPurposeData">
                                <input type="checkbox" />
                                <label>{el}</label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="credentialsInputsCont">
                    <p className="credentialsHeder">Credentials</p>
                    <div className="credentialsHederBorder"></div>
                    <div className="userNameInputCont">
                      <div className="textInputsCont">
                        <label>Username *</label>
                        <div className="passwordCont">
                          <input type="text" onClick={changeResponse} />
                          {isresponse ? (
                            <div className="confirmStatusCont">
                              <img src={""} alt="icon" />
                              <p>Check your email for instructions</p>
                            </div>
                          ) : (
                            <p>Reset Password</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="passwordsInputsCont">
                      <div className="textInputsCont">
                        <label>Password *</label>
                        <input type="text" />
                      </div>

                      <div className="textInputsCont">
                        <label>Confirm Password *</label>
                        <input type="text" />
                      </div>
                    </div>
                  </div>

                  <div className="profileImgFormatCont">
                    <div className="profileImgCont">
                      <img src={UploadImgIcon} alt="icon" />
                    </div>
                    <ul className="profileImgDetails">
                      <li className="profileImgHeader">Profile Image</li>
                      <li>Max file size : 2MB. File format : png, jpeg</li>
                      <li>Recommended resolution 300x300</li>
                    </ul>
                    <img
                      src={RemoveImgIcon}
                      alt="icon"
                      className="removeImgIcon"
                    />
                  </div>
                </div>
              </div>
            ) : activeTab == "facilities" ? (
              <div className="facilitiesContainer">
                <div className="facilitiesAddBtnCont">
                  <button className="facilitiesAddBtn">
                    <img src={AddIcon} alt="icon" />
                    Add
                  </button>
                </div>
                <div className="facilityAndRoleContainer">
                  <div className="facilityAndRoleHeaders">
                    <p>Facility</p>
                    <p>Role</p>
                  </div>
                  <div className="facilityAndRoleSelectorsCont">
                    <select className="facilityAndRoleSelector">
                      {facilitiesData.map((el) => (
                        <option>{el}</option>
                      ))}
                    </select>
                    <select className="facilityAndRoleSelector">
                      {facilityRoleData.map((el) => (
                        <option>{el}</option>
                      ))}
                    </select>
                    <img src={deleteIcon} alt="icon" />
                  </div>
                </div>
              </div>
            ) : activeTab == "groups" ? (
              <div className="facilityGroupsContainer">
                <div className="facilityGroupsAddBtnCont">
                  <button className="facilityGroupsAddBtn">
                    <img src={AddIcon} alt="icon" />
                    Add
                  </button>
                </div>
                <div className="facilityGroupsAndRoleContainer">
                  <div className="facilityGroupsAndRoleHeaders">
                    <p>Facility Group</p>
                    <p>Role</p>
                  </div>
                  <div className="facilityGroupsAndRoleSelectorsCont">
                    <select className="facilityGroupsAndRoleSelector">
                      {facilityGroupsData.map((el) => (
                        <option>{el}</option>
                      ))}
                    </select>
                    <select className="facilityGroupsAndRoleSelector">
                      {facilityGroupsRoleData.map((el) => (
                        <option>{el}</option>
                      ))}
                    </select>
                    <img src={deleteIcon} alt="icon" />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="editUserPopupFooter">
          <div className="editUserFooterCont">
            <div className="editSwitchCont">
              <CustomSwitch callBack={setCallBack} check={callBack} />
              <p>{callBack ? "Disabled" : "Enabled"}</p>
            </div>
            <div className="editUserBtnsCont">
              <button
                className="editUserCancelBtn"
                onClick={() => setEditUserPopup(false)}
              >
                Cancel
              </button>
              {activeTab !== "groups" ? (
                <button
                  className="editUserNextBtn"
                  onClick={() =>
                    onclickActivePopup(
                      activeTab == "basic"
                        ? "facilities"
                        : activeTab === "facilities"
                        ? "groups"
                        : ""
                    )
                  }
                >
                  Next
                </button>
              ) : (
                <button
                  className="editUserUpdateBtn"
                  onClick={() => setEditUserPopup(false)}
                >
                  Update User
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Delete Popup */}

      <Modal
        show={showDelete}
        onHide={() => setDeleteUser(false)}
        centered
        className="deleteUserPopupModal"
        backdrop="static"
      >
        <Modal.Header className="deleteUserPopHeaderCont">
          <h4 className="deleteUserPopHeader">Delete User?</h4>
          <div className="closeIconDiv" onClick={() => setDeleteUser(false)}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="deleteUserPopBoday">
          <div className="deleteUserBodyCont">
            <p className="deleteUserContentTxt">
              This action will permanently delete all associations of{" "}
              <b>Rajesh Tiwari (rajesh@company)</b> with the company. Do you
              want to proceed?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="deleteUserFooter">
          <button
            className="deleteUserCancelBtn"
            onClick={() => setDeleteUser(false)}
          >
            Cancel
          </button>
          <button
            className="deleteUserDeleteBtn"
            onClick={() => setDeleteUser(false)}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      {/* View User Popup */}

      <Modal
        className="viewUsersPopupModal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={viewUser}
        onHide={() => setViewUserPopup(false)}
        centered
        backdrop="static"
      >
        <Modal.Header className="viewUserHeaderPopCont">
          <div className="viewUserHeaderContainer">
            <div className="viewUserProfileCont">
              <div className="profileImgCont">
                <img src={userIcon} alt="icon" />
              </div>
              <ul className="profileDetails">
                <li className="userNameTxt">{data.userName}</li>
                <li className="userMailTxt">{data.userEmail}</li>
                <li className="userNumberTxt">{data.userNo}</li>
              </ul>
            </div>
            <ul className="userRoleContainer">
              <li className="userRoleHeader">Role</li>
              <li className="userRoleName">{data.userRole}</li>
            </ul>
            <ul className="userReportContainer">
              <li className="reportsHeader">REPORTS TO</li>
              <li className="reporterName">
                {data.reportsTo} ({data.reporterEmail})
              </li>
              <li className="reporterNumber">+91 {data.reporterNo}</li>
              <li className="reporterRol">{data.reporterRole}</li>
            </ul>
          </div>
          <div className="closeIconDiv" onClick={() => setViewUserPopup(false)}>
            <img src={IconClose} alt="icon" className="viewUserPopCloseIcon" />
          </div>
        </Modal.Header>
        <Modal.Body className="viewUsersPopBoday">
          <div className="viewUserBodyContainer">
            <div className="viewUserAddressCont">
              <p className="addressHeader">Address</p>
              <p className="addressInfoTxt">
                Ground Floor,Door No:2,Thiruvalluvar Salai, Kumaran Nagar,
                Solinganallur, Chennai-600119
              </p>
            </div>
            <div className="viewUserDetailsCont">
              <div>
                <ul className="facilityAndRoleDetails">
                  <li className="facilityAndRoleHeader">Facilities</li>
                  <li>Chennai Zone-2</li>
                  <li>Chennai Zone-4</li>
                </ul>
                <ul className="facilityAndRoleGroupsCont">
                  <li className="facilityAndRoleGroupsHeader">
                    Facility Groups
                  </li>
                  <li>Chennai</li>
                </ul>
              </div>
              <div>
                <ul className="facilityAndRoleDetails">
                  <li className="facilityAndRoleHeader">Role</li>
                  <li>Cluster Manager</li>
                  <li>Cluster Manager</li>
                </ul>
                <ul className="facilityAndRoleGroupsCont">
                  <li className="facilityAndRoleGroupsHeader">Role</li>
                  <li>Chennai</li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="viewUsersPopFooter">
          <div className="viewUserFooterContainer">
            {data.status === true ? (
              <p className="enabledStatusTxt">enabled</p>
            ) : (
              <div className="viewUserStatusCont">
                <p className="disableStatusTxt">disabled</p>
                <div className="statusAttemptCont">
                  <img src={DisabledIcon} alt="icon" />
                  <p className="attemptTxt">10 Failed login attempts</p>
                </div>
              </div>
            )}

            <div className="viewUserBtnsCont">
              <button
                className="editBtn"
                onClick={() => setEditUserPopup(true)}
              >
                <img src={IconEdit} alt="icon" />
                Edit
              </button>
              <button className="deleteBtn" onClick={() => setDeleteUser(true)}>
                <img src={IconDelete} alt="icon" />
                Delete
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Edit Address Popup */}
      <Modal
        show={showEditAddress}
        backdrop="static"
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="editAddressPopup"
      >
        <Modal.Header className="editAddressHeaderCont">
          <h4 className="editAddressTxt">Edit Address</h4>
          <div
            className="closeIconDiv"
            onClick={() => setShowEditAddress(false)}
          >
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="editAddressBody">
          <div className="editAddressBodyCont">
            <div className="editAddressInputsCont">
              <div className="addressTextInputsCont">
                <label>To Name *</label>
                <input type="text" />
              </div>

              <div className="addressTextInputsCont">
                <label>Attn Name *</label>
                <input type="text" />
              </div>

              <div className="addressTextInputsCont">
                <label>Address Line 1 *</label>
                <input type="text" />
              </div>

              <div className="addressTextInputsCont">
                <label>Address Line 2 *</label>
                <input type="text" />
              </div>

              <div className="addressTextInputsCont">
                <label>City *</label>
                <input type="text" />
              </div>

              <div className="addressSelectorCont">
                <label>State/Province *</label>
                <select>
                  <option>TN</option>
                </select>
              </div>

              <div className="addressTextInputsCont">
                <label>Postal Code *</label>
                <input type="text" />
              </div>

              <div className="addressSelectorCont">
                <label>Country</label>
                <select>
                  <option>India</option>
                </select>
              </div>
            </div>

            <div className="editAddressPurposeCont">
              {addressPurpose.map((el) => (
                <div className="addressPurposeCont">
                  <input type="checkbox" className="purposeCheckbox" />
                  <label>{el}</label>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="editAddressFooter">
          <button
            className="cancelBtn"
            onClick={() => setShowEditAddress(false)}
          >
            Cancel
          </button>
          <button
            className="updateAddressBtn"
            onClick={() => setShowEditAddress(false)}
          >
            Update Address
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserCard;
