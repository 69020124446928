const DEFAULT_VALUE = null;
const USER_SPECIFIC_ASSET_TYPE_DATA = "user_specific_asset_type_data";
const USER_SPECIFIC_ASSET_DATA = "user_specific_asset_data";
const USERNAME = "userName";
const PASSWORD = "password";
const REGION = "regionIds";
const CITY = "cityIds";
const CLUSTER = "clusterIds";
const FACILITY = "facilityIds";
const ASSET_ID = "assetId";
const SELECTED_ASSET = "selectedAsset";
const SINGLE_ASSET_CARD_DETAILS = "singleAssetCardDetails";
const TOKEN = "@tokenID";
const AREAS = "areaIds";
const FACILITY_STATICS_CACHE = "facility_statistics_cache";

class LocalStorageService {
  /*<==USER SPECIFIC ASSET TYPE DATA BEGIN==>*/
  static getUserSpecificAssetTypeData() {
    try {
      const storedValue = localStorage.getItem(USER_SPECIFIC_ASSET_TYPE_DATA);
      return storedValue ? JSON.parse(storedValue) : DEFAULT_VALUE;
    } catch (error) {
      console.error(
        "Local Storage Error :: Getting user-specific-asset-type-data : ",
        error
      );
      return DEFAULT_VALUE;
    }
  }

  static setUserSpecificAssetTypeData(value) {
    try {
      localStorage.setItem(
        USER_SPECIFIC_ASSET_TYPE_DATA,
        JSON.stringify(value)
      );
    } catch (error) {
      console.error(
        "Local Storage Error :: Setting user-specific-asset-type-data : ",
        error
      );
    }
  }
  /*<==USER SPECIFIC ASSET TYPE DATA END==>*/

  /*<==USER SPECIFIC ASSET DATA BEGIN==>*/
  static getUserSpecificAssetData() {
    try {
      const storedValue = localStorage.getItem(USER_SPECIFIC_ASSET_DATA);
      return storedValue ? JSON.parse(storedValue) : DEFAULT_VALUE;
    } catch (error) {
      console.error(
        "Local Storage Error :: Getting user-specific-asset-type-data : ",
        error
      );
      return DEFAULT_VALUE;
    }
  }

  static setUserSpecificAssetData(value) {
    try {
      localStorage.setItem(USER_SPECIFIC_ASSET_DATA, JSON.stringify(value));
    } catch (error) {
      console.error(
        "Local Storage Error :: Setting user-specific-asset-type-data : ",
        error
      );
    }
  }
  /*<==USER SPECIFIC ASSET DATA END==>*/

  /*<==USERNAME START==>*/
  static setUserName(value) {
    try {
      localStorage.setItem(USERNAME, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${USERNAME}:`, error);
    }
  }

  static getUsername() {
    try {
      let localData = localStorage.getItem(USERNAME);
      return localData != null ? localData : DEFAULT_VALUE;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${USERNAME}:`, error);
      return DEFAULT_VALUE;
    }
  }
  /*<==USERNAME END==>*/

  /*<==PASSWORD START==>*/
  static setPassword(value) {
    try {
      localStorage.setItem(PASSWORD, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${PASSWORD}:`, error);
    }
  }

  static getPassword() {
    try {
      let localData = localStorage.getItem(PASSWORD);
      return localData != null ? localData : DEFAULT_VALUE;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${PASSWORD}:`, error);
      return DEFAULT_VALUE;
    }
  }
  /*<==PASSWORD END==>*/

  /*<==TOKEN START==>*/
  static setToken(value) {
    try {
      localStorage.setItem(TOKEN, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${TOKEN}:`, error);
    }
  }

  static getToken() {
    try {
      let localData = localStorage.getItem(TOKEN);
      return localData != null ? localData : DEFAULT_VALUE;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${TOKEN}:`, error);
      return DEFAULT_VALUE;
    }
  }
  /*<==TOKEN END==>*/

  /*<==REGION DATA START==>*/
  static setRegionData(value) {
    try {
      localStorage.setItem(REGION, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${REGION}:`, error);
    }
  }

  static getRegionData() {
    try {
      let localData = localStorage.getItem(REGION);
      if (localData) return JSON.parse(localData);
      else return localData;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${REGION}:`, error);
      return null;
    }
  }
  /*<==REGION DATA END==>*/

  /*<==CITY DATA START==>*/
  static setCityData(value) {
    try {
      localStorage.setItem(CITY, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${CITY}:`, error);
    }
  }

  static getCityData() {
    try {
      let localData = localStorage.getItem(CITY);
      if (localData) return JSON.parse(localData);
      else return localData;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${CITY}:`, error);
      return null;
    }
  }
  /*<==CITY DATA END==>*/

  /*<==CLUSTER DATA START==>*/
  static setClusterData(value) {
    try {
      localStorage.setItem(CLUSTER, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${CLUSTER}:`, error);
    }
  }

  static getClusterData() {
    try {
      let localData = localStorage.getItem(CLUSTER);
      if (localData) return JSON.parse(localData);
      else return localData;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${CLUSTER}:`, error);
      return null;
    }
  }
  /*<==CLUSTER DATA END==>*/

  /*<==FACILITY DATA START==>*/
  static setFacilityData(value) {
    try {
      localStorage.setItem(FACILITY, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${FACILITY}:`, error);
    }
  }

  static getFacilityData() {
    try {
      let localData = localStorage.getItem(FACILITY);
      if (localData) return JSON.parse(localData);
      else return localData;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${FACILITY}:`, error);
      return null;
    }
  }
  /*<==FACILITY DATA END==>*/

  /*<==Asset Id DATA START==>*/
  static setAssetIdData(value) {
    try {
      localStorage.setItem(ASSET_ID, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${ASSET_ID}:`, error);
    }
  }

  static getAssetIdData() {
    try {
      let localData = localStorage.getItem(ASSET_ID);
      if (localData) return localData;
      else return localData;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${ASSET_ID}:`, error);
      return null;
    }
  }
  /*<==Asset Id DATA END==>*/

  /*<==Selected Asset DATA START==>*/
  static setSelectedAssetData(value) {
    try {
      localStorage.setItem(SELECTED_ASSET, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${SELECTED_ASSET}:`, error);
    }
  }

  static getSelectedAssetData() {
    try {
      let localData = localStorage.getItem(SELECTED_ASSET);
      if (localData) return JSON.parse(localData);
      else return localData;
    } catch (error) {
      console.error(
        `Local Storage Error :: Getting  ${SELECTED_ASSET}:`,
        error
      );
      return null;
    }
  }
  /*<==Selected Asset DATA END==>*/

  /*<==Selected Asset DATA START==>*/
  static setSingleAssetCardDetailsData(value) {
    try {
      localStorage.setItem(SINGLE_ASSET_CARD_DETAILS, value);
    } catch (error) {
      console.error(
        `Local Storage Error :: Setting ${SINGLE_ASSET_CARD_DETAILS}:`,
        error
      );
    }
  }

  static getSingleAssetCardDetailsData() {
    try {
      let localData = localStorage.getItem(SINGLE_ASSET_CARD_DETAILS);
      if (localData) return JSON.parse(localData);
      else return localData;
    } catch (error) {
      console.error(
        `Local Storage Error :: Getting  ${SINGLE_ASSET_CARD_DETAILS}:`,
        error
      );
      return null;
    }
  }
  /*<==Selected Asset DATA END==>*/

  /*<==TOKEN START==>*/
  static setTokenData(value) {
    try {
      localStorage.setItem(TOKEN, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${TOKEN}:`, error);
    }
  }

  static getTokenData() {
    try {
      let localData = localStorage.getItem(TOKEN);
      return localData;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${TOKEN}:`, error);
      return null;
    }
  }
  /*<==TOKEN END==>*/

  /*<==AREA START==>*/
  static setAreaData(value) {
    try {
      localStorage.setItem(AREAS, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${AREAS}:`, error);
    }
  }

  static getAreaData() {
    try {
      let localData = localStorage.getItem(AREAS);
      if (localData) return JSON.parse(localData);
      else return localData;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${AREAS}:`, error);
      return null;
    }
  }
  /*<==AREA END==>*/

   /*<==FACILITY_STATICS_CACHE START==>*/
   static setFacilityStaticsData(value) {
    try {
      localStorage.setItem(FACILITY_STATICS_CACHE, value);
    } catch (error) {
      console.error(`Local Storage Error :: Setting ${FACILITY_STATICS_CACHE}:`, error);
    }
  }

  static getFacilityStaticsData() {
    try {
      let localData = localStorage.getItem(FACILITY_STATICS_CACHE);
      if (localData) return JSON.parse(localData);
      else return localData;
    } catch (error) {
      console.error(`Local Storage Error :: Getting  ${FACILITY_STATICS_CACHE}:`, error);
      return null;
    }
  }
  /*<==FACILITY_STATICS_CACHE END==>*/
}

export default LocalStorageService;
