import React, { useEffect, useState } from "react";
import waterIc1 from "../../../assets/image/waterIc1.svg";
import waterBgi1 from "../../../assets/image/waterBgi1.svg";
import * as R from "ramda";
import "./ADWaterConsumption.scss";

function ADWaterConsumption({ data }) {
  const [waterFlowAgr, setWaterFlowAgr] = useState();
  
  useEffect(() => {
    {
      !R.isEmpty(data?.meters) &&
        !R.isNil(data?.meters) &&
        data?.meters.find(
          (el, index) =>
            el.meterTypeId.includes("WTR_FLOW_TOT") && setWaterFlowAgr(el)
        );
    }
  }, [data]);
  return (
    <div className="adWaterConsuptionCont">
      <div className="cardDetailsCont">
        <div className="cardDetails">
          <p className="cardHeader">Meter Reading</p>
          <div className="imgContainer">
            <div className="imgCountCont">
              <img src={waterIc1} alt="icon" className="icons" />
              <p className="counts">
              <span className="liquidCrystalFont">
                {!R.isEmpty(waterFlowAgr?.meterValue) &&
                !R.isNil(waterFlowAgr?.meterValue)
                  ? Number(waterFlowAgr?.meterValue)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "--"}{" "}
                  </span>
                {waterFlowAgr?.readingUomDesc}
              </p>
              {/* <p className="counts">1341.41 kWh</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="cardImages">
        <img src={waterBgi1} alt="Img" className="image1" />
      </div>
    </div>
  );
}

export default ADWaterConsumption;
