import { useEffect } from "react";
import "./EventList.scss";
import { useQuery, useQueryClient } from "react-query";
import { getEventList } from "../service/EventListService";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import EventListCard from "./EventListCard";
import iconChevronLeft from "../../../assets/icons/iconchevronleft.svg";
import iconChevronRight from "../../../assets/icons/iconchevronright.svg";
import DownloadIcon from "../assets/ic_download.svg";
import LinkWithIcon from "../../../a1Components/FormElements/linkWithIcon";
import DownloadEvent from "./DownloadEvent";
import { useStateValue } from "../../../redux/StateProvider";
import { EventLog } from "../types/EventListTypes";
import moment from "moment";
import EventListState from "../state/EventListState";
import { useNavigate } from 'react-router-dom';
import { cancelExistingQuery } from "../../../Utils/utils";

export function EventList() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [{ dateRange }] = useStateValue();
  const pageViewSize = 10;
  const {
    hoveredIndex,
    setHoveredIndex,
    showDownloadEventPopUp,
    setShowDownloadEventPopUp,
    pageIndex,
    setPageIndex,
    responseData,
    setResponseData,
  } = EventListState();

  const { data, isLoading, isFetching, error, refetch, dataUpdatedAt } =
    useQuery(
      "getEventList",
      () =>
        getEventList(
          pageIndex,
          pageViewSize,
          moment(dateRange[0]).valueOf(),
          moment(dateRange[1]).valueOf(),
          [],
        ),
      {
        enabled: false,
        keepPreviousData: false,
      }
    );

  const refetchEventList = async () => {
    try {
      setResponseData({
        paginationMap: {
          totalPage: 0,
          nextPage: 0,
          pageSize: 0,
          prevPage: 0,
          totalCount: 0,
          currentPage: 0,
        },
        eventLog: [],
      })
      await refetch();
    } catch (error) {
      console.error("Error in refetching api:", error);
    }
  };

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  useEffect(() => {
    if (data !== undefined && typeof data !== "number") {
      setResponseData(data);
    }
  }, [data, dataUpdatedAt]);

  const checkPrevButtonEnabled = () => {
    if (responseData.paginationMap.currentPage === 1) {
      return false;
    }
    return (
      responseData.paginationMap.prevPage !==
      responseData.paginationMap.currentPage
    );
  };

  const checkNextButtonEnabled = () => {
    return (
      responseData.paginationMap.nextPage !==
      responseData.paginationMap.currentPage
    );
  };

  useEffect(() => {
    cancelExistingQuery("getEventList", queryClient);
    return () => {
      cancelExistingQuery("getEventList", queryClient);
    };
  }, []);

  useEffect(() => {
    setPageIndex(1);
  }, [dateRange]);

  useEffect(() => {
    refetchEventList();
  }, [dateRange, pageViewSize, pageIndex]);

  if (isLoading || isFetching) {
    return (
      <div className="loadingBox1">
        <LoaderStyle />
      </div>
    );
  }

  if (error) {
    return (
      <div className="loadingBox1">
        <DisplayError type="err_1" />
      </div>
    );
  }

  if (data !== undefined && typeof data === "number") {
    return (
      <div className="loadingBox1">
        <DisplayError type="err-500/404" />
      </div>
    );
  }

  return (
    <>
      {showDownloadEventPopUp && (
        <DownloadEvent
          show={showDownloadEventPopUp}
          onHide={() => setShowDownloadEventPopUp(false)}
        />
      )}
      <div className="eventListMainContainer">
        {responseData.eventLog.length === 0 ? (
          <DisplayError type="err-empty" />
        ) : (
          <div className="eventListMainCont">
            {responseData.eventLog.map((item: EventLog, index: number) => (
              <EventListCard
                key={`${item.eventId}_${item.eventDescription}`}
                data={item}
                index={index}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                hoveredIndex={hoveredIndex}
              />
            ))}
          </div>
        )}
        {responseData.eventLog.length !== 0 && (
          <div className="paginationMainDiv">
            <img
              src={iconChevronLeft}
              style={{
                opacity: checkPrevButtonEnabled() ? 1 : 0.3,
                cursor: checkPrevButtonEnabled() ? "pointer" : "default",
              }}
              onClick={() => {
                if (checkPrevButtonEnabled()) {
                  setPageIndex((prev) => prev - 1);
                }
              }}
            />
            <p className="paginationText">
              Showing {(pageIndex - 1) * pageViewSize + 1}-
              {(pageIndex - 1) * pageViewSize + responseData.eventLog.length} of{" "}
              {responseData.paginationMap.totalCount} Events
            </p>
            <img
              src={iconChevronRight}
              style={{
                opacity: checkNextButtonEnabled() ? 1 : 0.3,
                cursor: checkNextButtonEnabled() ? "pointer" : "default",
              }}
              onClick={() => {
                if (checkNextButtonEnabled()) {
                  setPageIndex((prev) => prev + 1);
                }
              }}
            />
          </div>
        )}
        <div
          className="downloadEventLog"
          onClick={() => setShowDownloadEventPopUp(true)}
        >
          <img src={DownloadIcon} />
          <p className="downloadButtonText">Download Event log</p>
        </div>

        <div style={{border:1,display:'flex',justifyContent:'flex-end'}}>
        <LinkWithIcon
          onClick={() => navigate("/event-list")}
          cardname="Event List"
          label="View all events"
          link="/event-list"
          />
        </div>
      </div>
    </>
  );
}
