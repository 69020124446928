/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React, { useState, useEffect} from "react";

import DisableIcon from "../../../assets/icons/DisableIcon.svg";
import IconDelete from "../../../assets/icons/IconDelete.svg";

import "./UsersCardContainer.scss";
import UserCard from "./userCard/UserCard";
import { SettingsApiServices } from "../../../services/settingsPageServices/SettingsApiServices";

function UsersCardContainer() {

  //It will be use for userListApi

  // const fetchUserList = async () =>{
  //   let token = localStorage.getItem("@tokenID");
  //   let services = new SettingsApiServices ();
  //   let resp  = await services.usersList(token);
  //   setUsersList(resp)
  // }
  // useEffect(() => {
  //   fetchUserList();
  // }, [])

  
  const facilityCardList = [
    // "name1",
    // "name2",
    // "name3",
    // "name4",
    {
      status: false,
      userName: "Rajesh Tiwari",
      userEmail: "rajesh@email.com",
      userNo: "1234567890",
      userRole: "Cluster Manager",
      reportsTo: "Swami",
      reporterEmail: "swami@email.com",
      reporterNo: "2464842642",
      reporterRole: "Manager",
    },
    {
      status: true,
      userName: "Bharath Kumar",
      userEmail: "bharath@email.com",
      userNo: "0987654321",
      userRole: "Cluster Manager",
      reportsTo: "Swami",
      reporterEmail: "swami@email.com",
      reporterNo: "2464842642",
      reporterRole: "Manager",
    },
    {
      status: true,
      userName: "Murali Krishna",
      userEmail: "mural@email.com",
      userNo: "1234554321",
      userRole: "Cluster Manager",
      reportsTo: "Swami",
      reporterEmail: "swami@email.com",
      reporterNo: "2464842642",
      reporterRole: "Manager",
    },
    {
      status: false,
      userName: "Edukondalu Donga",
      userEmail: "edukondalu@email.com",
      userNo: "1212121212",
      userRole: "Cluster Manager",
      reportsTo: "Swami",
      reporterEmail: "swami@email.com",
      reporterNo: "2464842642",
      reporterRole: "Manager",
    },
  ];
  const [selectedLsit, setSelectedList] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const addToSelectedList = (facilityName: any) => {
    if (!selectedLsit.includes(facilityName)) {
      let val = selectedLsit;
      val.push(facilityName);
      setSelectedList(val);
      setSelectedCount(selectedCount + 1);
    } else {
      let removedArr = selectedLsit.filter(
        (element) => element !== facilityName
      );
      setSelectedList(removedArr);
      setSelectedCount(selectedCount - 1);
    }
  };
  const selectAll = () => {
    let count = facilityCardList.length;
    setSelectedCount(count);
    setSelectedList(facilityCardList.map((obj)=> obj.userName));
  };
  const deselectAll = () => {
    setSelectedCount(0);
    setSelectedList([]);
  };
  return (
    <div className="usersCardContainer">
      {selectedCount > 0 ? (
        <div className="usersCardsNav">
          <p>{selectedCount} selected</p>
          <div className="usersNavBtnsCont">
            <button className="usersSelectAllBtn" onClick={selectAll}>
              Select all
            </button>
            <button className="usersDeselectAllBtn" onClick={deselectAll}>
              Deselect all
            </button>
            <button className="usersDisableBtn">
              <img src={DisableIcon} alt="icon" />
              Disable
            </button>
            <button className="usersDeleteBtn">
              <img src={IconDelete} alt="icon" />
              Delete
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="usersCardsCont">
        {facilityCardList.map((el) => (
          <UserCard
            selected={selectedLsit.includes(el.userName)}
            key={el.userName}
            data={el}
            addToSelectedList={addToSelectedList}
          />
        ))}
      </div>
    </div>
  );
}

export default UsersCardContainer;
