/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";

import TempImg from "../../../assets/icons/TempImg.svg";
import airIcon from "../../../assets/image/airIcon.svg";

import co2 from "../../../assets/icons/co2.svg";
import pm_10 from "../../../assets/icons/pm-10.svg";
import pm25 from "../../../assets/icons/pm25.svg";
import PM10 from "../../../assets/icons/PM10.svg";
import TVOC from "../../../assets/icons/TVOC.svg";
import formaldehyde from "../../../assets/icons/formaldehyde.svg";
import Humidity from "../../../assets/icons/Humidity.svg";

import "./ADAirQuality.scss";
import * as R from "ramda";
function ADAirQuality({ data, closeOpenBtn }: any) {
  return (
    <>
      <div className="airQualityLiveDataCard">
        <div className="cardsRowCont">
          <div className="rowOne">

            {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
              data?.meters.map(
                (el: any, index: any) =>
                  el.meterTypeId.includes("TEMP") && (
                    <div className="airQualityContainer">
                      <p className="cardHeading">Temperature</p>
                      <div className="cardValuesCont">
                        <img src={TempImg} alt="icon" className="cardIcon" />
                        <p style={{color: el.compliance === false ? "red" : "#000000"}} className="card1Values">
                          {Number(el?.meterValue).toFixed(2)}°C
                        </p>
                      </div>
                      <p className="cardCelcius">
                        Min {data?.temp_min?.value}°
                        {data?.temp_min?.abbreviation} - Max{" "}
                        {data?.temp_max?.value}°{data?.temp_max?.abbreviation}
                      </p>
                    </div>
                  )
              )
            ) : (
              <div className="airQualityContainer">
                <p className="cardHeading">Temperature</p>
                <div className="cardValuesCont">
                  <img src={TempImg} alt="icon" className="cardIcon" />
                  <p className="cardValues">-</p>
                </div>
                <p className="cardCelcius">Min - °C - Max - °C</p>
              </div>
            )}
            {/* //co2 */}
            {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
              data?.meters.map(
                (el: any, index: any) =>
                  el.meterTypeId.includes("CO2") && (
                    <div className="airQualityContainer">
                      <p className="cardHeading">
                        CO <sub>2</sub>
                      </p>
                      <div className="cardValuesCont">
                        <img src={co2} alt="icon" className="cardIcon" />
                        <p
                          style={{
                            color: el.compliance === false ? "red" : "#000000",
                          }}
                          className="card1Values"
                        >
                          {Number(el?.meterValue).toFixed(2)}{" "}
                          {el?.readingUomDesc}
                        </p>
                      </div>
                    </div>
                  )
              )
            ) : (
              <div className="airContainer">
                <p className="cardHeading">
                  CO <sub>2</sub>
                </p>
                <div className="cardValuesCont">
                  <img src={co2} alt="icon" className="cardIcon" />
                  <p className="cardValues">-</p>
                </div>
              </div>
            )}
            {/* pm 1.0 */}
            {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
              data?.meters.map(
                (el: any, index: any) =>
                  el.meterTypeId.includes("PM1_0") && (
                    <div className="airQualityContainer">
                      <p className="cardHeading">
                        PM <sub>1.0</sub>
                      </p>
                      <div className="cardValuesCont">
                        <img src={pm_10} alt="icon" className="cardIcon" />
                        <p
                          style={{
                            color: el.compliance === false ? "red" : "#000000",
                          }}
                          className="card1Values"
                        >
                          {Number(el?.meterValue).toFixed(2)}{" "}
                          {el?.readingUomDesc}
                        </p>
                      </div>
                    </div>
                  )
              )
            ) : (
              <div className="airContainer">
                <p className="cardHeading">
                  PM <sub>1.0</sub>
                </p>
                <div className="cardValuesCont">
                  <img src={pm_10} alt="icon" className="cardIcon" />
                  <p className="cardValues">-</p>
                </div>
              </div>
            )}

            {/* PM 2.5 */}
            {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
              data?.meters.map(
                (el: any, index: any) =>
                  el.meterTypeId.includes("PM2_5") && (
                    <div className="airQualityContainer">
                      <p className="cardHeading">
                        PM <sub>2.5</sub>
                      </p>
                      <div className="cardValuesCont">
                        <img src={pm25} alt="icon" className="cardIcon" />
                        <p
                          style={{
                            color: el.compliance === false ? "red" : "#000000",
                          }}
                          className="card1Values"
                        >
                          {Number(el?.meterValue).toFixed(2)}{" "}
                          {el?.readingUomDesc}
                        </p>
                      </div>
                    </div>
                  )
              )
            ) : (
              <div className="airContainer">
                <p className="cardHeading">
                  PM <sub>2.5</sub>
                </p>
                <div className="cardValuesCont">
                  <img src={pm25} alt="icon" className="cardIcon" />
                  <p className="cardValues">-</p>
                </div>
              </div>
            )}
          </div>
          {closeOpenBtn && (
            <div className="rowTwo">
              {/* pm10 */}
              {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
                data?.meters.map(
                  (el: any, index: any) =>
                    el.meterTypeId.includes("PM10") && (
                      <div className="airQualityContainer">
                        <p className="cardHeading">
                          PM <sub>10</sub>
                        </p>
                        <div className="cardValuesCont">
                          <img src={PM10} alt="icon" className="cardIcon" />
                          <p
                            style={{
                              color:
                                el.compliance === false ? "red" : "#000000",
                            }}
                            className="card1Values"
                          >
                            {Number(el?.meterValue).toFixed(2)}{" "}
                            {el?.readingUomDesc}
                          </p>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="airContainer">
                  <p className="cardHeading">
                    PM <sub>10</sub>
                  </p>
                  <div className="cardValuesCont">
                    <img src={PM10} alt="icon" className="cardIcon" />
                    <p className="cardValues">-</p>
                  </div>
                </div>
              )}

              {/* TVOC */}
              {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
                data?.meters.map(
                  (el: any, index: any) =>
                    el.meterTypeId.includes("TVOC") && (
                      <div className="airQualityContainer">
                        <p className="cardHeading">
                          Total volatile organic compound
                        </p>
                        <div className="cardValuesCont">
                          <img src={TVOC} alt="icon" className="cardIcon" />
                          <p
                            style={{
                              color:
                                el.compliance === false ? "red" : "#000000",
                            }}
                            className="card1Values"
                          >
                            {Number(el?.meterValue).toFixed(2)}{" "}
                            {el?.readingUomDesc}
                          </p>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="airContainer">
                  <p className="cardHeading">Total volatile organic compound</p>
                  <div className="cardValuesCont">
                    <img src={TVOC} alt="icon" className="cardIcon" />
                    <p className="cardValues">-</p>
                  </div>
                </div>
              )}

              {/* HCHO */}
              {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
                data?.meters.map(
                  (el: any, index: any) =>
                    el.meterTypeId.includes("HCHO") && (
                      <div className="airQualityContainer">
                        <p className="cardHeading">Formaldehyde</p>
                        <div className="cardValuesCont">
                          <img
                            src={formaldehyde}
                            alt="icon"
                            className="cardIcon"
                          />
                          <p
                            style={{
                              color:
                                el.compliance === false ? "red" : "#000000",
                            }}
                            className="card1Values"
                          >
                            {Number(el?.meterValue).toFixed(2)}{" "}
                            {el?.readingUomDesc}
                          </p>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="airContainer">
                  <p className="cardHeading">Formaldehyde</p>
                  <div className="cardValuesCont">
                    <img src={formaldehyde} alt="icon" className="cardIcon" />
                    <p className="cardValues">-</p>
                  </div>
                </div>
              )}

              {/* HUM */}
              {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
                data?.meters.map(
                  (el: any, index: any) =>
                    el.meterTypeId.includes("HUM") && (
                      <div className="airQualityContainer">
                        <p className="cardHeading">Humidity</p>
                        <div className="cardValuesCont">
                          <img src={Humidity} alt="icon" className="cardIcon" />
                          <p
                            style={{
                              color:
                                el.compliance === false ? "red" : "#000000",
                            }}
                            className="card1Values"
                          >
                            {Number(el?.meterValue).toFixed(2)}{" "}
                            {el?.readingUomDesc}
                          </p>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="airContainer">
                  <p className="cardHeading">Humidity</p>
                  <div className="cardValuesCont">
                    <img src={Humidity} alt="icon" className="cardIcon" />
                    <p className="cardValues">-</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ADAirQuality;
