import { useEffect, useState } from "react";
import { AcControllerSetting } from "../types";

import acControllerArrowIcon from "../../acController/assets/acControllerArrowIcon.svg";
import horiZontalLine from "../../../assets/image/horizontalLine.svg";
import acControllerSuccessIcon from "../../acController/assets/acControllerSuccessIcon.svg";
import { updateAcControllerSettings } from "../service/AcControllerApi";
import { FieldValidatorUtil } from "../../../Utils/fieldValidation/FieldValidatorUtil";
import { useQuery, useQueryClient } from "react-query";

type PropTypes = {
  acControllerSetting: AcControllerSetting;
  assetId: string;
  macId: string;
};

function TemperatureMode({ acControllerSetting, assetId, macId }: PropTypes) {
  const queryClient = useQueryClient();
  const [temperatureModeEnabled, setTemperatureModeEnabled] =
    useState<boolean>(false);

  const [selectedTemp, setSelectedTemp] = useState("");
  const [applyButtonDisabled, setApplyButtonDisabled] = useState(true);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { data, error, isLoading, isFetching, refetch, dataUpdatedAt } =
    useQuery(
      "updateAcControllerSettingsForTemp",
      () =>
        updateAcControllerSettings({
          commandsJson: {
            cmd: 203,
            deviceId: `'${macId}'`,
            tempMode: {
              tempFlag: true,
              setTemp: Number(selectedTemp),
            },
          },
          fixedAssetId: assetId,
          topic: "topic-mqtt-Aone-ctrl-cmd-prod",
        }),
      { enabled: false, keepPreviousData: false }
    );

  const updateSettingInput = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error("Error re-fetching data:", error);
    }
  };

  const handleArrowClicks = () => {
    setTemperatureModeEnabled((prev) => !prev);
  };

  const handleTempValue = (value: string) => {
    if (value.includes(".")) {
      return;
    }
    if (value === "") {
      setSelectedTemp(value);
      setApplyButtonDisabled(false);
      return;
    }
    const regex = /^[+-]?\d+$/;
    const validate = new FieldValidatorUtil();

    if (validate.isAssetAttributeValidate(value, regex)) {
      setSelectedTemp(value);
      const floatValue = parseInt(value);
      if (!isNaN(floatValue)) {
        // Check if the value is within the valid range
        if (floatValue >= 16 && floatValue <= 30) {
          setSelectedTemp(value);
          setApplyButtonDisabled(false);
        } else {
          setApplyButtonDisabled(true);
        }
      }
    } else {
      setApplyButtonDisabled(true);
    }
  };

  const updateUseState = () => {
    const tempValue = acControllerSetting.data.setTemp;
    handleTempValue(tempValue?.toString());
  };

  useEffect(() => {
    if (
      acControllerSetting !== undefined &&
      acControllerSetting.hasOwnProperty("key") &&
      acControllerSetting.key !== ""
    ) {
      updateUseState();
    }
  }, [acControllerSetting]);

  useEffect(() => {
    if (data !== undefined && data === 200) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
  }, [data, dataUpdatedAt]);

  /**
   * It will remove the cache of the react-query call for the specific id.
   * @param id : id for the react-query call
   */
  const cancelExistingQuery = (id: string) => {
    queryClient
      .getQueryCache()
      .getAll()
      .forEach((query) => {
        if (query.queryKey.includes(id)) {
          queryClient.removeQueries(query.queryKey);
        }
      });
  };

  useEffect(() => {
    cancelExistingQuery("updateAcControllerSettingsForTemp");
    return () => {
      setShowSuccessMessage(false);
    };
  }, []);

  return (
    <>
      {/* For Temperature Mode */}
      <div className="bodyContentMainDiv">
        <div
          className="acControllerHeadingDiv"
          onClick={() => handleArrowClicks()}
        >
          <img
            src={acControllerArrowIcon}
            className={`acControllerArrowIcon ${
              temperatureModeEnabled ? "rotate-90" : "rotate-0"
            }`}
          />
          <p className="acControllerHeadingText">Temperature Mode</p>
        </div>
        {temperatureModeEnabled && (
          <>
            <div className="acControllerDataInputDiv">
              <div className="acControllerDataDiv">
                <p className="acControllerCategory">Actual set point</p>
                <div className="tempInputDiv">
                  <input
                    className="temperatureInput"
                    type="text"
                    value={selectedTemp}
                    onChange={(e) => handleTempValue(e.target.value)}
                  />
                  <img className="horizontalImage" src={horiZontalLine} />
                  <p className="tempUnit">°C</p>
                </div>
                {applyButtonDisabled && selectedTemp !== "" && (
                  <p className="tempErrorMessage">
                    Enter a value between 16°C to 30°C
                  </p>
                )}
              </div>
            </div>
            {showSuccessMessage ? (
              <div className="successDivCont">
                <img src={acControllerSuccessIcon} alt="success-icon" />
                <p className="successText"> Success</p>
              </div>
            ) : isLoading || isFetching ? (
              <div className="loading-container">
                <div className="loading-spinner"></div>
              </div>
            ) : (
              <div
                className="applyButtonDiv"
                onClick={() => {
                  if (selectedTemp !== "" && !applyButtonDisabled) {
                    updateSettingInput();
                  }
                }}
                style={{
                  opacity: selectedTemp === "" || applyButtonDisabled ? 0.3 : 1,
                  cursor:
                    selectedTemp === "" || applyButtonDisabled
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                <p className="applyButtonText">
                  {error || (data !== undefined && data !== 200)
                    ? "Retry"
                    : "Apply"}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default TemperatureMode;
