import "./EventListCard.scss";

import TempIcon from "../assets/icon-temperature.svg";
import MaskIcon from "../assets/icon_mask.svg";
import EventDefaultIcon from "../assets/icon-events-default.svg";
import ClockIcon from "../assets/ic_clock.svg";
import ImageIcon from "../assets/ic_image.svg";
import VideoIcon from "../assets/ic_video.svg";
import MarkerIcon from "../assets/ic_marker.svg";
import EventListDetail from "./EventListDetail";
import moment from "moment";

import Modal from "react-bootstrap/Modal";
import { EventLog } from "../types/EventListTypes";
import EventListState from "../state/EventListState";
import { formatDuration } from "../utils/Utils";


type PropsType = {
  data: EventLog;
  index: number;
  handleMouseEnter: (index: number) => void;
  handleMouseLeave: () => void;
  hoveredIndex: null | number;
};

function EventListCard({
  data,
  index,
  handleMouseEnter,
  handleMouseLeave,
  hoveredIndex,
}: PropsType) {
  const { showDetails, setShowDetails } = EventListState()

  let formattedDuration = formatDuration(data.startDateTime, data.endDateTime);

  return (
    <>
      {showDetails && (
        <Modal
          size="lg"
          show={showDetails}
          onHide={() => setShowDetails(false)}
          centered
          backdrop="static"
          className="eventListDetailPopUp"
        >
          <EventListDetail
            show={showDetails}
            onHide={() => setShowDetails(false)}
            eventId={data.eventId}
            startDateTime={data.startDateTime}
            assetId={data.assetId}
            objectCount={data.objectCount}
          />
        </Modal>
      )}
      <div
        className="eventListCard"
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
        style={{
          borderColor:
            hoveredIndex === index
              ? "rgba(38, 128, 235, 1)"
              : "rgba(228, 228, 228, 1)",
        }}
        onClick={() => setShowDetails(true)}
      >
        <div className="cardBody">
          <div className="cardTitleDiv">
            <div className="eventTitleAndIcon">
              <img
                className="eventIcon"
                src={
                  data.parentNoteTypeId === "FIRE_HAZARD"
                    ? TempIcon
                    : data.parentNoteTypeId === "SAFETY"
                    ? MaskIcon
                    : EventDefaultIcon
                }
              />
              <p className="eventTitle">{data.eventDescription}</p>
            </div>
            <p className="eventLastOccured">
              {moment(data.endDateTime).fromNow()}
            </p>
          </div>
          <div className="eventCategoryMainDiv">
            <div className="eventCategoryDiv">
              <p className="eventCategoryName">{data.eventCategoryDesc}</p>
              <p className="eventDescName">{data.eventTypeDesc}</p>
            </div>
            <div className="eventLastTimestamp">
              <img src={ClockIcon} />
              <p className="lastTimeStampText">
                {formattedDuration}
              </p>
            </div>
          </div>
        </div>
        <div className="cardFooter">
          <div className="eventAssetCount">
            <div className="eventOccuredCount">
              <p className="eventOccuredCountValue">#{data.objectCount}</p>
              <p className="eventOccuredCountText">Count</p>
            </div>
            <div className="assetDiv">
              <img src={ImageIcon} />
              <p className="assetCountText">{data.imageCount}</p>
            </div>
            <div className="assetDiv">
              <img src={VideoIcon} />
              <p className="assetCountText">{data.videoCount}</p>
            </div>
          </div>
          <div className="eventFacilityDetails">
            <img src={MarkerIcon} />
            <p className="facilityDetails">
              {data.facilityName}, {data.areaDesc}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventListCard;
