import ReactEcharts from "echarts-for-react";
import EnergyIcon from "../../assets/icons/energyIcon.svg";
import waterConsumedIcon from '../../assets/icons/water-consumed-icon.svg';
import airConsumedIcon from '../../assets/icons/air-consumed-icon.svg';
import gasConsumedIcon from '../../assets/icons/gas-consumed-icon.svg';
import fuelConsumedIcon from '../../assets/icons/fuel-consumed-icon.svg';
import "./SingleFacilityEnergyConsumption.scss";
import { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EnergyActiveIcon from "../../assets/icons/energyActiveIcon.svg"
import AirActiveIcon from "../../assets/icons/airActiveIcon.svg"
import FuelActiveIcon from "../../assets/icons/fuelActiveIcon.svg"
import GasActiveIcon from "../../assets/icons/gasActiveIcon.svg"
import WaterActiveIcon from "../../assets/icons/waterActiveIcon.svg"
import EnergyInActiveIcon from "../../assets/icons/energyInActiveIcon.svg"
import AirInActiveIcon from "../../assets/icons/airInActiveIcon.svg"
import FuelInActiveIcon from "../../assets/icons/fuelInActiveIcon.svg"
import GasInaActiveIcon from "../../assets/icons/gasInActiveIcon.svg"
import WaterInActiveIcon from "../../assets/icons/waterInActiveIcon.svg"
import airMeterIcon from "../../assets/icons/air-main-meter-icon.svg"
import airSubMeterIcon from "../../assets/icons/air-sub-meter-icon.svg"
import waterMeterIcon from "../../assets/icons/water-main-meter-icon.svg"
import waterSubMeterIcon from "../../assets/icons/water-sub-meter-icon.svg"
import gasMeterIcon from "../../assets/icons/gas-main-meter-icon.svg"
import gasSubMeterIcon from "../../assets/icons/gas-sub-meter-icon.svg"
import fuelMeterIcon from "../../assets/icons/fuel-main-meter-icon.svg"
import fuelSubMeterIcon from "../../assets/icons/fuel-sub-meter-icon.svg"
import EnergyCardComponent from "./EnergyCardComponent";
import AirCardComponent from "./AirCardComponent";
import WaterCardComponent from "./WaterCardComponent";
import IconClose from "../../assets/icons/IconClose.svg";
import ResetIcon from "../../assets/icons/iconReset.svg"
import SettingsIcon from "../../assets/icons/iconSettigs.svg"
import { Modal } from "react-bootstrap";
import { API_BASE_URL } from "../../Constants/constants";
import DisplayError from "../DisplayError/DisplayError";
import moment from "moment";
import { useStateValue } from "../../redux/StateProvider";
import CloseIcon from "../../assets/icons/IconClose.svg";
import ChipCloseIcon from "../../assets/icons/chipsCloseIcon.svg";
import FilterIcon from "../../assets/icons/filterArrowIcon.svg";
import upArrow from '../../assets/image/upArrow.svg';
import downArrow from '../../assets/image/downArrow.svg'




// interface propsDatatype {
//   energyConsumptionFilter: boolean;
//   setEnergyConsumptionFilter: Function;
// }
interface Facility {
  facilityId: string;
  parentFacilityGroupType: string;
  facilityName: string;
  parentFacilityGroupId: string;
}
interface filteredDataTypes {
  stateIds: string[];
  countryIds: string[];
  regionIds: string[];
  facilityIds: Facility[];
  clusterIds: {
    facilityGroupTypeId: string;
    parentFacilityGroupType: string;
    facilityGroupId: string;
    facilityGroupName: string;
    parentFacilityGroupId: string;
  }[];
  cityIds: {
    facilityGroupTypeId: string;
    parentFacilityGroupType: string;
    facilityGroupId: string;
    facilityGroupName: string;
    parentFacilityGroupId: string;
  }[];
}

type ChartsDropDownData = {
  [key: string]: string[];
};


function SingleFacilityEnergyConsumption(props: any) {
  const { fullSizedComponents, showSettingsPopup, setShowSettingsPopup, energyConsumptionFilter, setEnergyConsumptionFilter } = props;

  useEffect(() => {
    callingUserSpecificAPIForDecimal();
  }, [])
  const [decimalVal, setDecimalVal] = useState<any>(0)
  // const facilityList: any = localStorage.getItem("facilityIds");
  const firstFacilityId = '_NA_'
  // JSON.parse(facilityList)[0].facilityId;
  const [currentFilterByTypeAndId, setCurrentFilterByTypeAndId] = useState({
    filterByType: "facility",
    filterById: firstFacilityId,
  });

  useEffect(()=>{
    console.log('ActiveFilter::',currentFilterByTypeAndId)
  },[currentFilterByTypeAndId])

  const [tabsArr, setTabsArr] = useState<any>([{ name: 'Energy', abbreviation: 'kWh', id: 0, activeIcon: EnergyActiveIcon, inActiveIcon: EnergyInActiveIcon, consumedIcon: EnergyIcon, meterIcon: '', subMeterIcon: '' },
  { name: 'Air', abbreviation: 'm3', id: 1, activeIcon: AirActiveIcon, inActiveIcon: AirInActiveIcon, consumedIcon: airConsumedIcon, meterIcon: airMeterIcon, subMeterIcon: airSubMeterIcon },
  { name: 'Water', abbreviation: 'KL', id: 2, activeIcon: WaterActiveIcon, inActiveIcon: WaterInActiveIcon, consumedIcon: waterConsumedIcon, meterIcon: waterMeterIcon, subMeterIcon: waterSubMeterIcon },
  { name: "Gas", abbreviation: '', id: 3, activeIcon: GasActiveIcon, inActiveIcon: GasInaActiveIcon, consumedIcon: gasConsumedIcon, meterIcon: gasMeterIcon, subMeterIcon: gasSubMeterIcon },
  { name: 'Fuel', abbreviation: '', id: 4, activeIcon: FuelActiveIcon, inActiveIcon: FuelInActiveIcon, consumedIcon: fuelConsumedIcon, meterIcon: fuelMeterIcon, subMeterIcon: fuelSubMeterIcon }])

  const [activeTab, setActiveTab] = useState(0);

  const tabArr = [{ name: 'Energy', component: <EnergyCardComponent fullSizedComponents={fullSizedComponents} decimalVal={decimalVal} currentFilterByTypeAndId={currentFilterByTypeAndId} />, abbreviation: 'kWh', id: 0, activeIcon: EnergyActiveIcon, inActiveIcon: EnergyInActiveIcon, consumedIcon: EnergyIcon, meterIcon: '', subMeterIcon: '' },
  { name: 'Air', component: <AirCardComponent fullSizedComponents={fullSizedComponents} decimalVal={decimalVal} currentFilterByTypeAndId={currentFilterByTypeAndId} />, abbreviation: 'm3', id: 1, activeIcon: AirActiveIcon, inActiveIcon: AirInActiveIcon, consumedIcon: airConsumedIcon, meterIcon: airMeterIcon, subMeterIcon: airSubMeterIcon },
  { name: 'Water', component: <WaterCardComponent fullSizedComponents={fullSizedComponents} decimalVal={decimalVal} currentFilterByTypeAndId={currentFilterByTypeAndId} />, abbreviation: 'KL', id: 2, activeIcon: WaterActiveIcon, inActiveIcon: WaterInActiveIcon, consumedIcon: waterConsumedIcon, meterIcon: waterMeterIcon, subMeterIcon: waterSubMeterIcon },
  { name: "Gas", abbreviation: '', component: <DisplayError type={"err-empty"} />, id: 3, activeIcon: GasActiveIcon, inActiveIcon: GasInaActiveIcon, consumedIcon: gasConsumedIcon, meterIcon: gasMeterIcon, subMeterIcon: gasSubMeterIcon },
  { name: 'Fuel', abbreviation: '', component: <DisplayError type={"err-empty"} />, id: 4, activeIcon: FuelActiveIcon, inActiveIcon: FuelInActiveIcon, consumedIcon: fuelConsumedIcon, meterIcon: fuelMeterIcon, subMeterIcon: fuelSubMeterIcon }]

  const getTabs = () => {
    let loginData: any = localStorage.getItem("aoneLoginData");
    let dummyData = {
      "loginDetails": [
        {
          "product": [
            {
              "primaryProductCategoryDescription": "",
              "productId": "AONE_ENERGY",
              "primaryProductCategoryId": null,
              "productTypeDescription": "Digital Good",
              "productTypeId": "DIGITAL_GOOD",
              "productName": "AOne Energy Monitoring"
            },
            {
              "primaryProductCategoryDescription": "",
              "productId": "AONE_AIR",
              "primaryProductCategoryId": null,
              "productTypeDescription": "Digital Good",
              "productTypeId": "DIGITAL_GOOD",
              "productName": "AOne Air Monitoring"
            },
            {
              "primaryProductCategoryDescription": "",
              "productId": "AONE_WATER",
              "primaryProductCategoryId": null,
              "productTypeDescription": "Digital Good",
              "productTypeId": "DIGITAL_GOOD",
              "productName": "AOne Water Monitoring"
            },
            {
              "primaryProductCategoryDescription": "",
              "productId": "AONE_FUEL",
              "primaryProductCategoryId": null,
              "productTypeDescription": "Digital Good",
              "productTypeId": "DIGITAL_GOOD",
              "productName": "AOne Fuel Monitoring"
            }
          ]
        },]
    }
    if(JSON.parse(loginData).loginDetails[0].product !== undefined){
    let subscribedTabs = 
    // dummyData
       JSON.parse(loginData)
      .loginDetails[0].product.map((obj: any) => obj.productName.split(" ")[1]);
    setTabsArr(() => tabArr.filter((obj) => subscribedTabs.includes(obj.name)).map((obj, index) => ({ ...obj, id: index })))
    }else{
      setMainRespStat('empty')
    }
  }

  const setDecimalValues = (val: any) => {
    if (val >= 0 && val <= 4) {
      setDecimalVal(val);
    }
  }
  const restoreDecimalValue = useRef(0);
  const callingUserSpecificAPIForDecimal = async (val = 'empty') => {
    const token: any = localStorage.getItem("@tokenID");
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: val === 'empty' ? JSON.stringify({
        "portletId": "CONSUMPTION_V1",
      }) : JSON.stringify({
        "portletId": "CONSUMPTION_V1",
        "userPrefValue": "" + val,
        "userPrefTypeId": "CNSP_DECIMAL_SETTING"
      })
    }
    const data = await fetch(`${API_BASE_URL}/assets/userSpecificData`, options)
    const response = await data.json();
      restoreDecimalValue.current = response?.data?.all?.preferences?.filter((obj: any) => obj.portalPortletId === 'CONSUMPTION_V1')[0]?.userPrefValue !== undefined ? +response?.data?.all?.preferences?.filter((obj: any) => obj.portalPortletId === 'CONSUMPTION_V1')[0]?.userPrefValue : 2
      setDecimalVal(response?.data?.all?.preferences?.filter((obj: any) => obj.portalPortletId === 'CONSUMPTION_V1')[0]?.userPrefValue !== undefined ? +response?.data?.all?.preferences?.filter((obj: any) => obj.portalPortletId === 'CONSUMPTION_V1')[0]?.userPrefValue : 2)
      getTabs();
  }



  // Filter Functionality............................................

  const [{ dateRange, dateChip }] = useStateValue();
  const [filterData, setFilterData] = useState<filteredDataTypes | any>();
  const hierarchyLevels = [
    "countryIds",
    "stateIds",
    "regionIds",
    "cityIds",
    "clusterIds",
    "facilityIds",
  ];

  const [mappingArr, setMappingArr] = useState<
    {
      dropDownName: string;
      values: any[];
      show: boolean;
      selectedValue: string;
      selectedId: string;
    }[]
  >([
    {
      dropDownName: "",
      values: [],
      show: false,
      selectedValue: "",
      selectedId: "",
    },
  ]);
  const [arrayWithData, setArraysWithData] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const findHierarchyLevelsToDisplay = (filterData: any) => {
    let arrayWithNonEmptyValues: string[] = [];
    let tempArr = Object.keys(filterData).filter(
      (ele) => filterData[ele as keyof filteredDataTypes].length !== 0
    );
    hierarchyLevels.forEach((el) =>
      tempArr.includes(el) ? arrayWithNonEmptyValues.push(el) : ""
    );
    setArraysWithData(arrayWithNonEmptyValues);
  };

  const pushDataToMappingArr = (keyName: string) => {
    if (mappingArr[0].values.length === 0) {
      setMappingArr([
        {
          dropDownName: keyName,
          values: filterData[keyName as keyof filteredDataTypes],
          show: true,
          selectedValue: "",
          selectedId: "",
        },
      ]);
      setCurrentIndex(currentIndex + 1);
      return;
    }

    const index = hierarchyLevels.indexOf(keyName);
    setMappingArr((element) => [
      ...element,
      {
        dropDownName: keyName,
        values: filterData[keyName as keyof filteredDataTypes].filter(
          (obj: any) =>
            obj.parentFacilityGroupId ===
            element.find(
              (name) => name.dropDownName === hierarchyLevels[index - 1]
            )?.selectedId
        ),
        show: true,
        selectedValue: "",
        selectedId: "",
      },
    ]);
    setCurrentIndex(currentIndex + 1);
  };

  const [showSelectedFilterChipName, setSelectdFilterChipName] = useState<any>([]);

  useEffect(() => {
    const filterDataString = localStorage.getItem("filterRelatedData");
    const filterObj = JSON.parse(filterDataString ?? "{}");
    setFilterData(filterObj);
    findHierarchyLevelsToDisplay(filterObj);
  }, []);

  const filterBasedOnSelectedItem = (
    id: string,
    selectedName: string,
    dropDownName: string,
    type : string
  ) => {
    showContentLoader.current = true;

    setSelectdFilterChipName((prevSelectedFilters: any[]) => {
      const elementExists = prevSelectedFilters.some(
        (filter) => filter.type === type
      ); 
      if (elementExists) {
        return prevSelectedFilters.map((filter) =>
          filter.type === type ? { type: type, selectedId: id, selectedValue: selectedName } : filter
        );
      } else {
        return [...prevSelectedFilters, { type: type, selectedId: id, selectedValue: selectedName }];
      }
    });
    
    setMappingArr((ele) =>
    {
     
      let data = ele.map((obj: any) => {
        if (obj.dropDownName === dropDownName) {
          return {
            ...obj,
            selectedValue: selectedName,
            selectedId: id,
            show: obj.show,
          };
        } else {
          return obj;
        }
      })
      let index = data.findIndex((obj) => obj.selectedId === id);
      if(data[index+1] !== undefined){
        data = data.slice(0,index+1)
        setCurrentIndex(index+1);
        if(showSelectedFilterChipName.length > 1){
             setSelectdFilterChipName((element:any) => element.slice(0, index +1));
        }
      }
      return data
    }
    );
    const index = hierarchyLevels.indexOf(dropDownName);
    // if (hierarchyLevels[index + 1] !== undefined) {
    //   setMappingArr((ele) =>
    //     ele.map((obj: any) => {
    //       if (obj.dropDownName === hierarchyLevels[index + 1]) {
    //         return {
    //           ...obj,
    //           values: filterData[
    //             hierarchyLevels[index + 1] as keyof filteredDataTypes
    //           ].filter((obj: any) => obj.parentFacilityGroupId === id),
    //           selectedValue: "",
    //           selectedId: "",
    //         };
    //       } else {
    //         return obj;
    //       }
    //     })
    //   );
    // }
    setSelectorChip(true);
    setCurrentFilterByTypeAndId({
      filterByType: dropDownName.slice(0, -3),
      filterById: id,
    });
  };
  
  const [showSelectorChip, setSelectorChip] = useState(true);
  const removeSelectedFilters = (id: string) => {
    // console.log("ActiveFilter::",showSelectedFilterChipName)
    const index = mappingArr.findIndex((obj) => obj.selectedId === id);
    if (index == 0) {
      setMappingArr([
        {
          dropDownName: "",
          values: [],
          show: false,
          selectedValue: "",
          selectedId: "",
        },
      ]);
      setCurrentIndex(0);
      setCurrentFilterByTypeAndId({
        filterByType: "facility",
        filterById: firstFacilityId,
      });
      setSelectorChip(true);
      showContentLoader.current = true;
      setSelectdFilterChipName([]);
      return;
    }
    setSelectorChip(true);
    setMappingArr((element) => element.slice(0, index));
    setSelectdFilterChipName((element:any) => element.slice(0, index));
    setCurrentIndex(index);

    let getCurrentFilterChipsData = [...showSelectedFilterChipName];
    getCurrentFilterChipsData = getCurrentFilterChipsData.slice(0,index);

    setCurrentFilterByTypeAndId({
      filterByType: showSelectedFilterChipName[getCurrentFilterChipsData.length-1].type.toLowerCase(),
      filterById: showSelectedFilterChipName[getCurrentFilterChipsData.length-1].selectedId,
    });
  };

  const showContentLoader = useRef(false);
  useEffect(() => {
    // setMappingArr([
    //   {
    //     dropDownName: "",
    //     values: [],
    //     show: false,
    //     selectedValue: "",
    //     selectedId: "",
    //   },
    // ]);
    // setEnergyConsumptionFilter(false);
    // setCurrentFilterByTypeAndId({
    //   filterByType: "facility",
    //   filterById: '_NA_',
    // });
    showContentLoader.current = false;
  }, [dateChip, dateRange]);

  useEffect(() => {
    getTabs();
  }, [fullSizedComponents, currentFilterByTypeAndId,showSelectedFilterChipName, decimalVal])

  const [mainRespStat, setMainRespStat] = useState('');

  return (
    <>
    { mainRespStat === "empty" ? (
        <DisplayError type={"err-empty"} />
      ) : mainRespStat === "error" ? (
        <DisplayError type={"err-500/404"} />
      ) : 
      <div className="energyConsumptionContainer">
        {/* filter functionality */}
        {energyConsumptionFilter && (
          <div className="filterChipsContainer">
            <div className="filterChipsDiv">
              <div className="filterChipsMapCont">

                {showSelectedFilterChipName.length !== 0 &&
                  showSelectedFilterChipName.map((element:any) => (
                      <div key={element.selectedId} className="filterChips">
                        <p className="chipHeader">{element.selectedValue}</p>
                        <img
                          src={ChipCloseIcon}
                          alt="icon"
                          className="chipCloseIcon"
                          onClick={() => removeSelectedFilters(element.selectedId)}
                        />
                      </div>
                    ))}
              </div>
              {mappingArr[0].values.length !== 0 && <div className="filterCloseCont"
                onClick={() => {
                  showContentLoader.current = true;
                  setEnergyConsumptionFilter(false);
                  setSelectdFilterChipName([]);
                  setMappingArr([
                    {
                      dropDownName: "",
                      values: [],
                      show: false,
                      selectedValue: "",
                      selectedId: "",
                    },
                  ]);
                  setCurrentFilterByTypeAndId({
                    filterByType: "facility",
                    filterById: '_NA_',
                  });
                  setSelectorChip(true);
                  setCurrentIndex(0);
                }}
              >
                <img src={CloseIcon} alt="icon" className="closeIcon" />
              </div>}
            </div>
            <div className="filterChipSelectorCont">
              {mappingArr[0].values.length != 0 &&
                mappingArr.map((element) => (
                  <>
                    {" "}
                    <div className="filterSelectorContainer">
                      <div className="chipFilterSelector">
                        <input
                          onClick={()=> {setMappingArr((ele) =>
                            ele.map((obj: any) => {
                              if (obj.dropDownName === element.dropDownName) {
                                return {
                                  ...obj,
                                  selectedValue:'',
                                  show: true 
                                };
                              } else {
                                return obj;
                              }
                            })
                          ); 
                          // {element.selectedId !== undefined && removeSelectedFilters(element.selectedId)}
                         }}
                          type="search"
                          placeholder="Search..."
                          value={element.selectedValue}
                          onChange={(e) =>
                            setMappingArr((ele) =>
                              ele.map((obj: any) => {
                                if (obj.dropDownName === element.dropDownName) {
                                  return {
                                    ...obj,
                                    selectedValue: e.target.value,
                                  };
                                } else {
                                  return obj;
                                }
                              })
                            )
                          }
                        />
                       <img
                          src={FilterIcon}
                          alt="icon"
                          onClick={() =>
                            setMappingArr((ele) =>
                              ele.map((obj: any, index) => {
                                if (obj.dropDownName === element.dropDownName) {
                                  if(obj.show){
                                    let value = showSelectedFilterChipName[index];
                                    return {
                                      ...obj,
                                      selectedValue: value == undefined ? '': value.selectedValue,
                                      show: !obj.show
                                    };
                                  }
                                  return {
                                    ...obj,
                                    selectedValue:'',
                                    show: !obj.show
                                  };
                                } else {
                                  return obj;
                                }
                              })
                            )
                          }
                        />
                      </div>
                      {
                        (element.show )  && (
                        <ul className="chipFilterList">
                          {element.values
                            .filter((search) =>
                              search.facilityGroupName
                                ? search.facilityGroupName
                                  .toLowerCase()
                                  .includes(element.selectedValue.toLowerCase())
                                : search.facilityName
                                  .toLowerCase()
                                  .includes(element.selectedValue.toLowerCase())
                            )
                            .map((list: any) => (
                              <li
                                onClick={() =>{
                                  filterBasedOnSelectedItem(
                                    list.facilityGroupId
                                      ? list.facilityGroupId
                                      : list.facilityId,
                                    list.facilityGroupName
                                      ? list.facilityGroupName
                                      : list.facilityName,
                                    element.dropDownName,
                                    list.facilityGroupTypeId !== undefined ? list.facilityGroupTypeId : 'facility'
                                  ); setMappingArr((ele) =>
                                    ele.map((obj: any) => {
                                      if (obj.dropDownName === element.dropDownName) {
                                        return { ...obj, show: false};
                                      } else {
                                        return obj;
                                      }
                                    })
                                  );}
                                }
                                key={
                                  list.facilityGroupId
                                    ? list.facilityGroupId
                                    : list.facilityId
                                }
                              >
                                {list.facilityGroupName
                                  ? list.facilityGroupName
                                  : list.facilityName}
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </>
                ))}
              {showSelectorChip && arrayWithData[currentIndex] && (
                <div
                  className="selectorChip"
                  onClick={() => {
                    pushDataToMappingArr(arrayWithData[currentIndex]);
                    setSelectorChip(false);
                  }}
                >
                  <p className="selectorChipHeader">
                    {arrayWithData[currentIndex]
                      ? `Select ${arrayWithData[currentIndex].slice(0, -3)}`
                      : ""}{" "}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        {/*Consumption Tabs Render here */}
        <div className="energyConsumptionTabsCont">
          {
            tabsArr.map((obj: any) => <div style={{ border: obj.id === activeTab ? '' : 'none' }} key={obj.id} onClick={() => { setActiveTab(obj.id) }} className='toastTabSwitch'>
              <img src={obj.id === activeTab ? obj.activeIcon : obj.inActiveIcon} alt="icon" className="tabsIcons" />
              <p style={{ color: obj.id === activeTab ? '' : 'black' }}>{obj.name}</p>
            </div>)
          }
        </div>
        {/* Components will render according to above tabs selection  */}
        {tabsArr[activeTab].component}
      </div>
      }
      <Modal
        show={showSettingsPopup}
        onHide={() => setShowSettingsPopup(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="energyConsumptionPopup"

      >
        <Modal.Header className="energyConsumptionHeaderCont">
          <div className="energyHeaderIconTextCont">
            <img src={SettingsIcon} alt="icon" />
            <h4 className="energyPopupHeader">Settings</h4>
          </div>
          <div className="closeIconDiv" onClick={() => setShowSettingsPopup(false)}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="energyConsumptionBodyCont">
          <div className="energyBodayDetailsCont">
            <p className="energyDetailsText">Decimal Precision for Consumption</p>
            <div className="input-cont">
              <input type="number" value={decimalVal} readOnly onChange={(e) => setDecimalValues(e.target.value)} className="energyPopupInput" />
              <div className="arrow-cont">
                <button onClick={() => setDecimalValues(decimalVal + 1)}><img src={upArrow} alt="Arrow" /></button>
                <button onClick={() => setDecimalValues(decimalVal - 1)} className="downButton"><img src={downArrow} alt="Arrow" /></button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="energyConsumptionNavCont">
          <div className="consumptionNavDiv">
            <div className="energyResetBtnCont" onClick={() => setDecimalVal(restoreDecimalValue.current)}>
              <img src={ResetIcon} alt="icon" />
              <p className="resetBtnText">Reset to defaults</p>
            </div>

            <div className="energyConsumptionBtnsCont">
              <button
                className="energyCancelBtn" onClick= {() => {setShowSettingsPopup(false); setDecimalVal(restoreDecimalValue.current);}}
              >
                Cancel
              </button>
              <button
                className="energyApplyBtn" onClick={() =>{callingUserSpecificAPIForDecimal(decimalVal); setShowSettingsPopup(false) }}
              >
                Apply
              </button>
            </div>
          </div>

        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SingleFacilityEnergyConsumption;
//