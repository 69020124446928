import { API_BASE_URL_DOWNLOAD } from "../../../Constants/constants";
import { FileFormat } from "../../../Utils/enumData";
import LocalStorageService from "../../../Utils/LocalStorageService";

export const createDownloadUrl = (
  selectedFileFormat: string,
  startDate: number,
  endDate: number
) => {
  const fileExtension =
    selectedFileFormat === FileFormat.Excel ? "xlsx" : "pdf";
  const userName = LocalStorageService.getUsername();
  const password = LocalStorageService.getPassword();
  const url = `${API_BASE_URL_DOWNLOAD}services/control/EventLog.${fileExtension}?username=${userName}&password=${password}&fromDate=${startDate}&thruDate=${endDate}&&mode=DD`;
  return url;
};
