import "./EventCategoryList.scss";
import HorizontalLine from "../assets/horizontalLine.svg";
import { EventCategoryListType } from "../types";

type PropTypes = {
  handleMouseEnter: (index: number) => void;
  handleMouseLeave: () => void;
  handleEventSelection: (index: number, item: EventCategoryListType) => void;
  selectedIndex: number | null;
  hoveredIndex: number | null;
  data: EventCategoryListType[];
};

function EventCategoryList({
  handleMouseEnter,
  handleMouseLeave,
  handleEventSelection,
  selectedIndex,
  hoveredIndex,
  data,
}: PropTypes) {
  return (
    <div className="eventDataList">
      {data.map((item, index) => (
        <div
          className="eventDataCard"
          key={index}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleEventSelection(index, item)}
          style={{
            borderBottomWidth: selectedIndex === index ? "2px" : "0px",
          }}
        >
          <div className="eventDetailsDiv">
            <p
              className="eventName"
              style={{
                color:
                  hoveredIndex === index || selectedIndex === index
                    ? "rgba(55, 77, 198, 1)"
                    : "rgba(112, 112, 112, 1)",
              }}
            >
              {item.eventTypePrntType}
            </p>
            <p
              className="eventValue"
              style={{
                color:
                  hoveredIndex === index || selectedIndex === index
                    ? "rgba(55, 77, 198, 1)"
                    : "rgba(27, 27, 27, 1)",
              }}
            >
              {item.eventCountPerCategory}
            </p>
            <p
              className="eventCategory"
              style={{
                color:
                  hoveredIndex === index
                    ? "rgba(255, 255, 255, 1)"
                    : "rgba(112, 112, 112, 1)",
                padding: hoveredIndex === index ? "2px 4px 2px 4px" : "0px",
                backgroundColor:
                  hoveredIndex === index
                    ? "rgba(55, 77, 198, 1)"
                    : "rgba(255, 255, 255, 1)",
              }}
            >
              events
            </p>
          </div>
          {/* <div className="horizontalLineDiv" /> */}
          {data.length - 1 !== index && <img src={HorizontalLine} />}
        </div>
      ))}
    </div>
  );
}

export default EventCategoryList;
