/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import React from "react";
import TempImg from "../../../assets/icons/TempImg.svg";
import CurrentImg from "../../../assets/icons/CurrentImg.svg";
import VoltageImg from "../../../assets/icons/VoltageImg.svg";
import PowerImg from "../../../assets/icons/PowerImg.svg";
import FrequencyImg from "../../../assets/icons/FrequencyImg.svg";
import ApparentPowerImg from "../../../assets/icons/ApparentPowerImg.svg";
import * as R from "ramda";

import "./ADTempEnergy.scss";
import { subtractWholeNumber } from "../../../Utils/utils";

function ADTempEnergy({ data, closeOpenBtn }: any) {
  return (
    <div className="tempEnergyContainer">
      <div className="cardsRowCont">
        <div className="rowOne">
          {/* <div className="card1Container">
            <p className="card1Heading">Temperature</p>
            <div className="card1ValuesCont">
              <img src={TempImg} alt="icon" className="card1Icon" />
              <p className="card1Values">20.00 °C</p>
            </div>
            <p className="card1Celcius">Min 4 °C - Max 6 °C</p>
          </div> */}
          {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
            data?.meters.map(
              (el, index) =>
                el.meterTypeId.includes("TEMP") && (
                  <div className="card1Container" key={index}>
                    <p className="card1Heading">Temperature</p>
                    <div className="card1ValuesCont">
                      <img src={TempImg} alt="icon" className="card1Icon" />
                      <p
                        style={{
                          color: el.compliance === false ? "red" : "#000000",
                        }}
                        className="card1Values"
                      >
                        {Number(el?.meterValue).toFixed(2)}°{el.readingUomDesc}
                      </p>
                    </div>
                    <p className="card1Celcius">
                      Min {data?.temp_min?.value}°{data?.temp_min?.abbreviation}{" "}
                      - Max {data?.temp_max?.value}°
                      {data?.temp_max?.abbreviation}
                    </p>
                  </div>
                )
            )
          ) : (
            <div className="card1Container">
              <p className="card1Heading">Temperature</p>
              <div className="card1ValuesCont">
                <img src={TempImg} alt="icon" className="card1Icon" />
                <p className="card1Values">-</p>
              </div>
              <p className="card1Celcius">Min 4 °C - Max 6 °C</p>
            </div>
          )}

          {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
            data?.meters.map(
              (el, index) =>
                el.meterTypeId.includes("CURRENT") && (
                  <>
                    <div className="card3Cont">
                      <p className="card3Heading">Current</p>
                      <div className="card3ValuesCont">
                        <img
                          src={CurrentImg}
                          alt="icon"
                          className="card3Icon"
                        />
                        <p className="card3Values">
                          {Number(el?.meterValue).toFixed(2)}{" "}
                          {el.readingUomDesc}
                        </p>
                      </div>
                    </div>
                  </>
                )
            )
          ) : (
            <>
              <div className="card3Cont">
                <p className="card3Heading">Current</p>
                <div className="card3ValuesCont">
                  <img src={CurrentImg} alt="icon" className="card3Icon" />
                  <p className="card3Values">-</p>
                </div>
              </div>
            </>
          )}

          {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
            data?.meters.map(
              (el, index) =>
                el.meterTypeId.includes("VOLTAGE") && (
                  <>
                    <div className="card3Cont">
                      <p className="card3Heading">Voltage</p>
                      <div className="card3ValuesCont">
                        <img
                          src={VoltageImg}
                          alt="icon"
                          className="card3Icon"
                        />
                        <p className="card3Values">
                          {Number(el?.meterValue).toFixed(2)}{" "}
                          {el.readingUomDesc}
                        </p>
                      </div>
                    </div>
                  </>
                )
            )
          ) : (
            <>
              <div className="card3Cont">
                <p className="card3Heading">Voltage</p>
                <div className="card3ValuesCont">
                  <img src={VoltageImg} alt="icon" className="card3Icon" />
                  <p className="card3Values">-</p>
                </div>
              </div>
            </>
          )}

          {/* <div className="card4Cont">
            <p className="card4Heading">Power Factor</p>
            <div className="card4ValuesCont">
              <img src={PowerImg} alt="icon" className="card4Icon" />
              <p className="card4Values">0.9</p>
            </div>
            <p className="card4Ideal">Ideal Value: 1</p>
          </div> */}

          {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
            data?.meters.map(
              (el, index) =>
                el.meterTypeId.includes("POWER_FACTOR") && (
                  <>
                    <div className="card4Cont">
                      <p className="card4Heading">Power Factor</p>
                      <div className="card4ValuesCont">
                        <img src={PowerImg} alt="icon" className="card4Icon" />
                        <p className="card4Values">
                          {subtractWholeNumber(el?.meterValue, 2)}
                          {/* {Number(el?.meterValue).toFixed(2)} */}
                          {el.readingUomDesc}
                        </p>
                      </div>
                      {/* <p className="card4Ideal">Ideal Value: 1</p> */}
                    </div>
                  </>
                )
            )
          ) : (
            <>
              <div className="card4Cont">
                <p className="card4Heading">Power Factor</p>
                <div className="card4ValuesCont">
                  <img src={PowerImg} alt="icon" className="card4Icon" />
                  <p className="card4Values">-</p>
                </div>
                {/* <p className="card4Ideal">Ideal Value: 1</p> */}
              </div>
            </>
          )}

          {/* <div className="card5Cont">
            <p className="card5Heading">Voltage</p>
            <div className="card5ValuesCont">
              <img src={FrequencyImg} alt="icon" className="card5Icon" />
              <p className="card5Values">49.3 Hz</p>
            </div>
          </div> */}
        </div>
        {closeOpenBtn && (
          <div className="rowTwo">
            {/* <div className="card5Cont">
              <p className="card5Heading">Active Power</p>
              <div className="card5ValuesCont">
                <img src={ApparentPowerImg} alt="icon" className="card5Icon" />
                <p className="card5Values">200 kW</p>
              </div>
            </div> */}
            {console.log("The active power factop", data?.meters)}
            {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
              data?.meters.map(
                (el, index) =>
                  el.meterTypeId.includes("FREQUENCY") && (
                    <>
                      <div key={index} className="card5Cont">
                        <p className="card5Heading">Frequency</p>
                        <div className="card5ValuesCont">
                          <img
                            src={FrequencyImg}
                            alt="icon"
                            className="card5Icon"
                          />
                          <p className="card5Values">
                            {Number(el?.meterValue).toFixed(2)}{" "}
                            {el.readingUomDesc}
                          </p>
                        </div>
                      </div>
                    </>
                  )
              )
            ) : (
              <>
                <div className="card5Cont">
                  <p className="card5Heading">Frequency</p>
                  <div className="card5ValuesCont">
                    <img src={FrequencyImg} alt="icon" className="card5Icon" />
                    <p className="card5Values">-</p>
                  </div>
                </div>
              </>
            )}
            {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
              data?.meters
                .filter((el) => el.readingUomId === "ACTIVE_POWER")
                .map((el, index) => (
                  <div key={index} className="card5Cont">
                    <p className="card5Heading">Active Power</p>
                    <div className="card5ValuesCont">
                      <img
                        src={ApparentPowerImg}
                        alt="icon"
                        className="card5Icon"
                      />
                      <p className="card5Values">
                        {Number(el?.meterValue).toFixed(2)} {el.readingUomDesc}
                      </p>
                    </div>
                  </div>
                ))
            ) : (
              <>
                <div className="card5Cont">
                  <p className="card5Heading">Apparent Power</p>
                  <div className="card5ValuesCont">
                    <img
                      src={ApparentPowerImg}
                      alt="icon"
                      className="card5Icon"
                    />
                    <p className="card5Values">-</p>
                  </div>
                </div>
              </>
            )}

            {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
              data?.meters
                .filter((el) => el.meterTypeId === "KVA")
                .map((el, index) => (
                  <div key={index} className="card5Cont">
                    <p className="card5Heading">Apparent Power</p>
                    <div className="card5ValuesCont">
                      <img
                        src={ApparentPowerImg}
                        alt="icon"
                        className="card5Icon"
                      />
                      <p className="card5Values">
                        {Number(el?.meterValue).toFixed(2)} {el.readingUomDesc}
                      </p>
                    </div>
                  </div>
                ))
            ) : (
              <>
                <div className="card5Cont">
                  <p className="card5Heading">Apparent Power</p>
                  <div className="card5ValuesCont">
                    <img
                      src={ApparentPowerImg}
                      alt="icon"
                      className="card5Icon"
                    />
                    <p className="card5Values">-</p>
                  </div>
                </div>
              </>
            )}
            {/* <div className="card5Cont">
              <p className="card5Heading">Apparent Power</p>
              <div className="card5ValuesCont">
                <img src={ApparentPowerImg} alt="icon" className="card5Icon" />
                <p className="card5Values">49.kVA</p>
              </div>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default ADTempEnergy;
