/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import React, { useState, useEffect } from "react";
import waterIcon2 from "../../../assets/image/waterIcon2.svg";
import * as R from "ramda";
import "./ADWaterPulse.scss";

function ADWaterPulse({ data, closeOpenBtn }: any) {
    const [reg, setReg] = useState();
    useEffect(() => {
        let reg = data.meters.find(
            (obj: { meterTypeId: string }) => obj.meterTypeId === "WATER_VOLUME"
        );
        setReg(reg);
    }, [data]);

    return (
        <div className="tempEnergyContainer">
            <div className="cardsRowCont">
                <div className="rowOne">
                    {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
                        data?.meters.map(
                            (el, index) =>
                                el.meterTypeId.includes("WATER_VOLUME") && (
                                    <div className="card2Container" key={index}>
                                        <p className="card2Heading">Consumption</p>
                                        <div className="card2ValuesCont">
                                            <img src={waterIcon2} alt="icon" className="card2Icon" />
                                            <p className="card2Values">
                                                {Number(el?.meterValue).toFixed(2)} {el.readingUomDesc}
                                            </p>
                                        </div>
                                    </div>
                                )
                        )
                    ) : (
                        <div className="card2Container">
                            <p className="card2Heading">Consumption</p>
                            <div className="card2ValuesCont">
                                <img src={waterIcon2} alt="icon" className="card2Icon" />
                                <p className="card2Values">-</p>
                            </div>
                            <p className="card2Celcius"></p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ADWaterPulse;
