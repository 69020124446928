/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";

import TempImg from "../../../assets/icons/TempImg.svg";
import airIcon from "../../../assets/image/airIcon.svg";
import "./ADAir.scss";
import * as R from "ramda";
function ADAir({ data }:any) {

  return (
    <>
      {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
        data?.meters.map(
          (el:any, index:any) =>
            el.meterTypeId.includes("F_MASS") && (
              <div className="airContainer">
                <p className="cardHeading">Flow Rate</p>
                <div className="cardValuesCont">
                  <img src={airIcon} alt="icon" className="cardIcon" />
                  <p
                    style={{
                      color: el.compliance === false ? "red" : "#000000",
                    }}
                    className="card1Values"
                  >
                    {Number(el?.meterValue).toFixed(2)} {el?.readingUomDesc}
                  </p>
                </div>
               
              </div>
            )
        )
      ) : (
        <div className="airContainer">
          <p className="cardHeading">Flow Rate</p>
          <div className="cardValuesCont">
            <img src={airIcon} alt="icon" className="cardIcon" />
            <p className="cardValues">-</p>
          </div>
         
        </div>
      )}
    
    </>
  );
}

export default ADAir;
