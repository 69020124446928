import { SyntheticEvent, useEffect, useRef } from "react";
import "./AssetList.scss";
import { useQuery, useQueryClient } from "react-query";
import { getAssetList } from "../service/AssetListService";
import AssetCard from "./AssetCard";
import {
  FilterDataObj,
  FilterDataType,
  NewAssetData,
  SearchListType,
} from "../types";
import LoadMoreBtn from "./LoadMoreBtn";
import { useStateValue } from "../../../redux/StateProvider";
import { Outlet, useLocation } from "react-router-dom";
import * as R from "ramda";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import { SettingsApiServices } from "../../../services/settingsPageServices/SettingsApiServices";
import { cancelExistingQuery } from "../../../Utils/utils";
import AssetFilterData from "../utils/AssetFilterData";
import { DropdownType } from "../../../Utils/enumData";
import MultileTypeFilter from "../../filterComponent/components/MultileTypeFilter";
import { FilterUtils } from "../utils/FilterUtils";
import { getKeyForSelectedFilter } from "../../../Constants/constants";
import AssetListState from "../state/AssetListState";
import { checkAssetDataStatus } from "../utils";
import SingleTypeFilter from "../../filterComponent/components/SingleTypeFilter";
import LocalStorageService from "../../../Utils/LocalStorageService";
import { Col, Row } from "react-bootstrap";
import { debounce } from 'lodash';

function AssetList() {
  const queryClient = useQueryClient();

  const [{ searchText }] = useStateValue();
  const pageSize = 50;
  const pollInterval = 2 * 60 * 1000; //2 mins
  const pageIndex = useRef<any>(1);
  const nextPageIndex = useRef<any>(1);
  const totalPages = useRef<any>(null);

  let location = useLocation();
  const currentPath = location.pathname;
  const scrollRef = useRef(null);

  const {
    assetList,
    setAssetList,
    showLoadMore,
    setShowLoadMore,
    showLoading,
    setShowLoading,
    showError,
    setShowError,
    showClearAll,
    setShowClearAll,
    disableApplyFilter,
    setDisableApplyFilter,
    loadMoreStatus,
    setLoadMorestatus,
    filterDataList,
    setFilterDataList,
    filterDataListRef,
    searchByList,
    setSearchByList,
    searchByListRef,
    activeInput,
    setActiveInput,
    assetCountStatus,
    setAssetCountStatus,
    scrollPosition,
    setScrollPosition,
    assetDataStatus,
    setAssetDataStatus,
  } = AssetListState();

  const { data, error, isLoading, isFetching, refetch } = useQuery(
    "getAssetListApi",
    () =>
      getAssetList({
        viewIndex: pageIndex.current,
        viewSize: pageSize,
        isLowDataMode: false,
        withLiveMeters: false,
        withCardMeters: true,
        isMonitoring: true,
        withAssetAttributes: true,
        searchBy: searchByListRef.current,
      }),
    {
      refetchInterval: pollInterval,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (fetchedData) => {
        if (typeof fetchedData === "number") {
          setShowError(true);
        }
        if (
          fetchedData.hasOwnProperty("responseMessage") &&
          fetchedData.responseMessage === "success" &&
          fetchedData.hasOwnProperty("assets") &&
          fetchedData.hasOwnProperty("pagination")
        ) {
          setShowLoading(false);
          setShowError(false);
          if (pageIndex.current == 1) {
            setAssetList(() => fetchedData.assets);
          } else if (
            pageIndex.current === nextPageIndex.current &&
            assetList.length !== fetchedData.pagination.totalCount
          ) {
            setAssetList((prevList) => [...prevList, ...fetchedData?.assets]);
          } else {
            setAssetList((prevList) => [
              ...prevList?.slice(0, 0 - fetchedData?.assets?.length),
              ...fetchedData?.assets,
            ]);
          }
          //Api dependency
          if (!R.isEmpty(fetchedData.assets)) {
            nextPageIndex.current = fetchedData.pagination.nextPage;
            totalPages.current = fetchedData.pagination.totalPage;
            setShowLoadMore(
              fetchedData.pagination.currentPage ===
                fetchedData.pagination.nextPage
            );
          } else {
            setShowLoadMore(true);
          }
          setLoadMorestatus(false);
        }
      },
      onError: () => {
        setShowLoading(false);
        setShowError(true);
        setLoadMorestatus(false);
      },
    }
  );

  const fetchDataAndUpdateState = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error("Error re-fetching data:", error);
    }
  };

  const getAssetFilterData = async () => {
    let tokenID = LocalStorageService.getTokenData();
    const services = new SettingsApiServices();
    const response = await services.FacilityStatistics(tokenID);

    if (
      !R.isEmpty(response) &&
      !(
        response.statusCode == "BAD_REQUEST" ||
        response.responseMessage == "error"
      )
    ) {
      const utils = new AssetFilterData();
      const newFilterList = utils.getAssetFilterData();
      setFilterDataList(newFilterList);
    }
  };

  const handleClearFilter = async () => {
    const searchKeyList = [
      getKeyForSelectedFilter["Asset Name"],
      getKeyForSelectedFilter["Asset Id"],
    ];
    let newSearchList = searchByList.filter((item: SearchListType) =>
      searchKeyList.includes(item.searchKey)
    );

    const utils = new FilterUtils();
    const unselectedData = utils.clearSelectedFilter(filterDataListRef.current);
    setFilterDataList([...unselectedData]);

    setSearchByList([...newSearchList]);

    cancelExistingQuery("getAssetListApi", queryClient);
    pageIndex.current = 1;
    nextPageIndex.current = 1;
    fetchDataAndUpdateState();
    setAssetList([]);
    setShowLoading(true);
    setShowClearAll(false);
  };

  const handleLoadMore = async () => {
    pageIndex.current = nextPageIndex.current;
    setLoadMorestatus(true);
    cancelExistingQuery("getAssetListApi", queryClient);
    fetchDataAndUpdateState();
  };

  const handleScroll = (e: SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement;
    setScrollPosition(target.scrollTop);
  };

  const debouncedOnDataZoom = debounce(handleScroll, 200);

  const searchSingleTypeFilterData = (value: string, index: number) => {
    const utils = new FilterUtils();
    const result = utils.searchSingleTypeFilterData(
      value,
      index,
      filterDataListRef.current
    );
    setFilterDataList([...result]);
  };

  const searchMultipleTypeFilterData = (value: string, index: number) => {
    const utils = new FilterUtils();
    const result = utils.searchMultipleTypeFilterData(
      value,
      index,
      filterDataListRef.current
    );
    setFilterDataList([...result]);
  };

  const handleSelectedFilter = (obj: FilterDataObj, index: number) => {
    setFilterDataList((prevFilterDataList) => {
      const tempFilterData = [...prevFilterDataList];
      const data = [...tempFilterData[index].data];
      const dataIndex = data.findIndex((item) => item.id === obj.id);
      data[dataIndex].isSelected = !data[dataIndex].isSelected;
      tempFilterData[index].data = data;
      const selectedCount = data.reduce(
        (count, item) => count + (item.isSelected ? 1 : 0),
        0
      );
      tempFilterData[index].selectedCount = selectedCount;
      return tempFilterData;
    });
  };

  const filterHierarchyData = (
    index: number,
    group: string,
    hierarchyList: string[]
  ) => {
    const utils = new FilterUtils();
    const response = utils.filterHierarchyData(
      index,
      group,
      hierarchyList,
      filterDataListRef.current
    );
    setFilterDataList(response);
  };

  const updateSearchList = () => {
    const utils = new FilterUtils();
    const newSearchList = utils.updateSearchList(
      filterDataListRef.current,
      searchText
    );
    setSearchByList([...newSearchList]);

    cancelExistingQuery("getAssetListApi", queryClient);
    pageIndex.current = 1;
    nextPageIndex.current = 1;
    fetchDataAndUpdateState();
    setAssetList([]);
    setShowLoading(true);
    setShowClearAll(true);
  };

  const handleFilterHeading = (index: number, group: string) => {
    const temp: FilterDataType[] = filterDataListRef.current;
    const selectedChildren: string[] = temp[index].selectedChildren;
    let newSelectedChildren: string[] = [];

    if (selectedChildren.includes(group)) {
      newSelectedChildren = selectedChildren.filter(
        (item: string) => item !== group
      );
    } else {
      newSelectedChildren = [...selectedChildren, group];
    }

    temp[index].selectedChildren = newSelectedChildren;
    setFilterDataList([...temp]);
  };

  /**
   * Check for the Apply Button Disable status.
   */
  useEffect(() => {
    const checkButtonStatus = () => {
      const utils = new FilterUtils();
      const status = utils.checkFilterButtonDisable(filterDataListRef.current);
      setDisableApplyFilter(!status);
    };
    checkButtonStatus();
  }, [filterDataListRef.current]);

  /**
   * Mount and Unmount the component.
   */
  useEffect(() => {
    setShowLoading(true);
    fetchDataAndUpdateState();
    getAssetFilterData();
    return () => cancelExistingQuery("getAssetListApi", queryClient);
  }, []);

  /**
   * Update the Asset Count based on the current polling state.
   */
  useEffect(() => {
    const updateAssetCount = () => {
      const assetCountData = {
        assetsLengthCount: assetList.length,
        showAssetCount: !showLoading,
        totalAssetCount: data?.assetCount,
      };
      setAssetCountStatus(assetCountData);
      setDisableApplyFilter(true);
    };
    updateAssetCount();
  }, [isFetching, assetList, showLoading, data]);

  /**
   * Whenever there is a change in the search box, triggrer the API.
   * Note: SearchText will be updated once user click the ENTER button or manually click the search icon.
   */
  useEffect(() => {
    if (searchText && searchText.length > 0) {
      const updateSearchKey = () => {
        const utils = new FilterUtils();
        const newSearchList = utils.updateSearchKey(
          searchText,
          searchByListRef.current
        );

        setSearchByList([...newSearchList]);

        cancelExistingQuery("getAssetListApi", queryClient);
        pageIndex.current = 1;
        nextPageIndex.current = 1;
        fetchDataAndUpdateState();
        setAssetList([]);
        setShowLoading(true);
      };

      updateSearchKey();
    }
  }, [searchText]);

  /**
   * Auto Scroll to the selected Asset Card, when user press back button from the Asset Detail Page.
   */
  useEffect(() => {
    if (currentPath === "/asset-list" && scrollRef) {
      scrollRef?.current?.scrollTo(0, scrollPosition);
    }
  }, [currentPath]);

  useEffect(() => {
    const updateAssetDataStatus = () => {
      const result = checkAssetDataStatus(
        isLoading,
        isFetching,
        showError,
        assetList?.length,
        showLoading,
        showLoadMore
      );
      setAssetDataStatus(result);
    };
    updateAssetDataStatus();
  }, [isLoading, isFetching, showError, assetList, showLoading, showLoadMore]);

  if (currentPath !== "/asset-list") {
    return <Outlet />;
  } else {
    return (
      <>
        {filterDataListRef.current.length !== 0 && (
          <div className="assetListFilterMainDiv">
            {assetCountStatus.showAssetCount && (
              <p className="assetCountStatusDiv">
                You are viewing {assetCountStatus.assetsLengthCount} asset(s) /{" "}
                {assetCountStatus.totalAssetCount} asset(s){" "}
              </p>
            )}
            <div className="assetFilterRow">
              <Col xs={1} md={1} lg={1}>
                <p className="assetFilterHeading">Filter by</p>
              </Col>
              <Row style={{ width: "90%", alignItems: "center" }}>
                {filterDataListRef.current.map(
                  (item: FilterDataType, index: number) => (
                    <Col
                      xs={0}
                      sm={6}
                      md={6}
                      lg={3}
                      style={{
                        padding: ".25rem",
                        height: "80%",
                        fontSize: "small",
                      }}
                    >
                      {item.dropdown === DropdownType.SINGLE ? (
                        <SingleTypeFilter
                          data={item}
                          index={index}
                          activeInput={activeInput}
                          searchSingleTypeFilterData={
                            searchSingleTypeFilterData
                          }
                          setActiveInput={setActiveInput}
                          handleSelectedFilter={handleSelectedFilter}
                          disabled={isLoading}
                        />
                      ) : (
                        <MultileTypeFilter
                          data={item}
                          index={index}
                          activeInput={activeInput}
                          setActiveInput={setActiveInput}
                          handleSelectedFilter={handleSelectedFilter}
                          filterHierarchyData={filterHierarchyData}
                          searchMultipleTypeFilterData={
                            searchMultipleTypeFilterData
                          }
                          handleFilterHeading={handleFilterHeading}
                          disabled={isLoading}
                        />
                      )}
                    </Col>
                  )
                )}
                <button
                  onClick={updateSearchList}
                  onMouseOver={() => setActiveInput("")}
                  disabled={disableApplyFilter}
                  className={`filterApplyButton ${
                    disableApplyFilter
                      ? "disableFilterApplyButton"
                      : "enableFilterApplyButton"
                  }`}
                >
                  Apply
                </button>
                {showClearAll && (
                  <p onClick={handleClearFilter} className="clearAllButton">
                    CLEAR ALL
                  </p>
                )}
              </Row>
            </div>
          </div>
        )}

        {assetDataStatus.showLoading && !assetDataStatus.showAsset && (
          <div className="loadingBox2">
            <LoaderStyle />
          </div>
        )}

        {assetDataStatus.showEmptyData && (
          <div className="loadingBox2">
            <DisplayError type={"err-empty"} />
          </div>
        )}

        {assetDataStatus.showError && (
          <div className="loadingBox2">
            <DisplayError type={"err-500/404"} />
          </div>
        )}

        {assetDataStatus.showAsset && (
          <div className="assetMainDiv">
            <Row
              className="assetMainRow"
              onMouseOver={() => setActiveInput("")}
              onScroll={debouncedOnDataZoom}
              ref={scrollRef}
            >
              {assetList.map((el: NewAssetData, index: number) => (
                <Col
                  xs={12}
                  md={6}
                  lg={3}
                  className="assetCardColumnClass"
                  key={index}
                >
                  <AssetCard data={el} key={index} />
                </Col>
              ))}
              {assetDataStatus.showLoadMoreButton && (
                <div className="loadMoreAssets">
                  <LoadMoreBtn
                    state={loadMoreStatus}
                    title="Load more"
                    loadingText={"Loading"}
                    onClickFunction={handleLoadMore}
                  />
                </div>
              )}
            </Row>
          </div>
        )}
      </>
    );
  }
}

export default AssetList;
