/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import React, { useState, useEffect } from "react";
import TempImg from "../../../assets/icons/TempImg.svg";
import CurrentImg from "../../../assets/icons/CurrentImg.svg";
import VoltageImg from "../../../assets/icons/VoltageImg.svg";
import PowerImg from "../../../assets/icons/PowerImg.svg";
import FrequencyImg from "../../../assets/icons/FrequencyImg.svg";
import ApparentPowerImg from "../../../assets/icons/ApparentPowerImg.svg";
import returnIcon from "../../../assets/icons/icon-return-air-temp.svg";
import defrost from "../../../assets/icons/icon-defrost.svg";

import * as R from "ramda";

import "./TempControllerLive.scss";

function TempControllerLive({ data, closeOpenBtn }: any) {
  const [reg, setReg] = useState();
  useEffect(() => {
    let reg = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "SET_REG"
    );
    setReg(reg);
  }, [data]);

  return (
    <div className="tempEnergyContainer">
      <div className="cardsRowCont">
        <div className="rowOne">
          {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
            data?.meters.map(
              (el, index) =>
                el.meterTypeId.includes("SUPPLY_TEMP_1") && (
                  <div className="card1Container" key={index}>
                    <p className="card1Heading">Supply temp 1</p>
                    <div className="card1ValuesCont">
                      <img src={TempImg} alt="icon" className="card1Icon" />
                      <p className="card1Values">
                        {Number(el?.meterValue).toFixed(2)}°{el.readingUomDesc}
                      </p>
                    </div>
                    <p className="card1Celcius">
                      <b>Set Point</b>{" "}
                      {!R.isEmpty(reg) && !R.isNil(reg) && reg.meterValue}°
                      {!R.isEmpty(reg) && !R.isNil(reg) && reg.readingUomDesc}
                    </p>
                  </div>
                )
            )
          ) : (
            <div className="card1Container">
              <p className="card1Heading">Supply temp 1</p>
              <div className="card1ValuesCont">
                <img src={TempImg} alt="icon" className="card1Icon" />
                <p className="card1Values">-</p>
              </div>
              <p className="card1Celcius"></p>
            </div>
          )}

          {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
            data?.meters.map(
              (el, index) =>
                el.meterTypeId.includes("SUPPLY_TEMP_2") && (
                  <div className="card1Container" key={index}>
                    <p className="card1Heading">Supply temp 2</p>
                    <div className="card1ValuesCont">
                      <img src={TempImg} alt="icon" className="card1Icon" />
                      <p className="card1Values">
                        {Number(el?.meterValue).toFixed(2)}°{el.readingUomDesc}
                      </p>
                    </div>
                    <p className="card1Celcius">
                      <b>Set Point</b>{" "}
                      {!R.isEmpty(reg) && !R.isNil(reg) && reg.meterValue}°
                      {!R.isEmpty(reg) && !R.isNil(reg) && reg.readingUomDesc}
                    </p>
                  </div>
                )
            )
          ) : (
            <div className="card1Container">
              <p className="card1Heading">Supply temp 2</p>
              <div className="card1ValuesCont">
                <img src={TempImg} alt="icon" className="card1Icon" />
                <p className="card1Values">-</p>
              </div>
              <p className="card1Celcius"></p>
            </div>
          )}

          {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
            data?.meters.map(
              (el, index) =>
                el.meterTypeId.includes("RET_TEMP_1") && (
                  <div className="card1Container" key={index}>
                    <p className="card1Heading">Return Air Temp 1</p>
                    <div className="card1ValuesCont">
                      <img src={returnIcon} alt="icon" className="card1Icon" />
                      <p className="card1Values">
                        {Number(el?.meterValue).toFixed(2)}°{el.readingUomDesc}
                      </p>
                    </div>
                    <p className="card1Celcius">
                      <b>Set Point</b>{" "}
                      {!R.isEmpty(reg) && !R.isNil(reg) && reg.meterValue}°
                      {!R.isEmpty(reg) && !R.isNil(reg) && reg.readingUomDesc}
                    </p>
                  </div>
                )
            )
          ) : (
            <div className="card1Container">
              <p className="card1Heading">Return Air Temp 1</p>
              <div className="card1ValuesCont">
                <img src={returnIcon} alt="icon" className="card1Icon" />
                <p className="card1Values">-</p>
              </div>
              <p className="card1Celcius"></p>
            </div>
          )}
        </div>
        {closeOpenBtn && (
          <div className="rowTwo">
            {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
              data?.meters.map(
                (el, index) =>
                  el.meterTypeId.includes("DEFROST_TIME") && (
                    <>
                      <div key={index} className="card5Cont">
                        <p className="card5Heading">Time to Defrost</p>
                        <div className="card5ValuesCont">
                          <img src={defrost} alt="icon" className="card5Icon" />
                          <p className="card5Values">
                            {Number(el?.meterValue).toFixed(2)}{" "}
                            {el.readingUomDesc}
                          </p>
                        </div>
                      </div>
                    </>
                  )
              )
            ) : (
              <>
                <div className="card5Cont">
                  <p className="card5Heading">Time to Defrost</p>
                  <div className="card5ValuesCont">
                    <img src={defrost} alt="icon" className="card5Icon" />
                    <p className="card5Values">-</p>
                  </div>
                </div>
              </>
            )}

            {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
              data?.meters.map(
                (el, index) =>
                  el.meterTypeId.includes("DEFROST_STATUS") && (
                    <>
                      <div key={index} className="card5Cont">
                        <p className="card5Heading">Defrost Status</p>
                        <div className="card5ValuesCont">
                          <p className="card5Values">
                            {el.meterValue === "N" ? "FALSE" : "TRUE"}
                          </p>
                        </div>
                      </div>
                    </>
                  )
              )
            ) : (
              <>
                <div className="card5Cont">
                  <p className="card5Heading">Defrost Status</p>
                  <div className="card5ValuesCont">
                    <p className="card5Values">-</p>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TempControllerLive;
