/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";

import TempImg from "../../../assets/icons/TempImg.svg";
import "./ADTemp.scss";
import * as R from "ramda";
function ADTemp({ data }:any) {
  // console.log("thes kellp kit", data);
  return (
    <>
      {!R.isEmpty(data?.meters) && !R.isNil(data?.meters) ? (
        data?.meters.map(
          (el:any, index:any) =>
            el.meterTypeId.includes("TEMP") && (
              <div className="tempContainer">
                <p className="cardHeading">Temperature</p>
                <div className="cardValuesCont">
                  <img src={TempImg} alt="icon" className="cardIcon" />
                  <p
                    style={{
                      color: el.compliance === false ? "red" : "#000000",
                    }}
                    className="card1Values"
                  >
                    {Number(el?.meterValue).toFixed(2)}°C
                  </p>
                </div>
                <p className="cardCelcius">
                  Min {data?.temp_min?.value}°{data?.temp_min?.abbreviation} -
                  Max {data?.temp_max?.value}°{data?.temp_max?.abbreviation}
                </p>
              </div>
            )
        )
      ) : (
        <div className="tempContainer">
          <p className="cardHeading">Temperature</p>
          <div className="cardValuesCont">
            <img src={TempImg} alt="icon" className="cardIcon" />
            <p className="cardValues">-</p>
          </div>
          <p className="cardCelcius">Min - °C - Max - °C</p>
        </div>
      )}
      {/* // <div className="tempContainer">
    //   <p className="cardHeading">Temperature</p>
    //   <div className="cardValuesCont">
    //     <img src={TempImg} alt="icon" className="cardIcon" />
    //     <p className="cardValues">20.00 °C</p>
    //   </div>
    //   <p className="cardCelcius">
    //     Min 4 °C - Max 6 °C
    //   </p>
    // </div> */}
    </>
  );
}

export default ADTemp;
