import { useEffect } from "react";
import useState from "react-usestateref";
import Vector from "../../assets/icons/Vector.svg";
import downloadicon from "../../assets/icons/download-icon.svg";
import helpicon from "../../assets/icons/help-icon.svg";
import screenshot from '../../assets/icons/icon-capture-screenshot.svg';
import settingsIcon from "../../assets/icons/settings.svg";
import { useStateValue } from "../../redux/StateProvider";
// import "../../App_one.scss";
import FileSaver from "file-saver";
import moment from "moment";
import * as R from "ramda";
import ReactExport from "react-export-excel";
import * as XLSX from "xlsx";
import DownloadReportUtil from "../../Utils/DownloadReportUtil";
import expandComponentImg from "../../assets/icons/expandComponetImg.svg"
import collapseComponentImg from "../../assets/icons/collapseComponentImg.svg"
import "./index.scss";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type CardProps = {
  children: any;
  headerLabel: any;
  footerLabel: any;
  footerLink: any;
  infoTip: any;
  themeColors: any;
  typeTwo: any;
  downloadData: any;
  filter: any;
  dgData: any;
  settings:any;
  settingsCallBack:any;
  clickScrrenshot:any;
  screenImg:any
  energyConsumptionFilter : Function;
  width : string;
  showFullScreenICon:boolean;
  componentId : string;
  onClickMixedSizedComponents:any;
};

function CardWrapper(props: CardProps) {
  let {onClickMixedSizedComponents, componentId, width,showFullScreenICon, children, headerLabel, footerLabel, infoTip, themeColors, downloadData, typeTwo, filter, energyConsumptionFilter, dgData, screenImg, clickScrrenshot, settingsCallBack, settings } = props
  const [
    { darkThemeEnabled, triggerDownload, graphData, dwnLoadData, dateRange },
    dispatch,
  ] = useStateValue();
  const isDark = darkThemeEnabled;
  const headingColor = isDark
    ? { color: themeColors?.headingColor, fontSize: "10px" }
    : {};
  const bgColor = isDark ? { backgroundColor: themeColors?.cardBg } : {};
  const textColor = isDark ? { color: themeColors?.textCopy } : {};
  const ctaIcon: any = isDark
    ? {
        backgroundColor: themeColors?.ctaIconBg,
        borderColor: themeColors?.ctaIconBorder,
        width: "2vw",
        textAlign: "center",
      }
    : {
        backgroundColor: "#fff",
        borderColor: "#fff",
        width: "2vw",
        textAlign: "center",
      };

  //  const [sheetArr, setSheetArr] = useState([]);
  const [excelDataSet, setExcelDataSet, excelDataSetRef] = useState<any>([]);

  const [dwnLoadDataLoc, setDwnLoadDataLoc, dwnLoadDataLocRef] = useState<any>(
    {}
  );
  const [cardDwnLoadDataLoc, setCardDwnLoadDataLoc, cardDwnLoadDataLocRef] =
    useState<any>({});
  const [dgGraphData, setDgGraphData] = useState();
  const [excelLoaded, setExcelLoaded] = useState(false);

  const [dwnLoadTriggerCount, setDwnLoadTriggerCount, dwnLoadTriggerCountRef] =
    useState(0);

  useEffect(() => {
    setDwnLoadDataLoc(dwnLoadData);
  }, [dwnLoadData]);

  useEffect(() => {
    setCardDwnLoadDataLoc(downloadData);
  }, [downloadData]);

  useEffect(() => {
    setDgGraphData(dgData);
  }, [dgData]);

  useEffect(() => {
    if (headerLabel === "Asset Summary") {
      // const titleColor = 'FF0000'; // Red color
      //  console.log("dwnLoadData is::", dwnLoadData,headerLabel);
      // // alert("Currently no Download")
      //   downloadxlsx([dwnLoadData?.AssetSummary], titleColor);
    } else {
      headerLabel = headerLabel === "Diesel Generator" ? "DG" : headerLabel;
      headerLabel =
        headerLabel === "Compliance Summary" ? "Asset Summary" : headerLabel;
      // console.log("headerLabel is::", headerLabel);
      if (
        dwnLoadDataLoc.hasOwnProperty(headerLabel) &&
        dwnLoadDataLoc[headerLabel] !== undefined &&
        dwnLoadDataLoc[headerLabel].all !== null &&
        headerLabel !== "Tickets"
      ) {
        const sheetData = dwnLoadDataLoc[headerLabel];
        const sheetFac = sheetData?.facilities;
        const allData = sheetData?.all;
        // console.log("sheetData is::", sheetData);
        // console.log("sheetFac is::", sheetFac);
        if (!R.isEmpty(sheetFac) && !R.isNil(sheetFac)) {
          const dwnRepUtil = new DownloadReportUtil();
          console.log("sheetFac is::", dwnRepUtil);
          const excelDataSet = dwnRepUtil.getSheetArr(
            allData,
            sheetFac,
            headerLabel
          );

          // console.log("excelDataSet::", excelDataSet);

          setExcelLoaded(true);
          setExcelDataSet(excelDataSet);
        }
      } else {
        // console.log("We're here");
        // console.log("Report not available yet");
      }
    }
  }, [dwnLoadDataLoc]);

  //for asset summary download
  const downloadxlsx = (data: any, rowColor: any) => {
    if (!R.isEmpty(data) && !R.isNil(data)) {
      const worksheet: any = XLSX.utils.json_to_sheet(data);

      // Add border to cells
      const range: any = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })];
          if (cell && cell.t === "s") {
            cell.s = {
              border: {
                top: { style: "thin", color: { rgb: "FF000000" } },
                right: { style: "thin", color: { rgb: "FF000000" } },
                bottom: { style: "thin", color: { rgb: "FF000000" } },
                left: { style: "thin", color: { rgb: "FF000000" } },
              },
            };
          }
        }
      }

      // Add custom style for row
      const rowStyle = {
        fill: { fgColor: { rgb: rowColor } },
      };
      data.forEach((row: any, index: any) => {
        const rowIndex = index + 2; // skip header row
        Object.keys(row).forEach((key, colIndex) => {
          const cellAddress = XLSX.utils.encode_cell({
            r: rowIndex,
            c: colIndex,
          });
          const cell = worksheet[cellAddress];
          if (cell && cell.t === "s") {
            cell.s = {
              border: {
                top: { style: "thin", color: { rgb: "FF000000" } },
                right: { style: "thin", color: { rgb: "FF000000" } },
                bottom: { style: "thin", color: { rgb: "FF000000" } },
                left: { style: "thin", color: { rgb: "FF000000" } },
              },
              ...rowStyle,
            };
          }
        });
      });

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });
      const excelBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      FileSaver.saveAs(excelBlob, "data.xlsx");
    }
  };

  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  const getGraphData = async () => {
    let date_Range_Start = moment(dateRange[0]).format("l");
    let date_Range_End = moment(dateRange[1]).format("l");
    const getValue = (obj: any) => obj.compliancePercent;
    let wb = XLSX.utils.book_new();
    if (
      typeTwo &&
      !R.isEmpty(cardDwnLoadDataLoc) &&
      !R.isNil(cardDwnLoadDataLoc)
    ) {
      if (headerLabel === "Compliance Summary") {
        let top5: any;
        let fiveElements: any;
        let compData: any;
        console.log("cardDwnLoadDataLoc", cardDwnLoadDataLoc);
        if (cardDwnLoadDataLoc?.complianceSummaryDataTwo.complianceSummary !== "loading") {
          compData =
            cardDwnLoadDataLoc?.complianceSummaryData?.complianceSummary[0]?.data.map(
              (obj: any) => {
                return {
                  Facility: obj.facilityName,
                  "Compliance (%)": obj.compliancePercent,
                };
              }
            );

          const sortedData = cardDwnLoadDataLoc?.complianceSummaryDataTwo.complianceSummary.sort(
            (a: any, b: any) =>
              parseFloat(b.compliancePercent) - parseFloat(a.compliancePercent)
          );
          // console.log("the cardWrapper data", sortedData);
          top5 = sortedData.slice(0, 5);
          fiveElements = [0, 1, 2, 3, 4].map((ind: any) => {
            return {
              "Top five compliant assets": sortedData[ind]?.fixedAssetName,
              "Compliance (%)": sortedData[ind]?.compliancePercent,
              "Bottom five compliant assets":
                sortedData[sortedData.length - 1 - ind]?.fixedAssetName,
              "Compliance(%)":
                sortedData[sortedData.length - 1 - ind]?.compliancePercent,
            };
          });
        } else {
          fiveElements = [];
          compData = [];
        }

        //console.log("the cardWrapper data", fiveElements);
        // const excel = new ReactExport.ExcelFile();

        // const sheet = excel.addSheet('Sheet 1');

        // excel.saveAs('example.xlsx');
        // const sheet = XLSX.utils.json_to_sheet(compData);

        const sheet1 = XLSX.utils.json_to_sheet(fiveElements);
        const wb = XLSX.utils.book_new();
        const sheet = XLSX.utils.json_to_sheet(compData);
        XLSX.utils.book_append_sheet(
          wb,
          sheet,
          "Temperature compliance summary"
        );
        XLSX.utils.book_append_sheet(wb, sheet1, "Top and Bottom 5 assets");
        const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
        const arrayBuffer = s2ab(wbout);
        XLSX.utils.book_append_sheet(wb, sheet, "Data");
        const blob = new Blob([arrayBuffer], {
          type: "application/octet-stream",
        });
        // Create a download link and set its attributes
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = `Compliancesummary${date_Range_Start}-${date_Range_End}.xlsx`;
        // Append the download link to the document body
        document.body.appendChild(downloadLink);
        // Trigger a click event on the download link
        downloadLink.click();
        // Remove t
        // Remove the download link from the document body
        document.body.removeChild(downloadLink);
      } else if (headerLabel === "Tickets") {
        // console.log("the cardWrapper data", cardDwnLoadDataLoc);
        const ticketData =
          cardDwnLoadDataLoc && cardDwnLoadDataLoc !== "loading"
            ? cardDwnLoadDataLoc?.anoviTicketsDu?.all.map((obj: any) => {
                return {
                  Categories: obj.fixedAssetType,
                  Count: obj.count,
                  Open: obj.openTickets,
                  Closed: obj.closedTickets,
                  Critical: obj.criticalTicketsCount,
                  OverDue: obj.overDueTicketsCount,
                  "Critical OverDue": obj.criticalAndOverDueTicketsCount,
                  MTTR: `${obj.mttr.value} ${obj.mttr.abbreviation}`,
                  "MTTR-High priority": `${obj.mttrHigh.value} ${obj.mttrHigh.abbreviation}`,
                  "MTTR-Low priority": `${obj.mttrLow.value} ${obj.mttrLow.abbreviation}`,
                  "MTTR-Medium priority": `${obj.mttrMedium.value} ${obj.mttrMedium.abbreviation}`,
                };
              })
            : [];

        const trendData =
          cardDwnLoadDataLoc && cardDwnLoadDataLoc !== "loading"
            ? cardDwnLoadDataLoc?.anoviTicketsDuTwo?.ticketCount
            : [];
        // Extracting date range from the JSON keys
        const dates = trendData?.OpenCount
          ? trendData?.OpenCount.map((item: any) => item.Name)
          : [];
        const openC = trendData?.OpenCount
          ? trendData?.OpenCount.map((item: any) => item.Count)
          : [];
        const closedC = trendData?.closedCount
          ? trendData?.closedCount.map((item: any) => item.Count)
          : [];
        // Object.keys(trendData?.OpenCount?.Name);
        // Creating an array to hold the Excel data
        const excelData = [];
        // Adding the headers row
        excelData.push(["", ...dates]);
        // Adding the Open and Closed rows
        excelData.push(["Open", ...openC.map((date: any) => date || 0)]);
        excelData.push(["Closed", ...closedC.map((date: any) => date || 0)]);
        //console.log("the cardWrapper data", excelData);
        // Generating the Excel sheet as a string
        // let sheetString = "";
        // excelData.forEach((row) => {
        //   sheetString += row.join("\t") + "\n";
        // });
        const sheet: any = XLSX.utils.json_to_sheet(ticketData);
        const sheet1: any = XLSX.utils.json_to_sheet(excelData);

        const sheet1Data: any = XLSX.utils
          .sheet_to_json(sheet1, { header: 1 })
          .slice(1);
        const sheet1WithoutHeader: any = XLSX.utils.aoa_to_sheet(sheet1Data);

        // Add the modified sheet to the workbook

        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, sheet, "Overall");
        XLSX.utils.book_append_sheet(wb, sheet1WithoutHeader, "Overall trend");
        const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
        const arrayBuffer = s2ab(wbout);

        const blob = new Blob([arrayBuffer], {
          type: "application/octet-stream",
        });
        // Create a download link and set its attributes
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `Overall${date_Range_Start}-${date_Range_End}.xlsx`;
        // Append the download link to the document body
        document.body.appendChild(downloadLink);
        // Trigger a click event on the download link
        downloadLink.click();
        // Remove t
        // Remove the download link from the document body
        document.body.removeChild(downloadLink);
      } else if (headerLabel === "Asset Summary") {
        console.log("the cardWrapper data", cardDwnLoadDataLoc);
        if (cardDwnLoadDataLoc) {
          let compData = cardDwnLoadDataLoc;

          let dataSheet = [
            {
              Totalassets: compData?.Total,
              Active: compData?.Active,
              Inactive: compData?.Inactive,
              Lost: compData?.Lost,
              Maintenance: compData?.Maintenance,
              Offline: compData?.Offline,
              Online: compData?.Online,
              Slow: compData?.Slow,
            },
          ];
          //console.log("the cardWrapper data", dataSheet);
          const sheet = XLSX.utils.json_to_sheet(dataSheet);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, sheet, "Asset Status");
          const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
          const arrayBuffer = s2ab(wbout);
          const blob = new Blob([arrayBuffer], {
            type: "application/octet-stream",
          });
          // Create a download link and set its attributes
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = `Assetstatus${date_Range_Start}-${date_Range_End}.xlsx`;
          // Append the download link to the document body
          document.body.appendChild(downloadLink);
          // Trigger a click event on the download link
          downloadLink.click();
          // Remove t
          // Remove the download link from the document body
          document.body.removeChild(downloadLink);
        }
      } else if (headerLabel === "Diesel Generator") {
        if (cardDwnLoadDataLoc && dgGraphData) {
          console.log(
            "the DG value",
            cardDwnLoadDataLoc?.carouselData[0]?.carouselBody,
            dgGraphData
          );
          const preparedData =
            cardDwnLoadDataLoc?.carouselData[0]?.carouselBody.map(
              (item: any) => ({
                Title: item.title,
                Value: item.value,
              })
            );
          const DGSummary = dgGraphData?.dgSummary?.energyGenerated?.map(
            (item: any) => ({
              Range: item.range,
              RangeValue: item.rangeValue,
              sum: item.sum,
            })
          );
          const runHrSummary = dgGraphData?.dgSummary?.runHr?.map(
            (item: any) => ({
              Range: item.range,
              RangeValue: item.rangeValue,
              sum: item.sum,
            })
          );
          //console.log("the cardWrapper data", DGSummary,runHrSummary);
          const sheet = XLSX.utils.json_to_sheet(preparedData);
          const sheet2 = XLSX.utils.json_to_sheet(DGSummary);
          const sheet3 = XLSX.utils.json_to_sheet(runHrSummary);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, sheet, "Diesel Generator");
          XLSX.utils.book_append_sheet(wb, sheet2, "Energy Generated");
          XLSX.utils.book_append_sheet(wb, sheet3, "runHr");
          const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
          const arrayBuffer = s2ab(wbout);
          const blob = new Blob([arrayBuffer], {
            type: "application/octet-stream",
          });
          // Create a download link and set its attributes
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = `DieselGenerator${date_Range_Start}-${date_Range_End}.xlsx`;
          // Append the download link to the document body
          document.body.appendChild(downloadLink);
          // Trigger a click event on the download link
          downloadLink.click();
          // Remove t
          // Remove the download link from the document body
          document.body.removeChild(downloadLink);
        }
      } else if (headerLabel === "Alerts") {
        if (cardDwnLoadDataLoc) {
          let compData = cardDwnLoadDataLoc?.alertDummyData;
          //console.log("the cardWrapper data", compData);

          let dataSheet = [
            {
              close: compData?.close,
              open: compData?.open,
              totalAlerts: compData?.totalAlerts,
              resolution: compData?.resolution,
              mttr: compData.mttr,
            },
          ];
          //console.log("the cardWrapper data", dataSheet);
          const sheet = XLSX.utils.json_to_sheet(dataSheet);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, sheet, "Alert Summary");
          const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
          const arrayBuffer = s2ab(wbout);
          const blob = new Blob([arrayBuffer], {
            type: "application/octet-stream",
          });
          // Create a download link and set its attributes
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = `Alertsummary${date_Range_Start}-${date_Range_End}.xlsx`;
          // Append the download link to the document body
          document.body.appendChild(downloadLink);
          // Trigger a click event on the download link
          downloadLink.click();
          // Remove t
          // Remove the download link from the document body
          document.body.removeChild(downloadLink);
        }
      }
    } else {
      headerLabel = headerLabel === "Diesel Generator" ? "DG" : headerLabel;
      headerLabel =
        headerLabel === "Compliance Summary" ? "Asset Summary" : headerLabel;
      if (
        dwnLoadDataLocRef.current.hasOwnProperty(headerLabel) &&
        dwnLoadDataLocRef.current[headerLabel] !== undefined &&
        dwnLoadDataLocRef.current[headerLabel].all !== null &&
        headerLabel !== "Tickets"
      ) {
        const sheetData = dwnLoadDataLocRef.current[headerLabel];
        const sheetFac = sheetData?.facilities;
        const allData = sheetData?.all;

        if (!R.isEmpty(sheetFac) && !R.isNil(sheetFac)) {
          const dwnRepUtil = new DownloadReportUtil();

          const excelDataSet = dwnRepUtil.getSheetArr(
            allData,
            sheetFac,
            headerLabel
          );

          setExcelLoaded(true);
          setExcelDataSet(excelDataSet);

          // if (dwnLoadTriggerCountRef.current < 2) {
          //   getGraphData();

          //   setDwnLoadDataLoc(dwnLoadTriggerCountRef.current + 1);
          // } else {
          //   setDwnLoadDataLoc(0);
          // }
        }
      } else {
        // console.log("Report not available yet");
      }
    }
  };

  return (
    <div className={"cardWrapper"} style={{width:width}} >
      <div className="cardWrapper-header">
        <h2 style={headingColor}>{headerLabel}</h2>
        <div className="icon_Placement">
          {downloadData && <div className="ctaIcons">
            <ExcelFile
              element={
                <div onClick={() => getGraphData()} style={ctaIcon}>
                  <img
                    src={downloadicon}
                    style={{
                      color: isDark ? "#495275" : "#182F43",
                      width: "15px",
                      padding: "2px",
                    }}
                  />
                </div>
              }
              filename={`${headerLabel}_Report`}
            >
              {excelLoaded &&
                excelDataSetRef.current.map((sheetObj: any, index: any) => (
                  <ExcelSheet
                    key={index}
                    data={sheetObj.rowArr}
                    name={sheetObj.sheetName}
                  >
                    {sheetObj.columnArr.map((ele: any, index: any) => (
                      <ExcelColumn
                        key={index}
                        label={ele.label}
                        value={ele.value}
                      />
                    ))}
                  </ExcelSheet>
                ))}
            </ExcelFile>
          </div>}
          {
            settings ? 
          
          <div className="ctaIcons" onClick={() => settingsCallBack(componentId)}>
            <img
              style={{
                color: isDark ? "#495275" : "#182F43",
                width: "15px",
                padding: "2px",
                cursor: "pointer",
              }}
              src={settingsIcon}
            />
          </div> : null
}

          {filter || energyConsumptionFilter ? (
            <div className="ctaIcons" onClick={() =>{ filter ? filter() : energyConsumptionFilter()}}>
              <img
                style={{
                  color: isDark ? "#495275" : "#182F43",
                  width: "15px",
                  padding: "2px",
                }}
                src={Vector}
              />
            </div>
          ) : null}
          {showFullScreenICon ? (
            <div className="ctaIcons" 
            onClick={() => onClickMixedSizedComponents(componentId)}
            >
              <img
                style={{
                  color: isDark ? "#495275" : "#182F43",
                  width: "20px",
                  padding: "0px",
                }}
                src={ width === '49%' ? expandComponentImg : collapseComponentImg}
              />
            </div>
          ) : null}

           {screenImg ? (
            <div className="ctaIcons" onClick={() => clickScrrenshot()}>
              <img
                style={{
                  color: isDark ? "#495275" : "#182F43",
                  width: "15px",
                  padding: "2px",
                }}
                src={screenshot}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="cardWrapper-body" style={bgColor}>
        {infoTip && (
          <div
            className="info-tip"
            style={{ borderColor: isDark ? "#495275" : "#182F43" }}
          >
            {infoTip && (
              <img
                src={helpicon}
                style={{
                  color: isDark ? "#495275" : "#182F43",
                  padding: "8px 12px",
                  width: "48px",
                }}
              />
            )}
            {infoTip && (
              <div className="tooltip-content">
                <p>{infoTip}</p>
              </div>
            )}
          </div>
        )}

        <div className="cardWrapper-children"> {children}</div>
        {/* <div className="cardWrapper-footer" style={bgColor}>
                    <p style={textColor}>{footerLabel} <a href="">Learn more
                    <FontAwesomeIcon className="footer-icon" icon = {faAngleRight}/></a> </p>
                    {/* <a href={footerLink} style={headingColor}>Learn more</a> }
                </div> */}
      </div>
    </div>
  );
}

export default CardWrapper;
