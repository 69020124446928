import { useState } from "react";
//import useState from "react-usestateref";
import "./FilterChipsDrop.scss";
import VectorClose from "../../../assets/icons/VectorClose.svg";
import { useStateValue } from "../../../redux/StateProvider";
import moment from "moment";
import * as R from "ramda";
import { API_BASE_URL } from "../../../Constants/constants";

function AnoviFilterChipDropComp({
  data,
  callbackData,
  callBackname,
  apiStatusOnLoading,
  apiStatusOnDone,
  callBacktype,
  callBackByFacilityType,
  callBackDefaultNames
}: any) {
  const [{ dateRange }] = useStateValue();
  const [block, setBlocks] = useState<any>({ ...data });
  const [drop2, setDrop2] = useState<any>({});
  const [drop3, setDrop3] = useState<any>({});
  const [drop4, setDrop4] = useState<any>({});
  const [drop5, setDrop5] = useState<any>({});
  const [temp, setTemp] = useState<any>({ ...data });
  const [chipVal, setChipVal] = useState([]);

  const getAPiDropData = async (filterByType: any, filterById: any) => {
    apiStatusOnLoading();
    const deviceId = localStorage.getItem("assetId");
    const token_id: any = localStorage.getItem("@tokenID");

    let frmDate = moment(dateRange[0]).valueOf();
    let endDate = moment(dateRange[1]).valueOf();
    const url = `${API_BASE_URL}assets/complianceAndNcData?token=${token_id}&filterByType=${filterByType}&filterById=${filterById}&startDate=${frmDate}&endDate=${endDate}&pageSize=5&pageIndex=1`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return {};
      }
    } catch {
      return {};
    }
  };

  const [firstValue, setFirstValue] = useState("city");
  const [secondValue, setSecondValue] = useState("");
  const [thirdValue, setThirdValue] = useState("");
  const [fourthValue, setFourthValue] = useState("");
  const [fifthValue, setFifthValue] = useState("");

  const handledrop1 = async (e: any) => {
    let filterByType = "";
    let filterById = "";
    let idSet = block.data.find((ele: any) => ele.name === e);
    if (idSet === undefined) {
      setDrop2({});
    } else {
      filterByType = idSet.type;
      filterById = idSet.id;
      let lists: any = [];
      lists.push(idSet.name);
      setSecondValue(idSet.name);
      setThirdValue("");
      setFourthValue("");
      setFifthValue("");
      setChipVal(lists);
      callBackname(idSet.name);
      setDrop2({});
      setDrop3({});
      setDrop4({});
      let list: any = await getAPiDropData(filterByType, filterById);
      if (R.isEmpty(list)) {
        apiStatusOnDone();
      } else {
        let data: any = await list.complianceSummary;
        let chartType = data.all[0].name === "fixedAsset" ? true : false;
        let failityName = chartType ? "Asset" : data.all[0].type;
        callBackByFacilityType(failityName);
        callBacktype(chartType);
        setDrop2(chartType ? {} : data);
        callbackData(data);
        apiStatusOnDone();
      }
    }
  };

  const handledrop2 = async (e: any) => {
    let filterByType = "";
    let filterById = "";
    let idSet: any = drop2.data.find((ele: any) => ele.name === e);
    if (idSet === undefined) {
      setDrop3({});
    } else {
      filterByType = idSet.type;
      filterById = idSet.id;
      let lists: any = [chipVal[0]];
      lists.push(idSet.name);
      setFourthValue("");
      setFifthValue("");
      setThirdValue(idSet.name);
      setChipVal(lists);
      callBackname(idSet.name);
      setDrop3({});
      setDrop4({});
      let list: any = await getAPiDropData(filterByType, filterById);
      if (R.isEmpty(list)) {
        apiStatusOnDone();
      } else {
        let data: any = await list.complianceSummary;
        let chartType = data.all[0].name === "fixedAsset" ? true : false;
        let failityName = chartType ? "Asset" : data.all[0].type;
        callBackByFacilityType(failityName);
        callBacktype(chartType);
        setDrop3(chartType ? {} : data);
        callBacktype(false);
        callbackData(data);
        //setDrop3(data);
        apiStatusOnDone();
      }
    }
  };

  const handledrop3 = async (e: any) => {
    let filterByType = "";
    let filterById = "";
    let idSet: any = drop3.data.find((ele: any) => ele.name === e);
    if (idSet === undefined) {
      setDrop4({});
    } else {
      filterByType = idSet.type;
      filterById = idSet.id;
      let lists: any = [chipVal[0], chipVal[1]];
      lists.push(idSet.name);
      setFourthValue(idSet.name);
      setFifthValue("");
      setChipVal(lists);
      callBackname(idSet.name);
      setDrop4({});
      setDrop5({});
      let list: any = await getAPiDropData(filterByType, filterById);
      if (R.isEmpty(list)) {
        apiStatusOnDone();
      } else {
        let data: any = await list.complianceSummary;
        let chartType = data.all[0].name === "fixedAsset" ? true : false;
        let failityName = chartType ? "Asset" : data.all[0].type;
        callBackByFacilityType(failityName);
        callBacktype(chartType);
        setDrop4(chartType ? {} : data);
        callBacktype(true);
        callbackData(data);
        //setDrop4(data);
        apiStatusOnDone();
      }
    }
  };

  const handledrop4 = async (e: any) => {
    let filterByType = "";
    let filterById = "";
    let idSet: any = drop4.data.find((ele: any) => ele.name === e);
    if (idSet === undefined) {
      setDrop5({});
    } else {
      filterByType = idSet.type;
      filterById = idSet.id;
      let lists: any = [chipVal[0], chipVal[1], chipVal[2]];
      lists.push(idSet.name);
      setChipVal(lists);
      callBackname(idSet.name);
      setFifthValue(idSet.name);
      let list: any = await getAPiDropData(filterByType, filterById);
      if (R.isEmpty(list)) {
        apiStatusOnDone();
      } else {
        let data: any = await list.complianceSummary;
        let chartType = data.all[0].name === "fixedAsset" ? true : false;
        let failityName = chartType ? "Asset" : data.all[0].type;
        callBackByFacilityType(failityName);
        callBacktype(chartType);
        setDrop5(chartType ? {} : data);
        callBacktype(true);
        callbackData(data);
        setDrop5(data);
        apiStatusOnDone();
      }
    }
  };

  const onClickRemoveElement = (index: any) => {
    let dropDown = [...chipVal];
    if (index === 0) {
      callBacktype(false);
      setSecondValue("");
      callbackData(block);
      callBackDefaultNames();
      setChipVal([]);
      setDrop2({});
      setDrop3({});
      setDrop4({});
      setDrop5({});
    } else if (index === 1) {
      dropDown.splice(1, chipVal.length);
      callBackname(chipVal[0]);
      callBackByFacilityType(drop2.all[0].name);
      callBacktype(false);
      callbackData(drop2);
      //setDrop2({});
      setDrop3({});
      setDrop4({});
      setDrop5({});
      setThirdValue("");
      setChipVal(dropDown);
    } else if (index === 2) {
      dropDown.splice(2, chipVal.length);
      callBackname(chipVal[1]);
      setFourthValue("");
      callBacktype(false);
      callbackData(drop3);
      callBackByFacilityType(drop3.all[0].name)
      //setDrop3({});
      setDrop4({});
      setDrop5({});
      setChipVal(dropDown);
    } else if (index === 3) {
      dropDown.splice(3, chipVal.length);
      callBackname(chipVal[2]);
      callBacktype(false);
      callbackData(drop4);
      callBackByFacilityType(drop4.all[0].name)
      setFifthValue("");
      //setDrop4({});
      setDrop5({});
      setChipVal(dropDown);
    } else {
      dropDown.splice(4, chipVal.length + 1);
      callBackname(chipVal[3]);
      callbackData(drop5);
      callBackByFacilityType(drop5.all[0].name)
      //setDrop5({});
      setChipVal(dropDown);
    }
  };

  return (
    <div className="FilterChips_scss">
      <div className="seletedList">
        {chipVal.map((ele: any, index: any) => {
          return (
            <div key={index} className="compare">
              <h4 style={{ cursor: "context-menu" }}>
                {ele}
                <span>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickRemoveElement(index)}
                    className="vectorX"
                    src={VectorClose}
                  />
                </span>
              </h4>
            </div>
          );
        })}
      </div>
      <div>
        {!R.isEmpty(block) ? (
          secondValue === "" ? (
            <select
              className="selectDropDownBlock"
              style={{
                borderRadius: "50px",
                WebkitAppearance: "none",
                MozAppearance: "none",
                appearance: "none",
                backgroundImage: "none",
                /// textAlign: "center",
                paddingLeft: "5px",
                borderColor: "#843939",
                borderWidth: "1px",
              }}
              id="name"
              onChange={(e) => {
                handledrop1(e.target.value);
              }}
              value={`select ${block.all[0].name}`}
            >
              {secondValue === "" && (
                <option>select {block.all[0].name}</option>
              )}
              {block.data.map((item: any) => (
                <option
                  style={{ alignItems: "start" }}
                  key={item.id}
                  value={item.name}
                >
                  {item.name}
                </option>
              ))}
            </select>
          ) : (
            <select
              className="selectDropDownBlock"
              id="name"
              onChange={(e) => {
                handledrop1(e.target.value);
              }}
              value={secondValue}
            >
              {block.data.map((item: any) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          )
        ) : null}

        {/* 2nd dropDown */}
        {!R.isEmpty(drop2) ? (
          thirdValue === "" ? (
            <select
              className="selectDropDownBlock"
              style={{
                borderRadius: "50px",
                WebkitAppearance: "none",
                MozAppearance: "none",
                appearance: "none",
                backgroundImage: "none",
                //textAlign: "center",
                paddingLeft: "5px",
                borderColor: "#843939",
                borderWidth: "1px",
              }}
              id="name"
              onChange={(e) => {
                handledrop2(e.target.value);
              }}
              value={`select ${drop2.all[0].name}`}
            >
              {thirdValue === "" && (
                <option style={{ alignItems: "start" }}>
                  select {drop2.all[0].name}
                </option>
              )}
              {drop2.data.map((item: any) => (
                <option
                  style={{ alignItems: "start" }}
                  key={item.id}
                  value={item.name}
                >
                  {item.name}
                </option>
              ))}
            </select>
          ) : (
            <select
              className="selectDropDownBlock"
              id="name"
              onChange={(e) => {
                handledrop2(e.target.value);
              }}
              value={thirdValue}
            >
              {drop2.data.map((item: any) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          )
        ) : null}

        {!R.isEmpty(drop3) ? (
          fourthValue === "" ? (
            <select
              className="selectDropDownBlock"
              style={{
                borderRadius: "50px",
                WebkitAppearance: "none",
                MozAppearance: "none",
                appearance: "none",
                backgroundImage: "none",
                //textAlign: "center",
                paddingLeft: "5px",
                borderColor: "#843939",
                borderWidth: "1px",
              }}
              onChange={(e) => {
                handledrop3(e.target.value);
              }}
              value={`select ${drop3.all[0].name}`}
            >
              {fourthValue === "" && (
                <option style={{ alignItems: "start" }}>
                  select {drop3.all[0].name}
                </option>
              )}
              {drop3.data.map((item: any) => (
                <option
                  style={{ alignItems: "start" }}
                  key={item.id}
                  value={item.name}
                >
                  {item.name}
                </option>
              ))}
            </select>
          ) : (
            <select
              className="selectDropDownBlock"
              id="name"
              onChange={(e) => {
                handledrop3(e.target.value);
              }}
              value={fourthValue}
            >
              {drop3.data.map((item: any) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          )
        ) : null}

        {!R.isEmpty(drop4) ? (
          fifthValue === "" ? (
            <select
              className="selectDropDownBlock"
              style={{
                borderRadius: "50px",
                WebkitAppearance: "none",
                MozAppearance: "none",
                appearance: "none",
                backgroundImage: "none",
                //textAlign: "center",
                paddingLeft: "5px",
                borderColor: "#843939",
                borderWidth: "1px",
              }}
              id="name"
              onChange={(e) => {
                handledrop4(e.target.value);
              }}
              value={`select ${drop4.all[0].name}`}
            >
              {fifthValue === "" && (
                <option style={{ alignItems: "start" }}>
                  select {drop4.all[0].name}
                </option>
              )}
              {drop4.data.map((item: any) => (
                <option
                  style={{ alignItems: "start" }}
                  key={item.id}
                  value={item.name}
                >
                  {item.name}
                </option>
              ))}
            </select>
          ) : (
            <select
              className="selectDropDownBlock"
              id="name"
              onChange={(e) => {
                handledrop4(e.target.value);
              }}
              value={fifthValue}
            >
              {drop4.data.map((item: any) => (
                <option key={item.id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          )
        ) : null}
      </div>
    </div>
  );
}

export default AnoviFilterChipDropComp;
