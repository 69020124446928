import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import DisplayError from "../DisplayError/DisplayError";
import "./AnoviEventSummary.scss";
import vector from "../.././assets/icons/Vector_arleft.svg";
import { SortingTable } from "./SortingTable";
import { millisecondsToTime } from "../../Utils/utils";

const DoorOpenTable = ({ data }: any) => {
  const [activeFacility, setActiviteFacility] = useState("");
  const [activeSortingKey, setActiveSortingKey] = useState("");
  const [insideTable, setInsideTable] = useState(false);
  const [doorOpenTableResponse, setDoorOpenTableResponse] = useState<any>([]);
  const [doorOpenTableInsideResponse, setDoorOpenTableInsideResponse] = useState<any>([]);
  const [pageIndex, setPageIndex] = useState(10);
  const [insidePageTableIndex, setInsidePageTableIndex] = useState(10);
  let Sort = new SortingTable();

  const dataSorting = (key:any, table:any) => {
    if(activeSortingKey === key){
      if(table === 1) setDoorOpenTableResponse(Sort.sortTableAscendingOrder(doorOpenTableResponse, key));
      else setDoorOpenTableInsideResponse(Sort.sortTableAscendingOrder(doorOpenTableInsideResponse, key));
      setActiveSortingKey("");
    }else {
      if(table === 1) setDoorOpenTableResponse(Sort.sortTableDescending(doorOpenTableResponse, key));
      else setDoorOpenTableInsideResponse(Sort.sortTableDescending(doorOpenTableInsideResponse, key));
      setActiveSortingKey(key);
    }
  }


  const returnexceptedObj = () => {
    let list: any = data.map((obj: any) => {
      let array = obj.eventSummary.map((el: any) => {
        return {
          facility: obj.facilityName,
          assets: obj.assetCount,
          cycleCount: parseFloat(el.cycleCount),
          avgDuration: parseFloat(el.averageDuration),
          avgTimeBtnCls: parseFloat(el.avgBetweenCycle),
          assetList: obj.assetsList,
        };
      });
      if (array.length === 1) {
        return array[0];
      } else {
        return {};
      }
    });
    setDoorOpenTableResponse(list);
  };

  useEffect(() => {
    returnexceptedObj();
  }, [data])

  const returnexceptedObj_1 = (responseData: any) => {
    let list: any = responseData.map((obj: any) => {
      let array = obj.eventSummary.map((el: any, index: any) => {
        return {
          asset: obj.assetName,
          cycleCount: el.cycleCount,
          avgDuration: el.averageDuration,
          avgTimeBtnCls: el.avgBetweenCycle,
        };
      });
      if (array.length === 1) {
        return array[0];
      } else {
        return {};
      }
    });
    setDoorOpenTableInsideResponse(list);
  };

  const ReturnTable = () => {
    return (
      <>
        <p
          onClick={() => {
            setActiviteFacility("");
            setInsideTable(false);
            setInsidePageTableIndex(10);
          }}
          className="allFacilities"
        >
          <img src={vector} />
          All facilities
        </p>
        <p className="facilitiesText">Assets under {activeFacility}</p>
        <div style={{ overflowX: "scroll" }}>
          <Table responsive>
            <thead>
              <tr>
                <th onClick={() => dataSorting('assets', 2)}><div className="sortingIcons">
                        <p>Assets</p>
                        <div>
                        <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                <th onClick={() => dataSorting('cycleCount', 2)}>
                <div className="sortingIcons">
                        <p>Cycle Count</p>
                        <div>
                        <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                <th onClick={() => dataSorting('avgDuration', 2)}>
                <div className="sortingIcons">
                        <p>Average Duration</p>
                        <div>
                        <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                <th onClick={() => dataSorting('avgTimeBtnCls', 2)}>
                <div className="sortingIcons">
                        <p>Avg Time Between Openings</p>
                        <div>
                          <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
              </tr>
            </thead>
            <tbody>
              {doorOpenTableInsideResponse && doorOpenTableInsideResponse.slice(0, insidePageTableIndex).map((el: any, index: any) => {
                return (
                  <tr key={index}>
                    <td>{el.asset}</td>
                    <td>{el.cycleCount}</td>
                    <td>{millisecondsToTime(el.avgDuration)}</td>
                    <td>{millisecondsToTime(el.avgTimeBtnCls)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {
            doorOpenTableInsideResponse.length > 10 ? 
            <div className="footerShowMoreContainer">
              <button disabled={insidePageTableIndex === 10} onClick={() =>  setInsidePageTableIndex(insidePageTableIndex-10)}> {" < "} </button><p>Showing {insidePageTableIndex-9} to {doorOpenTableInsideResponse.length > insidePageTableIndex ? insidePageTableIndex : doorOpenTableInsideResponse.length} of {doorOpenTableInsideResponse.length} {activeFacility}</p> <button disabled={insidePageTableIndex > doorOpenTableInsideResponse.length } onClick={() => setInsidePageTableIndex(insidePageTableIndex+10)}>{" > "}</button>
            </div>  : null
          }
        </div>
      </>
    );
  };

  return (
    <>
      {doorOpenTableResponse.length === 0 ? (
        <div className="centerDisplayNote">
          <DisplayError type={"err-empty"} />
        </div>
      ) : (
        <>
          {insideTable ? (
            <>{ReturnTable()}</>
          ) : (
            <div className="eventSummaryScss" style={{ overflowX: "scroll" }}>
              <Table className="eventTableHeader" responsive>
                <thead>
                  <tr>
                    <th onClick={() => dataSorting('facility', 1)}> 
                      <div className="sortingIcons">
                        <p>Facility</p>
                        <div>
                        <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div>
                    </th>
                    <th onClick={() => dataSorting('assets', 1)}>
                    <div className="sortingIcons">
                        <p>Assets</p>
                        <div>
                        <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    <th onClick={() => dataSorting('cycleCount', 1)}>
                    <div className="sortingIcons">
                        <p>Cycle Count</p>
                        <div>
                        <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    <th onClick={() => dataSorting('avgDuration', 1)}>
                    <div className="sortingIcons">
                        <p>Average Duration</p>
                        <div>
                        <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                    <th onClick={() => dataSorting('avgTimeBtnCls', 1)}>
                    <div className="sortingIcons">
                        <p>Avg Time Between Openings</p>
                        <div>
                        <div className="upArrow"></div>
                          <div className="downArrow"></div>
                        </div>
                      </div></th>
                  </tr>
                </thead>
                <tbody>
                  {doorOpenTableResponse.slice(0, pageIndex).map((el: any, index: any) => {
                    return (
                      <tr key={index}>
                        <td
                          onClick={() => {
                            returnexceptedObj_1(el.assetList)
                            setActiviteFacility(el.facility);
                            setInsideTable(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {el.facility}
                        </td>
                        <td>{el.assets}</td>
                        <td>{el.cycleCount}</td>
                        <td>{millisecondsToTime(el.avgDuration)}</td>
                        <td>{millisecondsToTime(el.avgTimeBtnCls)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              {
                doorOpenTableResponse.length > 10 ? 
                <div className="footerShowMoreContainer">
                  <button disabled={ pageIndex === 10} onClick={() => setPageIndex(pageIndex-10)}> {" < "} </button><p>Showing {pageIndex-9} to {doorOpenTableResponse.length > pageIndex ? pageIndex : doorOpenTableResponse.length} of {doorOpenTableResponse.length} {activeFacility}</p> <button disabled={pageIndex > doorOpenTableResponse.length} onClick={() =>  setPageIndex(pageIndex+10)}>{" > "}</button>
                </div>  : null
              }
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DoorOpenTable;
