import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import A1Button from "../../Button";
import iccalendar from "../../../assets/image/calendar-icon.svg";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./DatePicker.scss";
import { useStateValue } from "../../../redux/StateProvider";

function DatePicker({
  handleCustom,
  callBackToday,
  handleCustomClick,
  remove,
  placement,
  stateId,
  anovi
}: any) {
  const [clicked, setClicked] = useState(false);
  const [active, setActive] = useState(false);
  const [label, setLabel] = useState("Custom Range");
  const [{ dateChip }, dispatch] = useStateValue();

  useEffect(() => {
    if (dateChip === "Custom") {
    }else {
      setLabel("Custom Range");
      setActive(false);
      setClicked(false);
    }
  }, [dateChip]);

  //   const start = new Date();
  const [state, setState] = useState<any>([
    {
      startDate: moment()
        .startOf("day")
        .subtract(5, "h")
        .subtract(30, "minutes"),
      endDate: null,
      key: "selection",
    },
  ]);

  // useEffect(() => {
  //   if (remove) {
  //     setLabel("Custom Range");
  //     setActive(false);
  //     callBackToday();
  //   }
  // }, [remove]);

  const handleDate = () => {
 
    const { startDate, endDate } = state[0];
    let dateCompare =
      moment(startDate).format("l") === moment(endDate).format("l");
    let startEnd = dateCompare
      ? moment(startDate).format("DD/MM/YYYY")
      : moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY");
  
    setLabel(startEnd);
    setActive(!active);
    handleCustom(active, label);

    const startUTC = moment(moment(startDate).format("YYYY-MM-DD HH:mm:ss"))
      .startOf("day")
      .valueOf();
    const endUTC = moment(moment(endDate).format("YYYY-MM-DD HH:mm:ss"))
      .endOf("day")
      .valueOf();

    let startFormate = moment(startUTC).format("YYYY-MM-DD HH:mm:ss");
    let endFormate = moment(endUTC).format("YYYY-MM-DD HH:mm:ss");

    const dateRangeSet = JSON.stringify(
      `${moment(startUTC).format("DD/MM/YYYY")} - ${moment(endUTC).format(
        "DD/MM/YYYY"
      )}`
    );

    dispatch({
      type: "DATE_RANGE",
      dateRange: [startFormate, endFormate],
    });

    dispatch({ type: "SET_DATE_CHIP", dateChip: "Custom" });

    dispatch({
      type: "TO_DATE_VALUE",
      toDateValue: `${JSON.parse(dateRangeSet)} `,
    });
  };
  const handleClick = () => {
    setClicked(!clicked);
    setState([
      {
        startDate: moment()
          .startOf("day")
          .subtract(5, "h")
          .subtract(30, "minutes")
          .valueOf(),
        endDate: moment()
          .endOf("day")
          .subtract(5, "h")
          .subtract(30, "minutes")
          .valueOf(),
        key: "selection",
      },
    ]);
    if (active) {
      setLabel("Custom Range");
      callBackToday();
      setActive(false);
    }
    handleCustomClick(active);
  };
  const handleCancel = () => {
    setClicked(!clicked);
  };
  // filter: opacity(0.5) drop-shadow(0 0 0 blue);

  const handleDateChange = (item: any) => {
    // console.log("item on datechange is::", item);
    setState([item.selection]);
  };

  // Function to get the date 365 days ago from today
  const get365DaysAgo = () => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 365));
    return pastDate;
  };

  return (
    <div className="a1DatePicker">
      <A1Button onClick={handleClick} anovi={anovi} active={active}>
        <img
          src={iccalendar}
          style={{
            width: "12px",
            marginRight: "5px",
            filter: active ? "brightness(0) invert(1)" : "none",
          }}
        />
        {label} {active && <FontAwesomeIcon icon={faXmark} />}
      </A1Button>
      {!active && clicked && !remove && (
        <div
          onMouseLeave={() => setClicked(false)}
          className={placement ? "downloadDatePicker" : "a1DatePicker-drawer"}
        >
          <DateRange
            showDateDisplay={false}
            onChange={handleDateChange}
            moveRangeOnFirstSelection={false}
            ranges={state}
            maxDate={new Date()}
            minDate={get365DaysAgo()}
          />
          <div className="a1DatePicker-button">
            <button className="btn" onClick={handleCancel}>
              Cancel
            </button>
            <button className="btn" onClick={handleDate}>
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DatePicker;