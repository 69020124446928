
import React, { useEffect, useState } from 'react';
import './ADEnergyConsuption.scss';
import * as R from "ramda";

function ADEnergyDemandMetrics({ data, onUpdateMessage }: any) {

    const [maximumDemand, setMaximumDemand] = useState<any>();
    const [predictedDemand, setPredictedDemand] = useState<any>();
    const [raisingDemand, setRaisingDemand] = useState<any>();

    useEffect(() => {
        {
            !R.isEmpty(data?.meters) &&
                !R.isNil(data?.meters) &&
                data?.meters.find(
                    (el: any) => {
                        el.meterTypeId.includes("MD") && setMaximumDemand(el)
                    }
                );
        }
        {
            !R.isEmpty(data?.meters) &&
                !R.isNil(data?.meters) &&
                data?.meters.find(
                    (el: any) => el.meterTypeId.includes("PD") && setPredictedDemand(el)
                );
        }
        {
            !R.isEmpty(data?.meters) &&
                !R.isNil(data?.meters) &&
                data?.meters.find(
                    (el: any) => el.meterTypeId.includes("RD") && setRaisingDemand(el)
                );
        }
    }, [data]);

    useEffect(() => {
        if ((maximumDemand?.meterValue) === null && (predictedDemand?.meterValue) === null && (raisingDemand?.meterValue) === null) {
            onUpdateMessage(false)
        }
    }, [maximumDemand, predictedDemand, raisingDemand])
    return (
        <div className='demandMatrics'>
            <h3>Demand Metrics</h3>
            <div className='matrix_flex'>
                <div>
                    <h4>Maximum demand</h4>
                    <h3>{!R.isEmpty(maximumDemand?.meterValue) && !R.isNil(maximumDemand?.meterValue)
                        ? Number(maximumDemand?.meterValue)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "--"}{" "}
                        {maximumDemand?.readingUomDesc}</h3>
                </div>
                <div>
                    <h4>Predicted demand</h4>
                    <h3>{!R.isEmpty(predictedDemand?.meterValue) && !R.isNil(predictedDemand?.meterValue)
                        ? Number(predictedDemand?.meterValue)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "--"}{" "}
                        {predictedDemand?.readingUomDesc}</h3>
                </div>
                <div>
                    <h4>Raising demand</h4>
                    <h3>{!R.isEmpty(raisingDemand?.meterValue) && !R.isNil(raisingDemand?.meterValue)
                        ? Number(raisingDemand?.meterValue)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "--"}{" "}
                        {raisingDemand?.readingUomDesc}</h3>
                </div>

            </div>
        </div>
    )
}

export default ADEnergyDemandMetrics