import React, { useEffect, useState } from "react";

import ConsuptionImg from "../../../assets/icons/ConsuptionImg.svg";
import AirGroupImg from "../../../assets/image/AirGroupImg.svg";
import * as R from "ramda";
import "./ADAirConsumption.scss";
import airIcon from "../../../assets/image/airIcon.svg";

function ADAirConsumption({ data }) {
  const [AirFlowAgr, setAirFlowAgr] = useState();

  useEffect(() => {
    {
      !R.isEmpty(data?.meters) &&
        !R.isNil(data?.meters) &&
        data?.meters.find(
          (el, index) =>
            el.meterTypeId.includes("AIR_FLOW_TOT") && setAirFlowAgr(el)
        );
    }
  }, [data]);

  return (
    <div className="adAirConsuptionCont">
      <div className="cardDetailsCont">
        <div className="cardDetails">
          <p className="cardHeader">Meter Reading</p>
          <div className="imgContainer">
            <div className="imgCountCont">
              <img src={airIcon} alt="icon" className="icons" />
              <p className="counts">
                <span className="liquidCrystalFont">
                {!R.isEmpty(AirFlowAgr?.meterValue) &&
                !R.isNil(AirFlowAgr?.meterValue)
                  ? Number(AirFlowAgr?.meterValue)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "--"}{" "}
                </span>
                {AirFlowAgr?.readingUomDesc}
              </p>
              {/* <p className="counts">1341.41 kWh</p> */}
            </div>
            {/* <div className="imgCountCont">
              <img src={ConsuptionImg} alt="icon" className="icons" />
              {!R.isEmpty(data?.dailyMeters) && !R.isNil(data?.dailyMeters) ? (
                data?.dailyMeters.map(
                  (el, index) =>
                    el.meterTypeId.includes("KVAH") && (
                      <>
                        <p className="counts">
                          {!R.isEmpty(el?.maximum) && !R.isEmpty(el?.maximum) ? Number(el?.maximum).toFixed(2) :"-"} kVAh
                        </p>
                        <li className="cardOneValue">{Number(el?.sum).toFixed(2)} kWh</li> 
                      </>
                    )
                )
              ) : (
                <>
                  <p className="counts">-</p>
                </>
              )}
               <p className="counts">1341.41 kWh</p> 
            </div> */}
          </div>
        </div>
      </div>
      <div className="cardImages">
        <img src={AirGroupImg} alt="Img" className="image1" />
      </div>
    </div>
  );
}

export default ADAirConsumption;
