import React from "react";

import CardOneImg from "../../../assets/icons/CardOneImg.svg";
import TimeImg from "../../../assets/icons/TimeImg.svg";
import FuelImg from "../../../assets/icons/FuelImg.svg";
import RemainingImg from "../../../assets/icons/RemainingImg.svg";
import FuelPumpImg from "../../../assets/icons/FuelPumpImg.svg";
import * as R from "ramda";
import "./ADDGConsuption.scss";

function ADDGConsuption({data}) {
  return (
    <div className="adDGConsuptionCont">
      <div className="dgConsuption">
        <div className="cardOne">
          <img src={CardOneImg} alt="icon" className="cardOneImg" />
          <ul className="cardOneDetails">
          {!R.isEmpty(data?.dailyMeters) && !R.isNil(data?.dailyMeters) ? (
          data?.dailyMeters.map(
            (el, index) =>
              el.meterTypeId.includes("Wh_DG") && (
                <>
                  
                  <li className="cardOneValue">{Number(el?.sum).toFixed(2)} kWh</li>
                </>
              )
          )
        ) : (
          <>
            <li className="cardOneValue"> -</li>
          </>
        )}
            {/* <li className="cardOneValue">5420.8 kWh</li> */}
            <li className="cardOneTxt">Total Energy Generated</li>
          </ul>
        </div>
        <div className="cardsContainer">
          <div className="cardTwoCont">
            <img src={TimeImg} alt="icon" className="cardTwoImg" />
            <ul className="cardTwoDetails">
            {!R.isEmpty(data?.dailyMeters) && !R.isNil(data?.dailyMeters) ? (
          data?.dailyMeters.map(
            (el, index) =>
              el.meterTypeId.includes("RUNHR_ACTUAL") && (
                <>
                   <li className="cardTwoValue">{Number(el?.sum).toFixed(2)} hours</li>
                  {/* <li className="cardOneValue">{Number(el?.sum).toFixed(2)}°{el.defaultUom}</li> */}
                </>
              )
          )
        ) : (
          <>
             <li className="cardTwoValue">-</li>
          </>
        )}
              {/* <li className="cardTwoValue">331 hours</li> */}
              <li className="cardTwoTxt">Total Run Hours</li>
            </ul>
          </div>

          <div className="cardTwoCont">
            <img src={FuelImg} alt="icon" className="cardTwoImg" />
            <ul className="cardTwoDetails">
            {!R.isEmpty(data?.dailyMeters) && !R.isNil(data?.dailyMeters) ? (
          data?.dailyMeters.map(
            (el, index) =>
              el.meterTypeId.includes("FUEL_REMAINING") && (
                <>
                   <li className="cardTwoValue">{Number(el?.sum).toFixed(2)} L</li>
                  {/* <li className="cardOneValue">{Number(el?.sum).toFixed(2)}°{el.defaultUom}</li> */}
                </>
              )
          )
        ) : (
          <>
             <li className="cardTwoValue">-</li>
          </>
        )}
              {/* <li className="cardTwoValue">13 L</li> */}
              <li className="cardTwoTxt">Fuel remaining</li>
            </ul>
          </div>

          <div className="cardTwoCont">
            {/* <img src={RemainingImg} alt="icon" className="cardTwoImg" /> */}
            <ul className="cardTwoDetails">
              <li className="cardTwoValue">-</li>
              <li className="cardTwoTxt">Time Remaining</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="dgImages">
        <img src={FuelPumpImg} alt="icon" className="image"/>
      </div>
    </div>
  );
}

export default ADDGConsuption;
