import React, { useEffect, useState } from "react";

import ConsuptionImg from "../../../assets/icons/ConsuptionImg.svg";
import TowerImg from "../../../assets/icons/TowerImg.svg";
import * as R from "ramda";
import "./ADEnergyConsuption.scss";

function ADEnergyConsuption({ data }: any) {
  const [whEB, setWhEB] = useState<any>();
  const [kvah, setKvah] = useState<any>();
  useEffect(() => {
    {
      !R.isEmpty(data?.meters) &&
        !R.isNil(data?.meters) &&
        data?.meters.find(
          (el: any) => el.meterTypeId.includes("KWH_MR") && setWhEB(el)
        );
    }
    {
      !R.isEmpty(data?.meters) &&
        !R.isNil(data?.meters) &&
        data?.meters.find(
          (el: any) => el.meterTypeId.includes("KVAH_MR") && setKvah(el)
        );
    }
  }, [data]);

  return (
    <div className="adEnergyConsuptionCont">
      <div className="cardDetailsCont">
        <div className="cardDetails">
          <p className="cardHeader">Meter Reading</p>
          <div className="imgContainer">
            <div className="imgCountCont">
              <img src={ConsuptionImg} alt="icon" className="icons" />
              <p className="counts">
              <span className="liquidCrystalFont">
                {!R.isEmpty(whEB?.meterValue) && !R.isNil(whEB?.meterValue)
                  ? Number(whEB?.meterValue)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "--"}{" "}
                  </span>
                  {whEB?.readingUomDesc}
              </p>
              {/* <p className="counts">1341.41 kWh</p> */}
            </div>
            <div className="imgCountCont">
              <img src={ConsuptionImg} alt="icon" className="icons" />
              <p className="counts">
              <span className="liquidCrystalFont">
                {!R.isEmpty(kvah?.meterValue) && !R.isNil(kvah?.meterValue)
                  ? Number(kvah?.meterValue)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "--"}{" "}
                  </span>
                  {kvah?.readingUomDesc}
              </p>
              {/* <p className="counts">1341.41 kWh</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="cardImages">
        <img src={TowerImg} alt="Img" className="image1" />
        <img src={TowerImg} alt="Img" className="image2" />
      </div>
    </div>
  );
}

export default ADEnergyConsuption;
