import { useEffect, useState } from "react";

import acControllerArrowIcon from "../../acController/assets/acControllerArrowIcon.svg";
import acControllerSuccessIcon from "../../acController/assets/acControllerSuccessIcon.svg";
import { AcControllerSetting, WeekDaysDataType } from "../types";
import { weekDaysData } from "../../../Constants/constants";
import { updateAcControllerSettings } from "../service/AcControllerApi";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

type PropTypes = {
  acControllerSetting: AcControllerSetting;
  assetId: string;
  macId: string;
};

function TimerMode({ acControllerSetting, assetId, macId }: PropTypes) {
  const queryClient = useQueryClient();
  const [timerModeEnabled, setTimerModeEnabled] = useState<boolean>(false);

  const [selectedTime, setSelectedTime] = useState({
    onTime: "",
    offTime: "",
  });
  const [timeValue, setTimeValue] = useState({
    onTimeHour: "",
    onTimeMin: "",
    offTimeHour: "",
    offTimeMin: "",
  });

  const [weekDays, setWeekDays] = useState<WeekDaysDataType[]>(weekDaysData);
  const [weekDaysInNumber, setWeekDaysInNumber] = useState({
    decimal: 0,
    binary: "",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isValidTimeRange, setIsValidTimeRange] = useState(true);

  const { data, error, isLoading, isFetching, refetch, dataUpdatedAt } =
    useQuery(
      "updateAcControllerSettingsForTimer",
      () =>
        updateAcControllerSettings({
          commandsJson: {
            cmd: 202,
            deviceId: `'${macId}'`,
            timerMode: {
              opMode: false,
              timerFlag: true,
              onTimeHour: Number(timeValue.onTimeHour),
              onTimeMin: Number(timeValue.onTimeMin),
              offTimeHour: Number(timeValue.offTimeHour),
              offTimeMin: Number(timeValue.offTimeMin),
              dayOfWeek: weekDaysInNumber.decimal,
            },
          },
          fixedAssetId: assetId,
          topic: "topic-mqtt-Aone-ctrl-cmd-prod",
        }),
      {
        enabled: false,
        keepPreviousData: false,
      }
    );

  const updateSettingInput = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error("Error re-fetching data:", error);
    }
  };

  const handleArrowClicks = () => {
    setTimerModeEnabled((prev) => !prev);
  };

  const handleDayClick = (value: string) => {
    setWeekDays((prevWeekDays) => {
      return prevWeekDays.map((day) => {
        if (day.key === value) {
          return { ...day, selected: !day.selected };
        } else {
          return day;
        }
      });
    });
  };

  useEffect(() => {
    const binaryRepresentation = weekDays
      .map((day) => (day.selected ? "1" : "0"))
      .join("");

    const decimalValue = parseInt(binaryRepresentation, 2);

    setWeekDaysInNumber({
      decimal: decimalValue,
      binary: binaryRepresentation,
    });
  }, [weekDays]);

  const convertTimeToSeconds = (time: string) => {
    const [hour, minute] = time.split(":");
    return parseInt(hour, 10) * 3600 + parseInt(minute, 10) * 60;
  };

  function decimalToBinary(decimalNumber: number): string {
    let binaryRepresentation = "";
    if (decimalNumber === null) {
      binaryRepresentation = "";
    } else {
      binaryRepresentation = decimalNumber.toString(2);
    }

    // Pad with leading zeros to ensure 7 digits
    while (binaryRepresentation.length < 7) {
      binaryRepresentation = "0" + binaryRepresentation;
    }

    return binaryRepresentation;
  }

  const convertGMTToLocal = (val: any) => {
    const localTime = val;
    const localTimeMoment = moment(localTime, "HH:mm");
    const gmtTimeMoment = localTimeMoment.utcOffset(0, true);
    const adjustedGmtTimeMoment = gmtTimeMoment
      .add(5, "hours")
      .add(30, "minutes");
    const adjustedGmtTime = adjustedGmtTimeMoment.format("HH:mm");

    return adjustedGmtTime;
  };

  const updateUseState = () => {
    const onTime = convertGMTToLocal(
      `${acControllerSetting.data.onTimeHour}:${acControllerSetting.data.onTimeMin}`
    );
    const offTime = convertGMTToLocal(
      `${acControllerSetting.data.offTimeHour}:${acControllerSetting.data.offTimeMin}`
    );
    const timerValue = {
      onTime: onTime,
      offTime: offTime,
    };
    setSelectedTime(timerValue);

    const tempWeekDays = weekDaysData;
    const dayOfWeek = acControllerSetting.data.dayOfWeek;
    const checkDayOfWeek = dayOfWeek === undefined ? 0 : dayOfWeek;
    const binaryValue = decimalToBinary(checkDayOfWeek);
    const binaryList = binaryValue.split("");

    tempWeekDays.forEach((item, index) => {
      const binary = binaryList[index];
      if (binary === "1") {
        tempWeekDays[index].selected = true;
      }
    });

    setWeekDaysInNumber({
      binary: binaryValue,
      decimal: checkDayOfWeek,
    });
  };

  const convertLocalToGMT = (val: any) => {
    const localTime = val;
    const localTimeMoment = moment(localTime, "HH:mm");
    const gmtTimeMoment = localTimeMoment.utcOffset(0, true);
    const adjustedGmtTimeMoment = gmtTimeMoment
      .subtract(5, "hours")
      .subtract(30, "minutes");
    const adjustedGmtTime = adjustedGmtTimeMoment.format("HH:mm");

    return adjustedGmtTime;
  };

  useEffect(() => {
    const updateTimeValue = () => {
      const onTime = convertLocalToGMT(selectedTime.onTime);
      const offTime = convertLocalToGMT(selectedTime.offTime);

      const [onHour, onMinute] = onTime.split(":");
      const [offHour, offMinute] = offTime.split(":");
      const temp = {
        onTimeHour: onHour,
        onTimeMin: onMinute,
        offTimeHour: offHour,
        offTimeMin: offMinute,
      };
      setTimeValue(temp);
    };

    const checkForValidTimeRange = () => {
      if (
        selectedTime.onTime !== "" &&
        selectedTime.offTime !== "" &&
        convertTimeToSeconds(selectedTime.onTime) <
          convertTimeToSeconds(selectedTime.offTime)
      ) {
        setIsValidTimeRange(true);
      } else {
        setIsValidTimeRange(false);
      }
    };

    updateTimeValue();
    checkForValidTimeRange();
  }, [selectedTime]);

  useEffect(() => {
    if (
      acControllerSetting !== undefined &&
      acControllerSetting.hasOwnProperty("key") &&
      acControllerSetting.key !== ""
    ) {
      updateUseState();
    }
  }, [acControllerSetting]);

  useEffect(() => {
    if (data !== undefined && data === 200) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
  }, [data, dataUpdatedAt]);

  /**
   * It will remove the cache of the react-query call for the specific id.
   * @param id : id for the react-query call
   */
  const cancelExistingQuery = (id: string) => {
    queryClient
      .getQueryCache()
      .getAll()
      .forEach((query) => {
        if (query.queryKey.includes(id)) {
          queryClient.removeQueries(query.queryKey);
        }
      });
  };

  useEffect(() => {
    cancelExistingQuery("updateAcControllerSettingsForTimer");
    return () => {
      setShowSuccessMessage(false);
      setIsValidTimeRange(false);
    };
  }, []);

  return (
    <>
      {/* For Timer Mode */}
      <div className="bodyContentMainDiv">
        <div
          className="acControllerHeadingDiv"
          onClick={() => handleArrowClicks()}
        >
          <img
            src={acControllerArrowIcon}
            className={`acControllerArrowIcon ${
              timerModeEnabled ? "rotate-90" : "rotate-0"
            }`}
          />
          <p className="acControllerHeadingText">Timer Mode</p>
        </div>
        {timerModeEnabled && (
          <>
            <div className="acControllerDataInputDiv">
              <div className="acControllerDataDiv">
                <p className="acControllerCategory">On Time</p>
                <input
                  type="time"
                  className="acControllerInput noClockIcon"
                  value={selectedTime.onTime}
                  onChange={(e) =>
                    setSelectedTime({
                      ...selectedTime,
                      onTime: e.target.value,
                    })
                  }
                />
              </div>
              <div className="acControllerDataDiv">
                <p className="acControllerCategory">Off Time</p>
                <input
                  type="time"
                  className="acControllerInput"
                  value={selectedTime.offTime}
                  onChange={(e) =>
                    setSelectedTime({
                      ...selectedTime,
                      offTime: e.target.value,
                    })
                  }
                  min={selectedTime.onTime}
                />
              </div>
            </div>

            {selectedTime.onTime !== "" &&
              selectedTime.offTime !== "" &&
              !isValidTimeRange && (
                <p className="timeErrorMessage">
                  Off time should be later than the on time
                </p>
              )}

            <p className="weekdaysHeading">Repeat</p>

            <div className="weekDaysContainer">
              {weekDays.map((item, index) => (
                <div
                  key={JSON.stringify(item)}
                  id={`weekday-${index}`}
                  className="weekDayText"
                  style={{
                    border: `1px solid ${
                      item.selected === true ? "blue" : "rgba(189, 203, 251, 1)"
                    }`,
                    backgroundColor:
                      item.selected === true
                        ? "rgba(189, 203, 251, 0.5)"
                        : "transparent",
                  }}
                  onClick={() => handleDayClick(item.key)}
                >
                  {item.name}
                </div>
              ))}
            </div>
            {showSuccessMessage ? (
              <div className="successDivCont">
                <img src={acControllerSuccessIcon} alt="success-icon" />
                <p className="successText"> Success</p>
              </div>
            ) : isLoading || isFetching ? (
              <div className="loading-container">
                <div className="loading-spinner"></div>
              </div>
            ) : (
              <button
                className="applyButtonDiv"
                style={{
                  opacity: isValidTimeRange ? 1 : 0.3,
                }}
                onClick={updateSettingInput}
                disabled={!isValidTimeRange}
              >
                <p className="applyButtonText">
                  {error || (data !== undefined && data !== 200)
                    ? "Retry"
                    : "Apply"}
                </p>
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default TimerMode;
