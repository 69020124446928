import React, { useEffect, useState } from "react";
import downloadIcon from "./../../assets/icons/viewReportDownloadIcon.svg";
import pinIcon from "./../../assets/icons/viewReportPinIcon.svg";
import DownloadIcon from "../../assets/icons/downlloadIcon.svg";
import CloseIcon from "../../assets/icons/CloseButton.svg";
import TempInputFied from "./TempInputFied/TempInputFied";
import * as R from "ramda";
import moment from "moment";
import DateRangeSelectorInput from "./DateRangeSelectorInput/DateRangeSelectorInput";
import DateTimeRangeSelectorInput from "./DateTimeRangeSelectorInput/DateTimeRangeSelectorInput";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import TimeRangePicker from "./TimeRangePicker/TimeRangePicker";
import ReportDatePicker from "./DatePicker/ReportDatePicker";
import TimePicker from "./TimePicker/TimePicker";
import ReportDateTimePicker from "./ReportDateTimePicker/ReportDateTimePicker";
import {
  DateDataType,
  ReportCardType,
  ReportResponseCard,
  TimeInputType,
} from "./DataTypes";
import DateFormat from "./DateFormatters/DateFormat";
import Select from "react-select";
import "./ReportCard.scss";
import LocalStorageService from "../../Utils/LocalStorageService";
import { useStateValue } from "../../redux/StateProvider";
import { reportDropDownCustomStyle, roundToDecimalNumber } from "../../Utils/utils";
import AssetFilterUtils from "../../Utils/AssetFilterUtils";

export const ReportCard = ({
  data,
  activeState,
  active,
  callBackRepoStatus,
}: ReportCardType) => {
  const [cardShow, setCardShow] = useState(false);
  const [repoLoader, setRepoLoader] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState<any>("");
  const [minTemp, setMinTemp] = useState<any>("");
  const [maxTemp, setMaxTemp] = useState<any>("");
  const [formatList, setFormatList] = useState<any>([]);
  const [dateRangeValue, setDateRangeValue] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [payloadDateValue, setPayloadDateValue] = useState<DateDataType>({
    startDate: "",
    endDate: "",
  });
  const [attributeProperties, setAttributeProperties] = useState<any>([]);
  const [downloadBtnStatus, setDownloadBtnStatus] = useState(false);
  const [activeInput, setActiveInput] = useState("");
  const dateFormatter = new DateFormat();

  const [selectedCategoryType, setSelectedCategoryType] =
    useState<string>("null");
  const [selectedAssetType, setSelectedAssetType] = useState<string>("null");
  const [selectedAsset, setSelectedAsset] = useState<string>("null");
  const [selectedAssetObject, setSelectedAssetObject] = useState(null);
  const [selectedAssetTypeObject, setSelectedAssetTypeObject] = useState(null);

  const [assetTypeList, setAsssetTypeList] = useState<
    { fixedAssetTypeId: string; description: string }[]
  >(LocalStorageService.getUserSpecificAssetTypeData());

  const assetFilterUtils = new AssetFilterUtils();
  const categoryFilterData = assetFilterUtils.setCategoryFilterData();

  const [{ utilUserAssets }, dispatch] = useStateValue();
  const [filteredAssets, setFilteredAssets] = useState<any[]>();

  let assetListOptions =
    utilUserAssets?.map((asset: any) => ({
      value: asset.fixedAssetId,
      label: asset.fixedAssetName,
      key: asset.fixedAssetTypeId,
    })) || [];

  useEffect(() => {
    const allAssetData = LocalStorageService.getUserSpecificAssetData();
    dispatch({ type: "UTIL_USER_ASSETS", utilUserAssets: allAssetData });
  }, []);

  useEffect(() => {
    if (selectedAssetType != "null") {
      const filtered = assetListOptions.filter(
        (asset: any) => asset.key === selectedAssetType
      );

      setFilteredAssets(filtered);
      setSelectedAssetObject(null);
      setSelectedAsset("null");
    } else {
      setFilteredAssets(assetListOptions);
    }
  }, [selectedAssetType, utilUserAssets]);

  useEffect(() => {
    if (selectedCategoryType != "null") {
      let allAssetsList = LocalStorageService.getUserSpecificAssetTypeData();
      const filteredAssetType = allAssetsList.filter(
        (asset: any) => asset.parentFixedAssetTypeId === selectedCategoryType
      );

      const newArray = filteredAssetType.map(
        (obj: { fixedAssetTypeId: any }) => obj.fixedAssetTypeId
      );

      const filteredAssetList = assetListOptions.filter((asset: any) =>
        newArray.includes(asset.key)
      );

      setAsssetTypeList(filteredAssetType);
      setFilteredAssets(filteredAssetList);
      setSelectedAssetObject(null);
      setSelectedAssetTypeObject(null);
      setSelectedAsset("null");
    } else {
      // setAsssetTypeList(assetListOptions);
      // setFilteredAssets(assetListOptions)
    }
  }, [selectedCategoryType, utilUserAssets]);

  const getReportDownload = async () => {
    setRepoLoader(true);
    let dbService = new DashBoardServices();
    let postRepoOBject;
    let obj = {
      fromDate: payloadDateValue.startDate,
      minVal: roundToDecimalNumber(minTemp, 8),
      maxVal: roundToDecimalNumber(maxTemp, 8),
      fixedAssetId: selectedAsset,
      fixedAssetTypeId: selectedAssetType,
      fixedAssetTypeParentId: selectedCategoryType,
      thruDate: payloadDateValue.endDate,
      contentId: data.contentId,
      reportFormat: selectedFileType.toLowerCase(),
      reportName: data.contentName,
    };
    if (
      attributeProperties.includes("TEMP_MIN") &&
      attributeProperties.includes("TEMP_MAX")
    ) {
      postRepoOBject = { ...obj };
    } else {
      let { minVal, maxVal, ...repoObj } = { ...obj };
      postRepoOBject = repoObj;
    }
    let dataFetch: any = await dbService.getReportDownload(postRepoOBject);
    let message;
    if (dataFetch === "error") message = "Failed Try Again";
    else {
      switch (dataFetch.status) {
        case 200:
          message = "Report download initiated successfully";
          setCardShow(false);
          setSelectedFileType("");
          setDateRangeValue({ startDate: "", endDate: "" });
          setMinTemp(-55);
          setMaxTemp(125);
          break;

        case 419:
          let data_412 = await dataFetch.json();
          message = await data_412.errorMessage;
          break;

        case 204:
          let data_413 = await dataFetch.json();
          message = await data_413.errorMessage;
          break;

        case 503:
          let data_419 = await dataFetch.json();
          message = await data_419.errorMessage;
          break;

        case 512:
          let data_420 = await dataFetch.json();
          message = await data_420.errorMessage;
          break;

        case 500:
          message = "Internal server error";
          break;

        case 400:
          message = "Bad Request Error";
          break;

        default:
          message = "error";
      }
    }
    callBackRepoStatus(message);
    setRepoLoader(false);
  };

  useEffect(() => {
    if (!R.isEmpty(data)) {
      let repoListAttributes = data?.attributeProperties?.map((el: any) => {
        if (el.attributeValue === "TRUE") return el.attributeName;
      });
      setAttributeProperties(repoListAttributes);
      let list: any = data?.attributeProperties.find(
        (el: any) => el.attributeName === "FORMATS"
      );
      if (list === undefined) setFormatList([]);
      else {
        let itemsList = list.attributeValue;
        if (itemsList.includes("XLSX") && itemsList.includes("PDF"))
          setFormatList(["XLSX", "PDF"]);
        else if (itemsList.includes("XLSX")) setFormatList(["XLSX"]);
        else if (itemsList.includes("PDF")) setFormatList(["PDF"]);
        else setFormatList([]);
      }
    }
  }, [data]);

  const handleDateRangeChange = (ranges: DateDataType) => {
    const fromdDate = moment(ranges.startDate).format("MM/DD/YYYY");
    const endDate = moment(ranges.endDate).format("MM/DD/YYYY");
    setPayloadDateValue({
      startDate: moment(ranges.startDate).startOf("day").valueOf(),
      endDate: moment(ranges.endDate).endOf("day").valueOf(),
    });
    setDateRangeValue({ startDate: fromdDate, endDate: endDate });
  };

  const handleDateChange = (ranges: DateDataType) => {
    const fromdDate = moment(ranges.startDate).format("MM/DD/YYYY");
    const selectedDate = moment(ranges.startDate).startOf("day").valueOf();
    const endOfDate = moment(ranges.startDate).endOf("day").valueOf();
    setPayloadDateValue({
      startDate: selectedDate,
      endDate: endOfDate,
    });
    setDateRangeValue({ startDate: fromdDate, endDate: "" });
  };

  const handleDateTimeRangeChange = (ranges: DateDataType) => {
    setPayloadDateValue({
      startDate: ranges.startDate,
      endDate: ranges.endDate,
    });
    setDateRangeValue(ranges);
  };

  const handleDateTimeChange = (epochiTime: number) => {
    setPayloadDateValue({
      startDate: epochiTime,
      endDate: epochiTime + 59 * 1000, // 59 seconds between start time and end time
    });
    setDateRangeValue({ startDate: epochiTime, endDate: epochiTime });
  };

  const handleTimeRangeChange = (ranges: TimeInputType) => {
    let startTime = dateFormatter.timeConvertedintomilliSeconds(
      ranges.startDate
    );
    let endTime = dateFormatter.timeConvertedintomilliSeconds(ranges.endDate);
    setPayloadDateValue({
      startDate: startTime,
      endDate: endTime,
    });
    setDateRangeValue(ranges);
  };

  const handleTimeChange = (ranges: TimeInputType) => {
    let startTime = dateFormatter.timeConvertedintomilliSeconds(
      ranges.startDate
    );
    setPayloadDateValue({
      startDate: startTime,
      endDate: startTime,
    });
    setDateRangeValue(ranges);
  };

  useEffect(() => {
    if (!active) {
      setSelectedFileType("");
      setMinTemp("");
      setMaxTemp("");
      setDateRangeValue({
        startDate: "",
        endDate: "",
      });
    }
  }, [active]);

  useEffect(() => {
    let checkList = data.attributeProperties.filter((obj: any) => {
      if (obj.attributeValue === "TRUE" && obj.mandate === "Y") return obj;
    });
    let count = 0;
    for (let item of checkList) {
      if (item.attributeName === "TEMP_MIN") {
        if (minTemp !== "") count = count + 1;
      } else if (item.attributeName === "TEMP_MAX") {
        if (maxTemp !== "") count = count + 1;
      } else if (item.attributeName === "ASSET") {
        if (selectedAsset !== "null") count = count + 1;
      } else if (item.attributeName === "ASSET_TYPE") {
        if (selectedAssetType !== "null") count = count + 1;
      } else if (item.attributeName === "ASSET_CATEGORY") {
        if (selectedCategoryType !== "null") count = count + 1;
      } else {
        if (dateRangeValue.startDate !== "") count = count + 1;
      }
    }
    setDownloadBtnStatus(count === checkList.length);
  }, [
    minTemp,
    maxTemp,
    dateRangeValue,
    selectedCategoryType,
    selectedAsset,
    selectedAssetType,
  ]);

  const getComponent = (item: ReportResponseCard, id: number) => {
    switch (item.attributeName) {
      case "TEMP_MIN":
        return (
          <TempInputFied
            key={id}
            abbreviation={item.uomAbbr}
            validationType={item.fieldTypeId}
            field={item.mandate}
            title="Min Temperature"
            value={minTemp}
            callBackFunction={(e) => setMinTemp(e)}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "TEMP_MAX":
        return (
          <TempInputFied
            key={id}
            abbreviation={item.uomAbbr}
            validationType={item.fieldTypeId}
            field={item.mandate}
            title="Max Temperature"
            value={maxTemp}
            callBackFunction={(e) => setMaxTemp(e)}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "DATE_RANGE":
        return (
          <DateRangeSelectorInput
            key={id}
            field={item.mandate}
            state={item.attributeName === activeInput}
            title="Date Range"
            PlaceHolder=""
            value={dateRangeValue}
            updateDateRange={handleDateRangeChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "DATE":
        return (
          <ReportDatePicker
            key={id}
            field={item.mandate}
            state={item.attributeName === activeInput}
            title="Date Range"
            PlaceHolder=""
            value={dateRangeValue}
            updateDateRange={handleDateChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "DATE_TIME_RANGE":
        return (
          <DateTimeRangeSelectorInput
            key={id}
            state={item.attributeName === activeInput}
            field={item.mandate}
            title="Date Time Range"
            PlaceHolder={""}
            value={dateRangeValue}
            updateDateRange={handleDateTimeRangeChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "DATE_TIME":
        return (
          <ReportDateTimePicker
            key={id}
            state={item.attributeName === activeInput}
            field={item.mandate}
            title="Date Time"
            PlaceHolder={""}
            value={dateRangeValue}
            updateDateRange={handleDateTimeChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "TIME_RANGE":
        return (
          <TimeRangePicker
            key={id}
            state={item.attributeName === activeInput}
            field={item.mandate}
            title="Time Range"
            PlaceHolder={""}
            value={dateRangeValue}
            updateDateRange={handleTimeRangeChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "TIME":
        return (
          <TimePicker
            key={id}
            state={item.attributeName === activeInput}
            field={item.mandate}
            title="Time"
            PlaceHolder={""}
            value={dateRangeValue}
            updateDateRange={handleTimeChange}
            activeInput={() => setActiveInput(item.attributeName)}
          />
        );
      case "ASSET_CATEGORY":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Asset Category ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={reportDropDownCustomStyle}
              placeholder="Select Category"
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOption: { value: string }) =>
                setSelectedCategoryType(selectedOption.value)
              }
              options={categoryFilterData?.map((asset) => ({
                value: asset.id,
                label: asset.name,
                key: asset.id, // Add key prop
              }))}
            />
          </div>
        );
      case "ASSET_TYPE":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Asset Type ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={reportDropDownCustomStyle}
              placeholder="Select Asset Type"
              value={selectedAssetTypeObject}
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOption: { value: string }) => {
                setSelectedAssetType(selectedOption.value);
                setSelectedAssetTypeObject(selectedOption);
              }}
              options={assetTypeList?.map((asset) => ({
                value: asset.fixedAssetTypeId,
                label: asset.description,
                key: asset.fixedAssetTypeId, // Add key prop
              }))}
            />
          </div>
        );
      case "ASSET":
        return (
          <div className="dropdownReportsReactSelect">
            <div className="reportsDropDownHeader">{`Asset ${
              item?.mandate === "Y" ? " *" : ""
            }`}</div>
            <Select
              styles={reportDropDownCustomStyle}
              placeholder="Select Asset"
              value={selectedAssetObject}
              options={filteredAssets}
              onFocus={() => setActiveInput(item.attributeName)}
              onChange={(selectedOption: any) => {
                setSelectedAsset(selectedOption.value);
                setSelectedAssetObject(selectedOption);
              }}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  const setFileType = (fileType: string) => {
    setSelectedFileType(fileType);
    activeState();
    setCardShow(true);
  };

  const closeFileType = () => {
    setSelectedFileType("");
    setSelectedFileType("");
    setMinTemp("");
    setMaxTemp("");
    setDateRangeValue({
      startDate: "",
      endDate: "",
    });
    setSelectedAsset("null");
    setSelectedAssetType("null");
    setSelectedCategoryType("null");
    setSelectedAssetObject(null);
    setSelectedAssetTypeObject(null);
    setCardShow(false);
  };

  return (
    <div className="reportDataMainView">
      <div className="viewReportCard">
        <div className="reportDetailsView">
          <p className="reportListHeader">{data.contentName}</p>
        </div>
        <div className="downloadActionView">
          <div className="reportDataFileType">
            {formatList &&
              formatList.map((item: string, index: number) => {
                return (
                  <div
                    key={index}
                    className={`selectedFileFormatDiv  ${
                      selectedFileType === item ? "activeSelected" : ""
                    }`}
                    onClick={() => setFileType(item)}
                  >
                    <img src={downloadIcon} loading="lazy" alt="icon" />{" "}
                    <p className="fileFormatText">{item}</p>
                  </div>
                );
              })}
          </div>
          {/* <img src={pinIcon} loading="lazy" alt="icon"/> */}
        </div>
      </div>

      {active && cardShow && (
        <div className="downloadReportPopUp">
          <div className="columnRightDiv">
            {data.attributeProperties.map(
              (obj: ReportResponseCard, index: number) => {
                if (obj.attributeValue === "TRUE")
                  return getComponent(obj, index);
              }
            )}
          </div>

          <div className="columnLeftDiv">
            {repoLoader ? (
              <div className="downloadButton">
                <p>Please wait...</p>
              </div>
            ) : downloadBtnStatus ? (
              <div className="downloadButton" onClick={getReportDownload}>
                <img src={DownloadIcon} alt="icon" /> <p>Download</p>
              </div>
            ) : (
              <div className="downloadButton inActivedownloadButton">
                <img src={DownloadIcon} alt="icon" /> <p>Download</p>
              </div>
            )}
            <img
              src={CloseIcon}
              loading="lazy"
              alt="icon"
              className="closeIcon"
              onClick={closeFileType}
            />
          </div>
        </div>
      )}
    </div>
  );
};
