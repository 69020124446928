import * as R from "ramda";
import DisplayError from "../DisplayError/DisplayError";
import "./AnoviEventSummary.scss";
import DefrostCycleTable from "./DefrostCycleTable";
import CompressorTable from "./CompressorTable";
import DoorOpenTable from "./DoorOpenTable";

function AnoviEventSummary({data, type}:any) {
  return (
    <>
      {data === "error" ? (
        <DisplayError type={"err_1"} />
      ) : typeof data === "number" ? (
        <DisplayError type={"err-500/404"} />
      ) : typeof data === "object" ? (
        R.isEmpty(data) || data[0].eventSummary.length == 0 ? (
          <DisplayError type={"err-empty"} />
        ) : type === "DEFROST_CYCLE" ? (
          <DefrostCycleTable data={data}/>
        ) : type === "CMPRSR_ON,CMPRSR_OFF" ? (
          <CompressorTable data={data}/>
        ) : (
          <DoorOpenTable data={data}/>
        )
      ) : (
        <DisplayError />
      )}
    </>
  );
}

export default AnoviEventSummary;
