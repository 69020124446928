import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./EventListDetail.scss";

import ClockIcon from "../assets/ic_clock.svg";
import MarkerIcon from "../assets/ic_marker.svg";
import DownloadIcon from "../assets/Icon feather-download.svg";
import DownArrow from "../assets/ic_down_arrow_blue.svg";
import { useQuery, useQueryClient } from "react-query";
import { getEventDetails } from "../service/EventListService";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import moment from "moment";
import EventListState from "../state/EventListState";
import { cancelExistingQuery } from "../../../Utils/utils";
import { formatDuration } from "../utils/Utils";

type PropsType = {
  show: boolean;
  onHide: () => void;
  eventId: string;
  startDateTime: number;
  assetId: string;
  objectCount: string;
};

function EventListDetail({
  show,
  onHide,
  eventId,
  startDateTime,
  assetId,
  objectCount,
}: PropsType) {
  const queryClient = useQueryClient();
  const [metaDataObj, setMetaDataObj] = useState<any>(null);
  const [showMetaDataObj, setShowMetaDataObj] = useState(false);

  const { hoveredCloseButton, setHoveredCloseButton } = EventListState();
  // Todo we have to change the url once backend team shift to the S3 bucket.
  const imageUrl =
    "https://fastly.picsum.photos/id/354/200/300.jpg?hmac=uSugRpT0q_Wj5xaWBDbBXW21Q__wsvMB58L9pjItvRQ";

  const { data, isLoading, isFetching, error, refetch, dataUpdatedAt } =
    useQuery(
      "getEventDetailsData",
      () => getEventDetails(eventId, startDateTime, assetId),
      {
        enabled: false,
        keepPreviousData: false,
      }
    );

  const refetchEventDetails = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error("Error in refetching api:", error);
    }
  };

  const handleMouseEnter = () => {
    setHoveredCloseButton(true);
  };

  const handleMouseLeave = () => {
    setHoveredCloseButton(false);
  };

  const handleDownloadAll = () => {
    data?.mediaList.forEach((item: MediaList, index: number) => {
      downloadFile(imageUrl, `file${index + 1}.jpg`);
    });
  };

  const downloadFile = (url: string, filename: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      });
  };

  useEffect(() => {
    cancelExistingQuery("getEventDetailsData", queryClient);
    return () => {
      cancelExistingQuery("getEventDetailsData", queryClient);
    };
  }, []);

  useEffect(() => {
    refetchEventDetails();
  }, [eventId, startDateTime, assetId]);

  useEffect(() => {
    if (data !== undefined) {
      if (data?.hasOwnProperty("metadata")) {
        setMetaDataObj(JSON.parse(data?.metadata));
      }
    }
  }, [data, dataUpdatedAt]);

  if (isLoading || isFetching) {
    return (
      <div className="loadingBox1">
        <LoaderStyle />
      </div>
    );
  }

  if (error) {
    return (
      <>
        <Modal.Header className="eventListDetailPopUpHeader"></Modal.Header>
        <Modal.Body>
          <div className="loadingBox1">
            <DisplayError type="err_1" />
          </div>
          <div className="eventDetailsMainDiv">
            <div className="modalFooterDiv">
              <div
                className="closeButtonDiv"
                style={{
                  backgroundColor: hoveredCloseButton
                    ? "rgba(95, 91, 91, 1)"
                    : "rgba(255, 255, 255, 1)",
                }}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={handleMouseLeave}
                onClick={onHide}
              >
                <p
                  className="closeButtonText"
                  style={{
                    color: hoveredCloseButton
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(95, 91, 91, 1)",
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  }

  if (data !== undefined && typeof data === "number") {
    return (
      <>
        <Modal.Header className="eventListDetailPopUpHeader"></Modal.Header>
        <Modal.Body>
          <div className="loadingBox1">
            <DisplayError type="err-500/404" />
          </div>
          <div className="eventDetailsMainDiv">
            <div className="modalFooterDiv">
              <div
                className="closeButtonDiv"
                style={{
                  backgroundColor: hoveredCloseButton
                    ? "rgba(95, 91, 91, 1)"
                    : "rgba(255, 255, 255, 1)",
                }}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={handleMouseLeave}
                onClick={onHide}
              >
                <p
                  className="closeButtonText"
                  style={{
                    color: hoveredCloseButton
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(95, 91, 91, 1)",
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  }

  if (data === undefined) {
    return (
      <>
        <Modal.Header className="eventListDetailPopUpHeader"></Modal.Header>
        <Modal.Body>
          <div className="loadingBox1">
            <DisplayError type="err-empty" />
          </div>
          <div className="eventDetailsMainDiv">
            <div className="modalFooterDiv">
              <div
                className="closeButtonDiv"
                style={{
                  backgroundColor: hoveredCloseButton
                    ? "rgba(95, 91, 91, 1)"
                    : "rgba(255, 255, 255, 1)",
                }}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={handleMouseLeave}
                onClick={onHide}
              >
                <p
                  className="closeButtonText"
                  style={{
                    color: hoveredCloseButton
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(95, 91, 91, 1)",
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  }

  let formattedDuration = formatDuration(data.startDateTime, data.endDateTime);
  
  return (
    <>
      <Modal.Header className="eventListDetailPopUpHeader"></Modal.Header>
      <Modal.Body>
        <div className="eventDetailsMainDiv">
          <div className="eventDetailsHeader">
            <div className="titleAndTimeDiv">
              <p className="eventDetailsTitle">{data.eventDescription}</p>
              <div className="timeDiv">
                <img src={ClockIcon} />
                <p className="eventTimeText">
                 {formattedDuration}
                </p>
              </div>
            </div>
            <div className="eventDetailsDiv">
              <div className="timeStampAndEventCountDiv">
                <p className="eventTimeStampData">
                  {moment(data.startDateTime).format("MMMM Do YYYY, h:mm a")} -{" "}
                  {moment(data.endDateTime).format("MMMM Do YYYY, h:mm a")}
                </p>
                <div className="eventCountDiv">
                  <p className="eventOccuredCunt">#{objectCount}</p>
                  <p className="textCount">Count</p>
                </div>
              </div>
              <div className="eventCategoryDiv">
                <p className="eventCategoryData">{data.eventCategoryDesc}</p>
                <p className="eventCategoryDataName">{data.eventTypeDesc}</p>
              </div>
            </div>
            <div className="facilityDetailsAndDownloadDiv">
              <div className="facilityDetailsDiv">
                <img src={MarkerIcon} />
                <div className="facilityDataDiv">
                  <p className="eventFaciliyName">
                    {data.facilityName}, {data.areaDesc}
                  </p>
                  <p className="eventFacilityAddress">{data.facilityAddress}</p>
                </div>
              </div>
              {data.mediaList.length !== 0 && (
                <div className="downloadButton" onClick={handleDownloadAll}>
                  <img src={DownloadIcon} />
                  <p className="downloadButtonText">Download All</p>
                </div>
              )}
            </div>
          </div>
          <div className="eventSnapshotParentDiv">
            <div className="eventSnapshotMainDiv">
              <div className="eventSnapShotDiv">
                {data.mediaList.length === 0 ? (
                  <div className="noMediaAvailableDiv">
                    <p className="noMediaAvailable">
                      No Media Available for this Event.
                    </p>
                  </div>
                ) : (
                  <>
                    {data.mediaList.map((item: MediaList) => (
                      <>
                        <img src={imageUrl} />
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          {metaDataObj !== null && (
            <div className="metadataParentDiv">
              <div
                className="metadataDivHeading"
                onClick={() => setShowMetaDataObj((prev) => !prev)}
              >
                <p className="metaDataHeadingText">View Metadata</p>
                <img
                  src={DownArrow}
                  className={`${showMetaDataObj ? "rotate-0" : "rotate-90"}`}
                />
              </div>
              {showMetaDataObj && (
                <div className="metadataDiv">
                  <p className="metaDataText">
                    {metaDataObj?.eventId}: {metaDataObj?.objectCount}
                  </p>
                </div>
              )}
            </div>
          )}
          <div className="modalFooterDiv">
            <div
              className="closeButtonDiv"
              style={{
                backgroundColor: hoveredCloseButton
                  ? "rgba(95, 91, 91, 1)"
                  : "rgba(255, 255, 255, 1)",
              }}
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={handleMouseLeave}
              onClick={onHide}
            >
              <p
                className="closeButtonText"
                style={{
                  color: hoveredCloseButton
                    ? "rgba(255, 255, 255, 1)"
                    : "rgba(95, 91, 91, 1)",
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

export default EventListDetail;
