/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import CurrentImg from "../../../assets/icons/CurrentImg.svg";
import VoltageImg from "../../../assets/icons/VoltageImg.svg";
import PowerImg from "../../../assets/icons/PowerImg.svg";
import LoadImg from "../../../assets/icons/LoadImg.svg";
import ApparentPowerImg from "../../../assets/icons/ApparentPowerImg.svg";
import TotalReactivepower from "../../../assets/icons/TotalReactivepower.svg";
import Linevoltageimg from "../../../assets/icons/Linevoltage.svg";
import CurrentHarmonicDistortion from "../../../assets/icons/CurrentHarmonicDistortion.svg"
import * as R from "ramda";
import "./ADEnergy.scss";
import {
  commaSeperatedString,
  convertwattTokw,
  roundToTwoDecimalPlaces,
  subtractWholeNumber,
} from "../../../Utils/utils";

function ADEnergy({ data, closeOpenBtn }) {
  const [current, setCurrent] = useState({
    RA: "",
    abb: "",
    YA: "",
    BA: "",
  });
  const [voltage, setVoltage] = useState({
    VR: "",
    abb: "",
    VY: "",
    VB: "",
  });
  const [lineVoltage, setLineVoltage] = useState({
    RY: "",
    abb: "",
    YB: "",
    BR: "",
  });
  const [powerFactor, setPowerFactor] = useState({
    PFR: "",
    abb: "",
    PFY: "",
    PFB: "",
    PF: "",
  });
  const [load, setLoad] = useState({
    RW: "",
    abb: "",
    YW: "",
    BW: "",
    TW: "",
  });
  const [apparentPower, setApparentPower] = useState({
    RVA: "",
    abb: "",
    YVA: "",
    BVA: "",
    TVA: "",
  });

  const [reactivePower, setReactivePower] = useState({
    kVAr: "",
    abb: "",
  });


  const [VHarmonicDistortion, setVHarmonicDistortion] = useState({
    RVTHD: "",
    YVTHD: "",
    BVTHD: "",
    TVTHD: "",
    abb: ""
  })

  const [AHarmonicDistortion, setAHarmonicDistortion] = useState({
    RATHD: "",
    YATHD: "",
    BATHD: "",
    TATHD: "",
    abb: ""
  })
  useEffect(() => {
    let powerFactorR = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "PFR"
    );
    powerFactor.PFR = powerFactorR?.meterValue;
    powerFactor.abb = powerFactorR?.readingUomDesc;

    let powerFactorY = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "PFY"
    );
    powerFactor.PFY = powerFactorY?.meterValue;

    let powerFactorB = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "PFB"
    );
    powerFactor.PFB = powerFactorB?.meterValue;

    let powerFactorPF = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "POWER_FACTOR"
    );
    powerFactor.PF = powerFactorPF?.meterValue;

    let voltageR = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "RV"
    );
    voltage.VR = voltageR?.meterValue;
    voltage.abb = voltageR?.readingUomDesc;

    let voltageY = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "YV"
    );
    voltage.VY = voltageY?.meterValue;

    let voltageB = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "BV"
    );
    voltage.VB = voltageB?.meterValue;

    let lineVoltageR = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "RY"
    );

    lineVoltage.RY = lineVoltageR?.meterValue;
    lineVoltage.abb = lineVoltageR?.readingUomDesc;

    let lineVoltageY = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "YB"
    );
    lineVoltage.YB = lineVoltageY?.meterValue;

    let lineVoltageB = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "BR"
    );
    lineVoltage.BR = lineVoltageB?.meterValue;

    let currentR = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "RA"
    );
    current.RA = currentR?.meterValue;
    current.abb = currentR?.readingUomDesc;

    let currentY = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "YA"
    );
    current.YA = currentY?.meterValue;

    let currentB = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "BA"
    );
    current.BA = currentB?.meterValue;

    let loadR = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "RW"
    );
    load.RW = convertwattTokw(loadR?.meterValue, "k");
    load.abb = loadR?.readingUomDesc;

    let loadY = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "YW"
    );
    load.YW = convertwattTokw(loadY?.meterValue, "k");

    let loadB = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "BW"
    );
    load.BW = convertwattTokw(loadB?.meterValue, "k");

    let loadT = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "TW"
    );
    load.TW = convertwattTokw(loadT?.meterValue, "k");

    let apparentPowerR = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "RVA"
    );
    apparentPower.RVA = convertwattTokw(apparentPowerR?.meterValue, "k");
    apparentPower.abb = apparentPowerR?.readingUomDesc;

    let apparentPowerY = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "YVA"
    );
    apparentPower.YVA = convertwattTokw(apparentPowerY?.meterValue, "k");

    let apparentPowerB = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "BVA"
    );
    apparentPower.BVA = convertwattTokw(apparentPowerB?.meterValue, "k");

    let apparentPowerTVA = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "TVA"
    );
    apparentPower.TVA = convertwattTokw(apparentPowerTVA?.meterValue, "k");
    // let filterDataKva = data.find((obj) => obj.meterTypeId === "KVA");
    // setKvaData(filterDataKva?.meterValue);
    let reactivePowerR = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "KVAR"
    );
    reactivePower.kVAr = reactivePowerR?.meterValue;
    reactivePower.abb = reactivePowerR?.readingUomDesc;



    let VHarmonicDistortion_rv = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "THD_RV"
    );
    VHarmonicDistortion.RVTHD = VHarmonicDistortion_rv?.meterValue;
    VHarmonicDistortion.abb = VHarmonicDistortion_rv?.readingUomDesc

    let VHarmonicDistortion_yv = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "THD_YV"
    );
    VHarmonicDistortion.YVTHD = VHarmonicDistortion_yv?.meterValue;

    let VHarmonicDistortion_bv = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "THD_BV"
    );
    VHarmonicDistortion.BVTHD = VHarmonicDistortion_bv?.meterValue;

    let VHarmonicDistortion_tv = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "THD_TV"
    );
    VHarmonicDistortion.TVTHD = VHarmonicDistortion_tv?.meterValue;

    let AHarmonicDistortion_ra = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "THD_RA"
    );
    AHarmonicDistortion.RATHD = AHarmonicDistortion_ra?.meterValue;
    AHarmonicDistortion.abb = AHarmonicDistortion_ra?.readingUomDesc

    let AHarmonicDistortion_ya = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "THD_YA"
    );
    AHarmonicDistortion.YATHD = AHarmonicDistortion_ya?.meterValue;

    let AHarmonicDistortion_ba = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "THD_BA"
    );
    AHarmonicDistortion.BATHD = AHarmonicDistortion_ba?.meterValue;

    let AHarmonicDistortion_ta = data.meters.find(
      (obj: { meterTypeId: string }) => obj.meterTypeId === "THD_TA"
    );
    AHarmonicDistortion.TATHD = AHarmonicDistortion_ta?.meterValue;


  }, [data]);

  return (
    <div className="emergyCont">
      <div className="cardsRowCont">
        <div className="rowOne">
          <div className="card1Cont">
            <p className="card1Heading">Current</p>
            <div className="card1ValuesCont">
              <img src={CurrentImg} alt="icon" className="card1Icon" />
              <div className="card1Values">
                <p>
                  R {roundToTwoDecimalPlaces(current?.RA)} {current?.abb}
                </p>
                <p>
                  Y {roundToTwoDecimalPlaces(current?.YA)} {current?.abb}
                </p>
                <p>
                  B {roundToTwoDecimalPlaces(current?.BA)} {current?.abb}
                </p>
              </div>
            </div>
          </div>
          <div className="card2Cont">
            <p className="card2Heading">Voltage</p>
            <div className="card2ValuesCont">
              <img src={VoltageImg} alt="icon" className="card2Icon" />
              <div className="card2Values">
                <p>
                  R {roundToTwoDecimalPlaces(voltage?.VR)} {voltage?.abb}
                </p>
                <p>
                  Y {roundToTwoDecimalPlaces(voltage?.VY)} {voltage?.abb}
                </p>
                <p>
                  B {roundToTwoDecimalPlaces(voltage?.VB)} {voltage?.abb}
                </p>
                {/* <P>r - V</P> */}
              </div>
            </div>
          </div>
          {/* //Line voltage */}
          <div className="card2Cont">
            <p className="card2Heading">Line Voltage</p>
            <div className="card2ValuesCont">
              <img src={Linevoltageimg} alt="icon" className="card2Icon" />
              <div className="card2Values">
                <p>
                  RY {roundToTwoDecimalPlaces(lineVoltage?.RY)}{" "}
                  {lineVoltage?.abb}
                </p>
                <p>
                  YB {roundToTwoDecimalPlaces(lineVoltage?.YB)}{" "}
                  {lineVoltage?.abb}
                </p>
                <p>
                  BR {roundToTwoDecimalPlaces(lineVoltage?.BR)}{" "}
                  {lineVoltage?.abb}
                </p>
                {/* <P>r - V</P> */}
              </div>
            </div>
          </div>
          {/* line voltage end */}
          <div className="card3Cont">
            <p className="card3Heading">Power Factor</p>
            <div className="card3ValuesCont">
              <img src={PowerImg} alt="icon" className="card3Icon" />
              <div className="card3Values">
                <p>
                  R {subtractWholeNumber(powerFactor?.PFR, 2)} {powerFactor?.abb}
                </p>
                <p>
                  Y {subtractWholeNumber(powerFactor?.PFY, 2)} {powerFactor?.abb}
                </p>
                <p>
                  B {subtractWholeNumber(powerFactor?.PFB, 2)} {powerFactor?.abb}
                </p>
                <p>
                  T {subtractWholeNumber(powerFactor?.PF, 2)} {powerFactor?.abb}
                </p>
              </div>
            </div>
          </div>
        </div>
        {closeOpenBtn && (
          <>
            <div className="rowOne">
              <div className="card4Cont">
                <p className="card4Heading">Load</p>
                <div className="card4ValuesCont">
                  <img src={LoadImg} alt="icon" className="card4Icon" />
                  <div className="card4Values">
                    <p>
                      R {commaSeperatedString(load?.RW)}
                      {`${load?.abb}`}
                    </p>
                    <p>
                      Y {commaSeperatedString(load?.YW)}
                      {`${load?.abb}`}
                    </p>
                    <p>
                      B {commaSeperatedString(load?.BW)}
                      {`${load?.abb}`}
                    </p>
                    <p>
                      T {commaSeperatedString(load?.TW)}
                      {load?.abb}
                    </p>
                  </div>
                </div>
              </div>
              <div className="card5Cont">
                <p className="card5Heading">Apparent Power</p>
                <div className="card5ValuesCont">
                  <img src={ApparentPowerImg} alt="icon" className="card5Icon" />
                  <div className="card5Values">
                    <p>
                      R {commaSeperatedString(apparentPower?.RVA)}
                      {apparentPower?.abb}
                    </p>
                    <p>
                      Y {commaSeperatedString(apparentPower?.YVA)}
                      {apparentPower?.abb}
                    </p>
                    <p>
                      B {commaSeperatedString(apparentPower?.BVA)}
                      {apparentPower?.abb}
                    </p>
                    <p>
                      T {commaSeperatedString(apparentPower?.TVA)}
                      {apparentPower?.abb}
                    </p>
                  </div>
                </div>
              </div>
              <div className="card4Cont">
                <p className="card4Heading">Voltage Harmonic Distortion</p>
                <div className="card4ValuesCont">
                  <img src={CurrentHarmonicDistortion} alt="icon" className="card4Icon" />
                  <div className="card4Values">
                    <p>
                      R {roundToTwoDecimalPlaces(VHarmonicDistortion?.RVTHD)}
                      {` ${VHarmonicDistortion?.abb}`}
                    </p>
                    <p>
                      Y {roundToTwoDecimalPlaces(VHarmonicDistortion?.YVTHD)}
                      {` ${VHarmonicDistortion?.abb}`}
                    </p>
                    <p>
                      B {roundToTwoDecimalPlaces(VHarmonicDistortion?.BVTHD)}
                      {` ${VHarmonicDistortion?.abb}`}
                    </p>
                    <p>
                      T {roundToTwoDecimalPlaces(VHarmonicDistortion?.TVTHD)}
                      {` ${VHarmonicDistortion?.abb}`}
                    </p>
                  </div>
                </div>
              </div>




            </div>

            {/* row 2 */}
            <div className="rowOne">

              <div className="card5Cont">
                <p className="card5Heading">Current Harmonic Distortion</p>
                <div className="card5ValuesCont">
                  <img src={CurrentHarmonicDistortion} alt="icon" className="card5Icon" />
                  <div className="card5Values">
                    <p>
                      R {roundToTwoDecimalPlaces(AHarmonicDistortion?.RATHD)}
                      {` ${AHarmonicDistortion?.abb}`}
                    </p>
                    <p>
                      Y {roundToTwoDecimalPlaces(AHarmonicDistortion?.YATHD)}
                      {` ${AHarmonicDistortion?.abb}`}
                    </p>
                    <p>
                      B {roundToTwoDecimalPlaces(AHarmonicDistortion?.BATHD)}
                      {` ${AHarmonicDistortion?.abb}`}
                    </p>
                    <p>
                      T {roundToTwoDecimalPlaces(AHarmonicDistortion?.TATHD)}
                      {` ${AHarmonicDistortion?.abb}`}
                    </p>
                  </div>
                </div>
              </div>

              <div className="card5Cont">
                <p className="card5Heading">Total Reactive Power</p>
                <div className="card5ValuesCont">
                  <img
                    src={TotalReactivepower}
                    alt="icon"
                    className="card5Icon"
                  />
                  <div className="card5Values">
                    <p>
                      {" "}
                      {roundToTwoDecimalPlaces(reactivePower?.kVAr)}{" "}
                      {reactivePower?.abb}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>



        )}
      </div>
    </div>
  );
}

export default ADEnergy;
