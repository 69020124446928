import { useEffect, useState } from "react";
import "./AssetCard.scss";
import { CardMeter, MinAndMaxDataType, NewAssetData } from "../types";
import {
  assetImageList,
  checkUndefinedConditions,
  getAssetTypeDesc,
  getFacilityDetails,
} from "../utils";
import LocationIcon from "../../../assets/image/location-icon.svg";
import { statusMap } from "./../utils/index";
import moment from "moment";
import {
  checkIsAssetCompliance,
  roundToTwoDecimalNumber,
} from "../../../Utils/utils";
import LocalStorageService from "../../../Utils/LocalStorageService";
import { actionTypes } from "../../../redux/reducer";
import { useStateValue } from "../../../redux/StateProvider";
import { useNavigate } from "react-router-dom";
import TempDataScrolling from "../../../a1Components/scrollingText/TempDataScrolling";
import ScrollingText from "../../../a1Components/scrollingText/ScrollingText";
import AssetCardMeter from "./AssetCardMeter";

type PropTypes = {
  data: NewAssetData;
};

function AssetCard({ data }: PropTypes) {
  const [{}, dispatch] = useStateValue();
  const navigate = useNavigate();

  const [assetType, setAssetType] = useState({
    fixedAssetTypeId: "",
    description: "",
  });
  const [facilityData, setFacilityData] = useState<any>({});
  const [assetStatus, setAssetStatus] = useState({
    backgroundColor: "",
    text: "",
  });
  const [cardMetersList, setCardMetersList] = useState<CardMeter[]>([]);
  const [minAndMaxData, setMinAndMaxData] = useState<MinAndMaxDataType>({
    min: null,
    max: null,
    unit: null,
  });

  const navigateAssetDetailPage = () => {
    let dataTypeCheck = {
      fixedAssetAssocWithTypeDesc: data.fixedAssetAssocWithTypeDesc,
      assetId: data.assetId,
      fixedAssetAssocWithTypeId: data.fixedAssetAssocWithTypeId,
      assetName: data.assetName,
      assetTypeDesc: assetType.description,
      meters: data.attributes,
      data: data,
    };
    LocalStorageService.setAssetIdData(data.assetId);
    LocalStorageService.setSelectedAssetData(JSON.stringify(dataTypeCheck));
    LocalStorageService.setSingleAssetCardDetailsData(
      JSON.stringify(facilityData)
    );

    dispatch({
      type: actionTypes.SET_CURRENT_ASSET_ID,
      currAssetId: data.assetId,
    });
    navigate(`/asset-list/${data.assetName}`);
  };

  useEffect(() => {
    const extractData = async () => {
      const assetTypeData = await getAssetTypeDesc(data.assetTypeId);
      setAssetType(assetTypeData);

      const facility = await getFacilityDetails(data.locatedAtFacilityId);
      setFacilityData(facility);
      const statusColor = statusMap[data.healthStatus] === undefined ? null : statusMap[data.healthStatus];
      setAssetStatus(statusColor);
      if (
        data.hasOwnProperty("meterData") &&
        data.meterData.hasOwnProperty("cardMeters")
      ) {
        const cardMeter = data.meterData.cardMeters.sort(
          (a, b) => a.sequenceNum - b.sequenceNum
        );
        setCardMetersList(cardMeter);
      }

      if (
        data.attributes.length !== 0
      ) {
        const { attributes } = data;
        const minTempObj = attributes.find(
          (item) => item.attrName === "TEMP_MIN"
        );
        const maxTempObj = attributes.find(
          (item) => item.attrName === "TEMP_MAX"
        );

        if (minTempObj && maxTempObj) {
          const minValue = minTempObj?.attrValue;
          const maxValue = maxTempObj?.attrValue;

          if (minValue !== null && maxValue !== null) {
            setMinAndMaxData({
              min: roundToTwoDecimalNumber(minValue),
              max: roundToTwoDecimalNumber(maxValue),
              unit: minTempObj?.UomDescription,
            });
          }
        }
      }
    };
    extractData();
  }, [data]);
  return (
    <div
      className="assetCardContainer"
      style={{ borderBottom: assetStatus === null ? "" : `4px solid ${assetStatus.backgroundColor}` }}
      onClick={navigateAssetDetailPage}
    >
      <div className="assetCardHeaderCont">
        <img
          className="assetIcon"
          src={data?.contentUrl}
          alt="icon"
          loading="lazy"
        />
        <div className="assetNameDiv">
          <ScrollingText
            classNames="assetName"
            data={`${data.assetName.toUpperCase()}`}
            spanClassNames=""
            spanData=""
            color=""
          />
          <p className="assetType">{assetType?.description}</p>
        </div>
      </div>

      <AssetCardMeter
        data={cardMetersList}
        fixedAssetAssocWithTypeId={data.fixedAssetAssocWithTypeId}
        minAndMaxData={minAndMaxData}
        attributeList={data.attributes}
        name={data.assetName}
      />

      <div className="facilityDetailsDiv">
        <img
          src={LocationIcon}
          alt="icon"
          loading="lazy"
          className="locationIcon"
        />
        <p className="facilityData">
          {checkUndefinedConditions(facilityData?.facilityName, false)}
          {checkUndefinedConditions(facilityData?.address?.city, true)}
          {checkUndefinedConditions(facilityData?.address?.stateProvinceGeoId, true)}
        </p>
      </div>
      <div className="dividerLine" />
      <div className="footer">
        <div
          className="assetStatus"
          style={{ backgroundColor: assetStatus === null ? "" : assetStatus.backgroundColor }}
        >
          <p className="assetStatusText">{assetStatus?.text}</p>
        </div>
        {data.lastCommTime !== null && (
          <p className="assetLastUpdatedTime">
            {moment(data.lastCommTime).fromNow()}
          </p>
        )}
      </div>
    </div>
  );
}

export default AssetCard;
